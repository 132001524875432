<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue'

import { useSubProfileStore } from '@/store'

import { QrSection, QrSectionItem, NewsSectionContent, NewsSectionStyle, NewsSectionItemContent } from '@/api/types'

import IconChevronNewsSectionSvg from '@/components/svg/qr-section/IconChevronNewsSectionSvg.vue'

const subProfileStore = useSubProfileStore()

const { getImageUrl } = subProfileStore

const { newsSection } = defineProps({
  newsSection: {
    type: Object as PropType<QrSection<NewsSectionContent, NewsSectionStyle>>,
    required: true
  }
})

const emit = defineEmits(['onClick'])

const { section, style } = newsSection

const cssVarsContainer = computed(() => {
  return {
    '--background': style.backgroundColor
  }
})

const cssVarsItem = computed(() => {
  return {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), ' + style.backgroundColor
  }
})

const cssVarsText = computed(() => {
  return {
    '--text-align': style.align,
    '--color': style.textColor
  }
})

function onClick (newsItem: QrSectionItem<NewsSectionItemContent>) {
  window.open(newsItem.content.url, '_blank')
  emit('onClick', newsItem.id)
}

</script>

<template>
    <div v-if="newsSection" class="news-body" :style="cssVarsContainer">
        <p v-if="newsSection.labelVisible" class="label-text" :style="cssVarsText">{{ newsSection.label }}</p>
        <div v-for="item in section.items" :key="item.id" :index="item.id" class="news-item" :style="cssVarsItem" @click="() => onClick(item)">
            <img class="news-image" :src="getImageUrl(item.content.imageUri)" />
            <div class="news-text-container">
                <p class="news-title" :style="cssVarsText">{{ item.content.title }}</p>
                <p class="news-sub-title" :style="cssVarsText">{{ item.content.subtitle }}</p>
            </div>
            <IconChevronNewsSectionSvg :color="style.textColor" />
        </div>
    </div>
</template>

<style scoped lang="scss">

  div.news-body {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 55px 30px;
    background-color: var(--background);
    width: 111%;
    margin: 30px 0 30px 0;
  }

  p.label-text {
    color: var(--color);
    text-align: var(--text-align);
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    opacity: 0.5;
    margin: 0;
  }

  div.news-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    aspect-ratio: 3.1 / 1.25;
    padding: 0 15px 0 0;
  }
  img.news-image {
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    aspect-ratio: 1 / 1.25;
  }
  div.news-text-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin: 0 0 0 8%;
  }
  p.news-title {
    color: var(--color);
    text-align: var(--text-align);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin: 0;
  }
  p.news-sub-title{
    color: var(--color);
    text-align: var(--text-align);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin: 0;
    opacity: 0.5;
  }
</style>
