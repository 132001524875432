<script setup lang="ts">
import { computed, ref } from 'vue'

import { storeToRefs } from 'pinia'

import { useEditorStore, useOnboardingStore } from '@/store'

import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import LoginButtonWhite from '@/components/button/LoginButtonWhite.vue'
import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginButtonSkip from '@/components/button/LoginButtonSkip.vue'

import { SOCIAL_TYPE_TO_ICON } from '@/constants'

const editorStore = useEditorStore()
const onboardingStore = useOnboardingStore()

const { selectedSocials } = storeToRefs(editorStore)
const { clearSocials, editSelectedSocialByIndex, submitSocials, fetchUserSubProfile } = editorStore

const { setMode } = onboardingStore

const cssItemIcon = computed(() => {
  return {
    width: '0.64rem',
    height: '0.64rem'
  }
})

const cssItemIconContainer = computed(() => {
  return {
    backgroundColor: 'var(--color-white)',
    borderRadius: '0.26rem',
    border: '1px solid rgba(33, 37, 41, 0.15)',
    width: '1.28rem',
    height: '1.28rem',
    alignItems: 'center',
    padding: '0.32rem',
    display: 'flex',
    marginRight: '0.32rem'
  }
})

function getIcon (type: string): string {
  return SOCIAL_TYPE_TO_ICON[type].value
}

function onEdit (index: number, value: string) {
  editSelectedSocialByIndex(index, value)
}

function onBack () {
  setMode('select-socials')
}

async function onContinue () {
  // Concurrency trial
  const status = await Promise.all([submitSocials(), fetchUserSubProfile()])
  setMode('add-details')
}

async function onSkip () {
  clearSocials()
  const status = await fetchUserSubProfile()
  setMode('add-details')
}

</script>

<template>
  <div class="add-socials-container">
    <div class="add-container">
      <EditorInputComponent
        v-for="(item, index) in selectedSocials"
        :key="index"
        :icon-src="require(`@/assets/${getIcon(item.type)}`)"
        :icon-style="cssItemIcon"
        :icon-container-style="cssItemIconContainer"
        :value="item.value"
        @onChangeValue="(value: string) => onEdit(index, value)"
        />
    </div>
    <div class=buttons-container>
       <div class="back-continue-buttons-container">
        <div class="button-container">
          <LoginButtonWhite text="Back" @click="onBack"/>
        </div>
        <div class="button-container">
          <LoginButtonGrad text="Continue" @click="onContinue"/>
        </div>
       </div>
      <LoginButtonSkip text="Skip" @click="onSkip"/>
    </div>
  </div>
</template>

<style scoped>

div.add-socials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
div.add-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.32rem;
}

div.buttons-container {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 0.96rem;
  margin-top: 2.94rem;
}
div.back-continue-buttons-container {
  display: flex;
  gap: 0.96rem;
  width: 100%;
}
div.button-container {
  display: flex;
  flex: 1;
}
</style>
