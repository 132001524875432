import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["width", "height", "src"]

import { computed, PropType } from 'vue'

import { QrSection, EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle } from '@/api/types'
import { youtubeUrlToEmbed } from '@/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'EmbeddedVideoPlayer',
  props: {
  embeddedVideoSection: {
    type: Object as PropType<QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>>,
    required: true
  },
  playerStyle: {
    type: Object,
    default: null
  },
  playerWidth: {
    type: Number,
    required: true
  },
  playerHeight: {
    type: Number,
    required: true
  }
},
  setup(__props) {



const { section, style } = __props.embeddedVideoSection

const cssVarsPlayer = computed(() => {
  return {
    ...__props.playerStyle
  }
})

const controlsShow = computed(() => style.controls === 'show')
const getSrc = computed(() => youtubeUrlToEmbed(section.url))


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      width: __props.playerWidth,
      height: __props.playerHeight,
      style: _normalizeStyle(cssVarsPlayer.value),
      src: getSrc.value,
      class: "embedded-video-player"
    }, null, 12, _hoisted_2)
  ]))
}
}

})