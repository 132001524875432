import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LandingView from '../views/LandingView.vue'
import LoginView from '../views/LoginView.vue'
import ManageView from '../views/ManageView.vue'
import ManageQrView from '../views/ManageQrView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import QrTempView from '../views/QrTempView.vue'
import RegisterDetailsView from '../views/RegisterDetailsView.vue'
import RegisterRootView from '../views/RegisterRootView.vue'
import SubProfileView from '../views/SubProfileView.vue'
import VerifyEmailView from '../views/VerifyEmailView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Keypin',
    component: LandingView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterRootView
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: VerifyEmailView
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: RegisterDetailsView
  },
  {
    path: '/dashboard',
    name: 'manage',
    component: ManageView
  },
  {
    path: '/dashboard/:id',
    name: 'manageqr',
    component: ManageQrView
  },
  /* {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(webpackChunkName: "about"  '../views/AboutView.vue')
  },
  /* {
    path: '/:keypin',
    name: 'Profile',
    component: ProfileView
  }, */
  {
    path: '/qr/:id',
    name: 'Qr',
    component: QrTempView
  },
  {
    path: '/:name/:suffix',
    name: 'SubProfile',
    component: SubProfileView
  },
  {
    path: '/:name',
    name: 'SubProfileRoot',
    component: SubProfileView
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
