import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'StyleIconButton',
  props: {
  icon: {
    type: String,
    required: true
  },
  iconStyle: {
    type: Object,
    default: null
  },
  style: {
    type: Object,
    default: null
  },
  selected: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



const cssVars = computed(() => {
  return {
    ...__props.style,
    background: __props.selected
      ? 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
      : 'var(--color-light-grey)'
  }
})

const iconSrc = computed(() => {
  if (__props.selected) return require('@/assets/icons/svg/' + __props.icon + '-grad.svg')
  else return require('@/assets/icons/svg/' + __props.icon + '.svg')
})

const cssImgVars = computed(() => {
  return {
    ...__props.iconStyle
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-content-center align-items-center button-content",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("img", {
      src: iconSrc.value,
      style: _normalizeStyle(cssImgVars.value),
      alt: "icon",
      class: "icon"
    }, null, 12, _hoisted_1)
  ], 4))
}
}

})