import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'
import DropdownButton from './DropdownButton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownQrListItem',
  props: {
  id: {
    type: String,
    required: true
  },
  btnIcon: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  btnStyle: {
    type: Object,
    required: true
  }
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const cssButton = computed(() => {
  return {
    ...__props.btnStyle,
    backgroundColor: 'var(--color-light-grey)',
    width: '1.28rem',
    height: '1.28rem',
    borderRadius: '0.26rem',
    padding: '0.32rem'
  }
})

const cssLink = computed(() => {
  return {
    padding: '0.38rem 0.44rem'
  }
})

const cssLinkText = computed(() => {
  return {
    color: 'var(--color-nearly-black)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-14)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px'
  }
})

function onClick (id: number) {
  emit('onClick', id)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownButton, {
    id: __props.id,
    "btn-icon": __props.btnIcon,
    "btn-style": cssButton.value,
    "link-style": cssLink.value,
    "link-text-style": cssLinkText.value,
    items: __props.items,
    onOnClick: onClick
  }, null, 8, ["id", "btn-icon", "btn-style", "link-style", "link-text-style", "items"]))
}
}

})