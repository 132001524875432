<script setup lang="ts">
import { computed, ref } from 'vue'

const { name, label, labelVisible, type, placeholder, modelValue, hasError, errorMessage, containerStyle, wrapperStyle, multiline } = defineProps({
  name: String,
  label: String,
  labelVisible: Boolean,
  type: String,
  placeholder: String,
  modelValue: String,
  hasError: Boolean,
  errorMessage: String,
  containerStyle: {
    type: Object,
    default: null
  },
  wrapperStyle: {
    type: Object,
    default: null
  },
  multiline: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'onFocus'])

const isFocused = ref(false)

const value = computed({
  get () {
    return modelValue
  },
  set (value: string) {
    emit('update:modelValue', value)
  }
})

const cssContainerStyle = computed(() => {
  return {
    ...containerStyle,
    height: multiline ? 'auto' : '1.6rem'
  }
})

const cssWrapperStyle = computed(() => {
  return {
    ...wrapperStyle
  }
})

function onFocus () {
  isFocused.value = true
  emit('onFocus', true)
}

function onBlur () {
  isFocused.value = false
  emit('onFocus', false)
}

</script>

<template>
  <div class="input-container" :class="{ focused: isFocused }" :style="cssContainerStyle">
    <label v-if="isFocused || labelVisible" for="id" class="label">{{ label }}</label>
    <textarea
      v-if="multiline"
      id="id"
      :name="name"
      :type=type
      :placeholder="placeholder"
      class="input-wrapper"
      v-model="value"
      @focus="onFocus"
      @blur="onBlur"
      :style="cssWrapperStyle"
      rows="17"
    />
    <input
      v-else
      id="id"
      :name="name"
      :type=type
      :placeholder="placeholder"
      class="input-wrapper"
      v-model="value"
      @focus="onFocus"
      @blur="onBlur"
      :style="cssWrapperStyle"
    />
    <div v-if="hasError" class="error-message">
      <span>{{ errorMessage }}</span>
      <span class="error-icon">&#x26A0;</span>
    </div>
  </div>
</template>

<style scoped>
  .input-container {
    position: relative;
    display: flex;
    height: 1.6rem;
    border-radius: 12px;
    border: 1px solid rgba(33, 37, 41, 0.3);
    transition: border-color 0.3s;
  }

  .input-container.focused {
    position: relative;
    border: 2px solid transparent;
    border-radius: 12px;
  }

  .input-container.focused::after {
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -2px;
    background: var(--color-grad-bp);
    content: '';
    z-index: -1;
    border-radius: 12px;
  }

  .input-container.error {
    border-color: red;
  }

  .label {
    position: absolute;
    top: -12px;
    left: 8px;
    background: #fff;
    padding: 0 4px;
    font-size: var(--font-size-12);
    color: #ccc;
    transition: color 0.3s;
  }

  .input-container.focused .label {
    color: #4a90e2; /* Matches the gradient */
  }

  .input-container.error .label {
    color: red;
  }

  .input-wrapper {
    width: 100%;
    height: 100%;
    padding: 17px 20px;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: var(--font-size-16);
    overflow: hidden;
  }

  .error-message {
    position: relative;
    z-index: -1;
    height: 1rem;
    bottom: -0.96rem;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: red;
    background: rgba(255, 0, 0, 0.08);
    border-radius: 12px;
    width: 100%;
  }

  .error-icon {
    margin-left: 4px;
  }
</style>
