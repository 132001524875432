<script setup lang="ts">

import { computed } from 'vue'

const { icon, style, textStyle } = defineProps({
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  },
  textStyle: {
    type: Object,
    default: null
  }
})

const cssVars = computed(() => {
  return {
    ...style
  }
})

const cssVarsText = computed(() => {
  return {
    ...textStyle
  }
})

const iconSrc = computed(() => {
  return require('@/assets/icons/svg/' + icon + '.svg')
})
</script>

<template>
    <div class="d-flex justify-content-center align-items-center button-content" :style="cssVars">
        <div class="d-flex flex-column justify-content-center icon-container">
          <img :src="iconSrc" alt="icon" class="icon">
        </div>
        <p class="button-text" :style="cssVarsText"><slot/></p>
    </div>
</template>

<style scoped lang="scss">
  div.button-content {
    display: flex;
    gap: 0.32rem;
    cursor: pointer;
  }

  div.icon-container {
    width: 20px;
    height: 20px;
  }
  img.icon {
    width: 100%;
  }

  p.button-text {
    margin: 0px;
    color: var(--color-nearly-black);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px; /* 107.692% */
    opacity: 0.5;
  }
</style>
