<script setup lang="ts">

import { computed } from 'vue'
import DebouncedTextInput from './DebouncedTextInput.vue'
import { HandleDirective } from 'vue-slicksort'
import ImageInput from './ImageInput.vue'

const emit = defineEmits(['update:title', 'update:subtitle', 'update:url', 'update:image', 'delete'])

const { title, subTitle, url, imageUri } = defineProps({
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  url: {
    type: String,
    default: ''
  },
  imageUri: {
    type: String,
    default: ''
  }
})

const vHandle = HandleDirective

const cssImageInput = computed(() => {
  return {
    width: '4.096rem',
    height: '4.096rem'
  }
})

function onSetTitle (value: string) {
  emit('update:title', value)
}

function onSetSubtitle (value: string) {
  emit('update:subtitle', value)
}

function onSetUrl (value: string) {
  emit('update:url', value)
}

function onSelectImage (file: File) {
  emit('update:image', file)
}

function onDelete () {
  emit('delete')
}
</script>

<template>
    <div class="news-input-container">
        <ImageInput :uri="imageUri" :style="cssImageInput" @onSelect="onSelectImage"/>
        <div class="news-input-content">
          <div class="news-input-row">
            <p class="editor-input-label">Item title</p>
            <DebouncedTextInput
                :value="title"
                type="text"
                @update:value="onSetTitle"
            />
          </div>
          <div class="news-input-row">
            <p class="editor-input-label">Sub-title</p>
            <DebouncedTextInput
              :value="subTitle"
              type="text"
              @update:value="onSetSubtitle"
            />
          </div>
          <div class="news-input-row">
            <p class="editor-input-label">URL</p>
            <DebouncedTextInput
              :value="url"
              type="text"
              @update:value="onSetUrl"
            />
          </div>
        </div>
        <img class="delete-button-image" src="@/assets/icons/svg/editor-xmark.svg" @click="onDelete"/>
        <span v-handle class="handle grip-handle"><a class="grip-icon" href="#"><img class="grip-icon-image" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
    </div>
  </template>

<style scoped>
  .news-input-container {
    display: flex;
    width: 100%;
    min-height: 148px;
    border-radius: 8px;
    background-color: var(--color-light-grey);
    margin: 10px 0 0 0;
    padding: 0.32rem;
    display: flex;
    align-items: center;
    gap: 0.32rem;
  }
  .news-input-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.32rem;
    justify-content: space-between;
  }
  .news-input-row {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .editor-input-label {
    text-align: left;
    margin: 0;
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    width: 20%;
    opacity: 0.5;
  }
  img.delete-button-image {
    width: 0.78rem;
    height: 0.78rem;
    cursor: pointer;
    margin-left: 0.32rem;
  }
  a.grip-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img.grip-icon-image {
    height: 0.54rem;
    width: 0.32rem;
  }
  span.grip-handle {
    height: 0.54rem;
    width: 0.32rem;
    margin: 0 0.64rem 0 0.576rem;
  }
</style>
