<script setup lang="ts">
import { useEditorStore, useOnboardingStore } from '@/store'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginInput from '@/components/input/LoginInput.vue'
import PACombo from './PACombo.vue'

const router = useRouter()

const onboardingStore = useOnboardingStore()

const {
  email,
  password,
  confirmPassword,
  regCode
} = storeToRefs(onboardingStore)

const {
  setEmail,
  setPassword,
  setConfirmPassword,
  setRegCode,
  emailValidationError,
  passwordValidationError,
  confirmPasswordValidationError,
  regCodeValidationError,
  register
} = onboardingStore

const emit = defineEmits(['onNavigateLogin'])

const cssInputContainer = {
  width: '100%',
  margin: '0.48rem 0'
}

async function onRegister () {
  const status = await register()
  router.push('/register')
}

function onNavigateLogin () {
  emit('onNavigateLogin')
}
</script>

<template>
  <div class="login-form-container">
    <LoginInput
       name="email"
       label="Email"
       type="email"
       placeholder="Email"
       :model-value="email"
       @update:model-value="setEmail"
       @keydown.enter="onRegister"
       :container-style="cssInputContainer"
        />
    <LoginInput
        name="code"
        label="Beta-testing Code"
        type="text"
        placeholder="Beta-testing Code"
        :model-value="regCode"
        @update:model-value="setRegCode"
        @keydown.enter="onRegister"
        :container-style="cssInputContainer"
    />
    <LoginInput
        name="password"
        label="Password"
        type="password"
        placeholder="Password"
        :model-value="password"
        @update:model-value="setPassword"
        @keydown.enter="onRegister"
        :container-style="cssInputContainer"
    />
    <LoginInput
        name="confirmPassword"
        label="Confirm password"
        type="password"
        placeholder="Confirm password"
        :model-value="confirmPassword"
        @update:model-value="setConfirmPassword"
        @keydown.enter="onRegister"
        :container-style="cssInputContainer"
    />
    <LoginButtonGrad text="Continue" @click="onRegister"/>
    <PACombo
      @on-click="onNavigateLogin"
      text="Already signed up?"
      link-text="Login instead"
    />
   </div>
 </template>

<style scoped lang="scss">
  div.login-form-container{
    flex-direction: column;
    align-items: center;
    width: 60%;
  }
</style>
