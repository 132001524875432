<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue'

import { QrSection, TableSectionContent, TableSectionStyle } from '@/api/types'

const { tableSection } = defineProps({
  tableSection: {
    type: Object as PropType<QrSection<TableSectionContent, TableSectionStyle>>,
    required: true
  }
})

const { section, style } = tableSection

const cssVarsTL = computed(() => {
  return {
    '--opacity': '1.0',
    '--border-bottom': (style.header === 'row' || style.border === 0) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-top': style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-left': (style.border === 0 || style.border === 1) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsTC = computed(() => {
  return {
    '--opacity': style.header === 'row' ? '1.0' : '0.5',
    '--border-bottom': style.header === 'row' ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-top': style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsTR = computed(() => {
  return {
    '--opacity': style.header === 'row' ? '1.0' : '0.5',
    '--border-bottom': (style.header === 'row' || style.border === 0) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-top': style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsLC = computed(() => {
  return {
    '--opacity': style.header === 'col' ? '1.0' : '0.5',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-bottom': style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-left': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsCC = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsRC = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsBL = computed(() => {
  return {
    '--opacity': style.header === 'col' ? '1.0' : '0.5',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-bottom': style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-left': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsBC = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsBR = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})
</script>

<template >
    <div v-if="tableSection" class="table-container d-flex flex-row justify-content-center" :style="cssVars">
      <table>
        <!-- Table cant be less than 2x2-->
        <thead>
          <tr>
            <th :style="cssVarsTL"> {{ section.value[0][0] }}</th>
            <th v-for="(header, index) in section.value[0].slice(1, -1)" :key="index" :style="cssVarsTC">{{ header }}</th>
            <th :style="cssVarsTR"> {{ section.value[0].slice(-1)[0] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in section.value.slice(1, -1)" :key="rowIndex">
            <td :style="cssVarsLC">{{ row[0] }}</td>
            <td :style="cssVarsCC" v-for="(cell, cellIndex) in row.slice(1, -1)" :key="cellIndex">{{ cell }}</td>
            <td :style="cssVarsRC">{{ row.slice(-1)[0] }}</td>
          </tr>
          <tr>
            <td :style="cssVarsBL">{{ section.value[section.value.length - 1][0] }}</td>
            <td :style="cssVarsBC" v-for="(cell, cellIndex) in section.value[section.value.length - 1].slice(1, -1)" :key="cellIndex">{{ cell }}</td>
            <td :style="cssVarsBR">{{ section.value[section.value.length - 1].slice(-1)[0] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<style scoped lang="scss">
    div.table-container {
        width: 90%;
        background-color: transparent;
        margin-top: 40px;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
    }
    td, th {
      color: var(--color-nearly-black);
      font-family: Inter;
      opacity: var(--opacity);
      text-align: center;
      border-bottom: var(--border-bottom);
      border-top: var(--border-top);
      border-left: var(--border-left);
      border-right: var(--border-right);
    }
    td {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    th {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }
</style>
