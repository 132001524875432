import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "qr-grid-container" }


import QRGridItem from '@/components/dashboard/QRGridItem.vue'

import { ListSubProfile } from '@/api/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'QRGrid',
  props: {
  subProfiles: Array
},
  emits: ['onDownload', 'onSelect', 'onDelete'],
  setup(__props, { emit: __emit }) {



const emit = __emit

function onSelect (id: string) {
  emit('onSelect', id)
}

function onDelete (id: string) {
  emit('onDelete', id)
}

function onDownload (type: string, id: string) {
  emit('onDownload', type, id)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((__props.subProfiles as ListSubProfile[]), (subProfile) => {
      return (_openBlock(), _createElementBlock("div", {
        key: subProfile.id,
        class: "qr-grid-item-container"
      }, [
        _createVNode(QRGridItem, {
          subProfile: subProfile,
          interactive: true,
          onOnSelect: onSelect,
          onOnDelete: onDelete,
          onOnDownload: onDownload
        }, null, 8, ["subProfile"])
      ]))
    }), 128))
  ]))
}
}

})