import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "add-socials-container" }
const _hoisted_2 = { class: "add-container" }
const _hoisted_3 = { class: "buttons-container" }
const _hoisted_4 = { class: "back-continue-buttons-container" }
const _hoisted_5 = { class: "button-container" }
const _hoisted_6 = { class: "button-container" }

import { computed, ref } from 'vue'

import { storeToRefs } from 'pinia'

import { useEditorStore, useOnboardingStore } from '@/store'

import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import LoginButtonWhite from '@/components/button/LoginButtonWhite.vue'
import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginButtonSkip from '@/components/button/LoginButtonSkip.vue'

import { SOCIAL_TYPE_TO_ICON } from '@/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'AddSocialsForm',
  setup(__props) {

const editorStore = useEditorStore()
const onboardingStore = useOnboardingStore()

const { selectedSocials } = storeToRefs(editorStore)
const { clearSocials, editSelectedSocialByIndex, submitSocials, fetchUserSubProfile } = editorStore

const { setMode } = onboardingStore

const cssItemIcon = computed(() => {
  return {
    width: '0.64rem',
    height: '0.64rem'
  }
})

const cssItemIconContainer = computed(() => {
  return {
    backgroundColor: 'var(--color-white)',
    borderRadius: '0.26rem',
    border: '1px solid rgba(33, 37, 41, 0.15)',
    width: '1.28rem',
    height: '1.28rem',
    alignItems: 'center',
    padding: '0.32rem',
    display: 'flex',
    marginRight: '0.32rem'
  }
})

function getIcon (type: string): string {
  return SOCIAL_TYPE_TO_ICON[type].value
}

function onEdit (index: number, value: string) {
  editSelectedSocialByIndex(index, value)
}

function onBack () {
  setMode('select-socials')
}

async function onContinue () {
  // Concurrency trial
  const status = await Promise.all([submitSocials(), fetchUserSubProfile()])
  setMode('add-details')
}

async function onSkip () {
  clearSocials()
  const status = await fetchUserSubProfile()
  setMode('add-details')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedSocials), (item, index) => {
        return (_openBlock(), _createBlock(EditorInputComponent, {
          key: index,
          "icon-src": require(`@/assets/${getIcon(item.type)}`),
          "icon-style": cssItemIcon.value,
          "icon-container-style": cssItemIconContainer.value,
          value: item.value,
          onOnChangeValue: (value) => onEdit(index, value)
        }, null, 8, ["icon-src", "icon-style", "icon-container-style", "value", "onOnChangeValue"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(LoginButtonWhite, {
            text: "Back",
            onClick: onBack
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(LoginButtonGrad, {
            text: "Continue",
            onClick: onContinue
          })
        ])
      ]),
      _createVNode(LoginButtonSkip, {
        text: "Skip",
        onClick: onSkip
      })
    ])
  ]))
}
}

})