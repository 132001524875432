import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"



import TagComponent from '@/components/tag/TagComponent.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QrUnpublishedTag',
  props: {
  style: Object
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TagComponent, {
    "background-color": "#F1F1F1",
    color: "#212529",
    style: _normalizeStyle(__props.style)
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" Unpublished ")
    ])),
    _: 1
  }, 8, ["style"]))
}
}

})