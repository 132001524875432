<script setup lang="ts">
import { computed } from 'vue'

const { type, label, isSelected } = defineProps({
  type: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  isSelected: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['onSelect'])

const iconSrc = computed(() => {
  return require(`@/assets/icons/socials/icon-${type}.png`)
})

const opIconSrc = computed(() => {
  if (isSelected) return require('@/assets/icons/svg/editor-xmark.svg')
  return require('@/assets/icons/svg/editor-plus-circ.svg')
})

function onSelect () {
  emit('onSelect', type)
}

</script>

<template>
    <div class="select-social-button-container" :class="{ selected: isSelected }" @click="onSelect">
        <img class="social-icon" :src="iconSrc"/>
        <p class="social-label">{{ label }}</p>
        <img class="op-icon" :src="opIconSrc"/>
    </div>
</template>

<style scoped>
.select-social-button-container {
    height: 1.28rem;
    position: relative;
    display: flex;
    padding: 0.25rem;
    border: 1px solid rgba(33, 37, 41, 0.15);
    border-radius: 0.38rem;
    cursor: pointer;
}

.select-social-button-container.selected {
    position: relative;
    border: 2px solid var(--color-keypin-blue);
    border-radius: 0.38rem;
    background: linear-gradient(135deg, rgba(46, 117, 255, 0.05) 0%, rgba(122, 61, 255, 0.05) 99.99%, rgba(123, 61, 255, 0.05) 100%);
}

/* .select-social-button-container.selected::after {
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -2px;
    background: var(--color-grad-bp);
    border-radius: 0.38rem;
} */

img {
    width: 0.76rem;
    height: 0.76rem;
}

p.social-label {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-13);
    font-weight: 500;
    margin: 0 0.32rem;
    padding-top: 2px;
}
</style>
