import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex table-panel-content"
}
const _hoisted_2 = { class: "table-container" }
const _hoisted_3 = { class: "add-col-container d-flex" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "add-col-container" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "d-flex flex-column table-style-panel-content"
}
const _hoisted_10 = { class: "d-flex table-style-panel-row align-items-center" }
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = { class: "d-flex table-style-panel-row align-items-center" }
const _hoisted_13 = { class: "d-flex" }

import { computed, ref, PropType, useTemplateRef, ShallowRef } from 'vue'

import { QrSection, QrSectionTargetEnum, TableSectionContent, TableSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleIconButton from '@/components/button/style-panel/StyleIconButton.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TablePanel',
  props: {
  tableSection: {
    type: Object as PropType<QrSection<TableSectionContent, TableSectionStyle>>,
    required: true
  }
},
  emits: ['onToggleMode'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = __emit



const styleMode = ref(false)

const { id, label, active, persistent, section, style } = __props.tableSection

const tableRef = section.value.map(
  (row, ridx) => row.map(
    (cell, cidx) => useTemplateRef('cell-' + ridx.toString() + '-' + cidx.toString())
  )
)

const cssInputContainer = computed(() => ({
  height: '100%'
}))

const cssInputWrapper = computed(() => ({
  'border-radius': '0',
  border: '1px solid rgba(33, 37, 41, 0.15)',
  background: 'transparent',
  focusedBorder: '1px solid var(--bp---Gradient, #2E75FF)',
  focusedBrackground: '#fff',
  height: '100%'
}))

const cssInput = computed(() => ({}))

const styleRow = computed(() => __props.tableSection.style.header === 'row')

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

/* function focusCell (r: number, c: number) {
  tableRef[r][c].value.focus()
} */

function onAddCells (type: string) {
  const newValue = [...section.value]
  if (type === 'row') {
    const newRow: string[] = []
    for (let i = 0; i < section.value[0].length; i++) {
      newRow.push('New Cell')
    }
    newValue.push(newRow)
  } else {
    newValue.forEach((row) => {
      row.push('New Cell')
    })
  }
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'value', newValue)
}

function onRemoveCells (type: string) {
  const newValue = [...section.value]
  if (type === 'row') {
    newValue.pop()
  } else {
    newValue.forEach((row) => {
      row.pop()
    })
  }
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'value', newValue)
}

function onEditCell (value: string, r: number, c: number) {
  const newValue = [...section.value]
  newValue[r][c] = value
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'value', newValue)
  // NOW KEEP TRACK OF R AND C
}

function onEditHeader (value: string) {
  if (value === __props.tableSection.style.header) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'header', value)
}

function onEditBorder (value: number) {
  if (value === __props.tableSection.style.border) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'border', value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    onOnToggleMode: onToggleMode
  }, {
    default: _withCtx(() => [
      (!styleMode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("table", null, [
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).value, (row, rowIndex) => {
                    return (_openBlock(), _createElementBlock("tr", { key: rowIndex }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (cell, cellIndex) => {
                        return (_openBlock(), _createElementBlock("td", { key: cellIndex }, [
                          _createVNode(DebouncedTextInput, {
                            ref_for: true,
                            ref: 'cell-' + rowIndex.toString() + '-' + cellIndex.toString(),
                            value: cell,
                            type: "text",
                            placeholder: "title",
                            "onUpdate:value": (value) => onEditCell(value, rowIndex, cellIndex),
                            containerStyle: cssInputContainer.value,
                            wrapperStyle: cssInputWrapper.value,
                            inputStyle: cssInput.value,
                            "border-on-focus": true
                          }, null, 8, ["value", "onUpdate:value", "containerStyle", "wrapperStyle", "inputStyle"])
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                (_unref(section).value.length < 8)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "add-row-col-button",
                      onClick: _cache[0] || (_cache[0] = () => onAddCells('row'))
                    }, [
                      _createElementVNode("img", {
                        src: require('@/assets/icons/svg/plus-table.svg')
                      }, null, 8, _hoisted_4)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(section).value.length > 2)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "rem-row-col-button mx-2",
                      onClick: _cache[1] || (_cache[1] = () => onRemoveCells('row'))
                    }, [
                      _createElementVNode("img", {
                        src: require('@/assets/icons/svg/minus-table.svg')
                      }, null, 8, _hoisted_5)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_unref(section).value[0].length < 8)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "add-row-col-button",
                    onClick: _cache[2] || (_cache[2] = () => onAddCells('col'))
                  }, [
                    _createElementVNode("img", {
                      src: require('@/assets/icons/svg/plus-table.svg')
                    }, null, 8, _hoisted_7)
                  ]))
                : _createCommentVNode("", true),
              (_unref(section).value[0].length > 2)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "rem-row-col-button",
                    onClick: _cache[3] || (_cache[3] = () => onRemoveCells('col'))
                  }, [
                    _createElementVNode("img", {
                      src: require('@/assets/icons/svg/minus-table.svg')
                    }, null, 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Header", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(StyleTextIconButton, {
                  icon: 'table-style-row',
                  selected: styleRow.value,
                  onClick: _cache[4] || (_cache[4] = () => onEditHeader('row'))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("First Row")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextIconButton, {
                  icon: 'table-style-col',
                  selected: !styleRow.value,
                  onClick: _cache[5] || (_cache[5] = () => onEditHeader('col'))
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("First Column")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[9] || (_cache[9] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Border", -1)),
              _createElementVNode("div", _hoisted_13, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
                  return _createVNode(StyleIconButton, {
                    key: (i-1),
                    icon: 'table-style-border-'+(i-1).toString(),
                    selected: __props.tableSection.style.border === (i-1),
                    onClick: () => onEditBorder(i-1)
                  }, null, 8, ["icon", "selected", "onClick"])
                }), 64))
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})