<script setup lang="ts">
import { computed } from 'vue'

import { useOnboardingStore } from '@/store'

import { storeToRefs } from 'pinia'

import AddDetailsForm from '@/components/form/AddDetailsForm.vue'
import AddSocialsForm from '@/components/form/AddSocialsForm.vue'
import SelectSocialsForm from '@/components/form/SelectSocialsForm.vue'

const onboardingStore = useOnboardingStore()
const { mode } = storeToRefs(onboardingStore)

const headingText = computed(() => {
  switch (mode.value) {
    case 'select-socials': return 'Which platforms are you on?'
    case 'add-socials': return 'Add your links'
    case 'add-details': return 'Add Details'
  }
  return ''
})

const subHeadingText = computed(() => {
  switch (mode.value) {
    case 'select-socials': return 'Get started with your links'
    case 'add-socials': return 'Complete the fields below to quick add your links on pages.'
    case 'add-details': return 'Add page Title and Description / Bio'
  }
  return ''
})

const cssSubHeading = computed(() => {
  return {
    margin: mode.value === 'add-details' ? '0.38rem 0 0.48rem 0' : '5.2rem 0 0.48rem 0'
  }
})

</script>

<template>
   <div class="login-container">
      <div class="form-container">
         <img class="header-image" src="@/assets/logo-grad-text.png">
         <p class="header-text">{{ headingText }}</p>
         <p class="header-sub-text" :style="cssSubHeading">{{ subHeadingText }}</p>
         <div class="form-content">
            <SelectSocialsForm v-if="mode === 'select-socials'"/>
            <AddSocialsForm v-else-if="mode === 'add-socials'"/>
            <AddDetailsForm v-else-if="mode === 'add-details'"/>
         </div>
      </div>
   </div>
 </template>

<style scoped lang="scss">
   div.login-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 0.64rem;
   }
  div.form-container{
    flex-direction: column;
    align-items: center;
    flex: 1;
    display: flex;
  }
  img.header-image {
    margin-top: 0.32rem;
    width: 100px;
    height: 36px;
  }
  p.header-text, p.header-sub-text {
    text-align: center;
    font-family: Inter;
    color: var(--color-nearly-black);
  }
  p.header-text {
   margin: 0.38rem 0 0 0;
   font-size: var(--font-size-25);
   font-weight: 700;
  }
  p.header-sub-text {
   font-size: var(--font-size-14);
   font-weight: 500;
   opacity: 0.5;
  }
  div.form-content {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
</style>
