<script setup lang="ts">
import { defineProps } from 'vue'
import QRListItem from '@/components/dashboard/QRListItem.vue'

import { ListSubProfile } from '@/api/types'

const { subProfiles } = defineProps({
  subProfiles: Array
})

const emit = defineEmits(['onDownload', 'onSelect', 'onDelete'])

function onSelect (id: string) {
  emit('onSelect', id)
}

function onDelete (id: string) {
  emit('onDelete', id)
}

function onDownload (type: string, id: string) {
  emit('onDownload', type, id)
}

</script>

<template>
    <div
      v-for="subProfile in (subProfiles as ListSubProfile[])"
      :key="subProfile.id"
      class="row">
       <QRListItem
          :subProfile="subProfile"
          :interactive="true"
          @onSelect=onSelect
          @onDelete=onDelete
          @onDownload=onDownload
        />
      </div>
</template>

<style scoped lang="scss">
  li {
      margin-top: 20px;
  }
  li.manage-qr{
    transition: all .2s ease-in-out;
  }
  li.manage-qr:hover{
    transform: scale(1.05);
  }
</style>
