import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, vModelText as _vModelText } from "vue"

const _hoisted_1 = ["type", "name", "placeholder"]
const _hoisted_2 = ["maxlength", "placeholder"]
const _hoisted_3 = { key: 3 }

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextInput',
  props: {
  label: {
    String,
    default: ''
  },
  maxlength: {
    type: String,
    default: '200'
  },
  rightImageUri: {
    type: String,
    default: ''
  },
  containerStyle: {
    type: Object,
    default: null
  },
  wrapperStyle: {
    type: Object,
    default: null
  },
  textStyle: {
    type: Object,
    default: null
  },
  labelStyle: {
    type: Object,
    default: null
  },
  rightComponentStyle: {
    type: Object,
    default: null
  },
  multiline: {
    type: Boolean,
    default: false
  },
  name: String,
  placeholder: String,
  type: String,
  modelValue: String
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const value = computed({
  get () {
    return __props.modelValue
  },
  set (value: string) {
    emit('update:modelValue', value)
  }
})

const cssVarsLabel = computed(() => {
  return {
    ...__props.labelStyle
  }
})

const cssVarsContainer = computed(() => {
  return {
    ...__props.containerStyle
  }
})

const cssVarsWrapper = computed(() => {
  return {
    ...__props.wrapperStyle
  }
})

const cssVarsText = computed(() => {
  return {
    ...__props.textStyle
  }
})

const cssVarsRightComponent = computed(() => {
  return {
    ...__props.rightComponentStyle
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "text-input-container",
    style: _normalizeStyle(cssVarsContainer.value)
  }, [
    (__props.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: "text-input",
          class: "text-input-label",
          style: _normalizeStyle(cssVarsLabel.value)
        }, _toDisplayString(__props.label), 5))
      : _createCommentVNode("", true),
    (!__props.multiline)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "text-input-wrapper",
          style: _normalizeStyle(cssVarsWrapper.value)
        }, [
          _withDirectives(_createElementVNode("input", {
            class: "text-input-text",
            type: __props.type,
            name: __props.name,
            placeholder: __props.placeholder,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
            style: _normalizeStyle(cssVarsText.value)
          }, null, 12, _hoisted_1), [
            [_vModelDynamic, value.value]
          ]),
          _renderSlot(_ctx.$slots, "default")
        ], 4))
      : _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 2,
          class: "text-input-wrapper",
          maxlength: __props.maxlength,
          placeholder: __props.placeholder,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((value).value = $event))
        }, null, 8, _hoisted_2)), [
          [_vModelText, value.value]
        ]),
    (__props.multiline)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(__props.modelValue?.length + '/' + __props.maxlength), 1))
      : _createCommentVNode("", true)
  ], 4))
}
}

})