import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "editor-panel-header-container" }
const _hoisted_2 = { class: "handle grip-handle" }
const _hoisted_3 = {
  class: "grip-icon",
  href: "#"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "label-text" }
const _hoisted_6 = { class: "controls-container d-flex flex-row align-items-center justify-content-end" }

import { computed, ref, PropType } from 'vue'

import { QrSectionTargetEnum } from '@/api/types'
import { useEditorStore } from '@/store'

import DropdownQrSectionEditDelete from '@/components/dropdown/DropdownQrSectionEditDelete.vue'
import EditorToggle from '../switch/EditorToggle.vue'
import SwitchComponent from '../switch/Switch.vue'
import { HandleDirective } from 'vue-slicksort'


export default /*@__PURE__*/_defineComponent({
  __name: 'EditorPanel',
  props: {
  id: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  active: {
    type: Boolean,
    required: true
  },
  persistent: {
    type: Boolean,
    required: true
  },
  styleMode: {
    type: Boolean,
    required: true
  },
  hasStyle: {
    type: Boolean,
    default: true
  },
  containerStyle: {
    type: Object,
    default: null
  }
},
  emits: ['onToggleMode'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const editorStore = useEditorStore()
const { patchQrSection, deleteQrSection, duplicateQrSection } = editorStore



const vHandle = HandleDirective
const isActive = ref(__props.active)

const cssVarsContainer = computed(() => {
  return {
    ...__props.containerStyle
  }
})

function onSetActive (value: boolean) {
  isActive.value = value
  patchQrSection(__props.id, QrSectionTargetEnum.HEADER, 'active', value)
}

function onDelete () {
  deleteQrSection(__props.id)
}

function onDuplicate () {
  duplicateQrSection(__props.id)
}

function onToggleMode () {
  emit('onToggleMode')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "editor-panel-container",
    style: _normalizeStyle(cssVarsContainer.value)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("img", {
            class: "grip-icon-image",
            src: require('@/assets/icons/icon-grip-1.png')
          }, null, 8, _hoisted_4)
        ])
      ])), [
        [_unref(vHandle)]
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(__props.title), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(DropdownQrSectionEditDelete, {
          id: 'qr-section-edit-delete-dropdown-'+__props.id,
          onOnClickDelete: onDelete,
          onOnClickDuplicate: onDuplicate
        }, null, 8, ["id"]),
        _createVNode(SwitchComponent, {
          initialValue: isActive.value,
          "v-model": isActive.value,
          "onUpdate:modelValue": onSetActive
        }, null, 8, ["initialValue", "v-model"]),
        (__props.hasStyle)
          ? (_openBlock(), _createBlock(EditorToggle, {
              key: 0,
              styleMode: __props.styleMode,
              style: {"margin-left":"10px"},
              onClick: onToggleMode
            }, null, 8, ["styleMode"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}
}

})