import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_2 = { "clip-path": "url(#clip0_801_2372)" }
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("g", _hoisted_2, [
        _createElementVNode("path", {
          d: "M24 12.073C24 5.40577 18.6269 0 12 0C5.37308 0 0 5.40577 0 12.073C0 18.0985 4.38757 23.0934 10.1251 24V15.5636H7.07748V12.073H10.1251V9.41256C10.1251 6.3871 11.9173 4.71478 14.6579 4.71478C15.9708 4.71478 17.3444 4.95077 17.3444 4.95077V7.9219H15.8306C14.3406 7.9219 13.8749 8.85229 13.8749 9.8082V12.073H17.2026L16.6711 15.5636H13.8749V24C19.6124 23.0951 24 18.1002 24 12.073Z",
          fill: _ctx.color
        }, null, 8, _hoisted_3)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("defs", null, [
        _createElementVNode("clipPath", { id: "clip0_801_2372" }, [
          _createElementVNode("rect", {
            width: "24",
            height: "24",
            fill: "white"
          })
        ])
      ], -1))
    ]))
  ], 4))
}