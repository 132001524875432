import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["index", "onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "news-text-container" }

import { computed, PropType } from 'vue'

import { useSubProfileStore } from '@/store'

import { QrSection, QrSectionItem, NewsSectionContent, NewsSectionStyle, NewsSectionItemContent } from '@/api/types'

import IconChevronNewsSectionSvg from '@/components/svg/qr-section/IconChevronNewsSectionSvg.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsSection',
  props: {
  newsSection: {
    type: Object as PropType<QrSection<NewsSectionContent, NewsSectionStyle>>,
    required: true
  }
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {

const subProfileStore = useSubProfileStore()

const { getImageUrl } = subProfileStore



const emit = __emit

const { section, style } = __props.newsSection

const cssVarsContainer = computed(() => {
  return {
    '--background': style.backgroundColor
  }
})

const cssVarsItem = computed(() => {
  return {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), ' + style.backgroundColor
  }
})

const cssVarsText = computed(() => {
  return {
    '--text-align': style.align,
    '--color': style.textColor
  }
})

function onClick (newsItem: QrSectionItem<NewsSectionItemContent>) {
  window.open(newsItem.content.url, '_blank')
  emit('onClick', newsItem.id)
}


return (_ctx: any,_cache: any) => {
  return (__props.newsSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "news-body",
        style: _normalizeStyle(cssVarsContainer.value)
      }, [
        (__props.newsSection.labelVisible)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "label-text",
              style: _normalizeStyle(cssVarsText.value)
            }, _toDisplayString(__props.newsSection.label), 5))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).items, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            index: item.id,
            class: "news-item",
            style: _normalizeStyle(cssVarsItem.value),
            onClick: () => onClick(item)
          }, [
            _createElementVNode("img", {
              class: "news-image",
              src: _unref(getImageUrl)(item.content.imageUri)
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", {
                class: "news-title",
                style: _normalizeStyle(cssVarsText.value)
              }, _toDisplayString(item.content.title), 5),
              _createElementVNode("p", {
                class: "news-sub-title",
                style: _normalizeStyle(cssVarsText.value)
              }, _toDisplayString(item.content.subtitle), 5)
            ]),
            _createVNode(IconChevronNewsSectionSvg, {
              color: _unref(style).textColor
            }, null, 8, ["color"])
          ], 12, _hoisted_1))
        }), 128))
      ], 4))
    : _createCommentVNode("", true)
}
}

})