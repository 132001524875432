import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "social-label" }
const _hoisted_3 = ["src"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectSocialButton',
  props: {
  type: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  isSelected: {
    type: Boolean,
    required: true
  }
},
  emits: ['onSelect'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const iconSrc = computed(() => {
  return require(`@/assets/icons/socials/icon-${__props.type}.png`)
})

const opIconSrc = computed(() => {
  if (__props.isSelected) return require('@/assets/icons/svg/editor-xmark.svg')
  return require('@/assets/icons/svg/editor-plus-circ.svg')
})

function onSelect () {
  emit('onSelect', __props.type)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["select-social-button-container", { selected: __props.isSelected }]),
    onClick: onSelect
  }, [
    _createElementVNode("img", {
      class: "social-icon",
      src: iconSrc.value
    }, null, 8, _hoisted_1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.label), 1),
    _createElementVNode("img", {
      class: "op-icon",
      src: opIconSrc.value
    }, null, 8, _hoisted_3)
  ], 2))
}
}

})