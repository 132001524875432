<template>
  <div id="wrapper">
    <SideBar page="analytics"/>
    <router-view/>
  </div>
</template>

<script>
import SideBar from './components/nav/SideBar.vue'

export default {
  name: 'App',
  components: {
    SideBar
  }
}
</script>

<style lang="scss">
*{
  padding: 0px;
  margin: 0px;
}
</style>
