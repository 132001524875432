<template>
    <div class="editor-num-input-container" :style=cssVars>
        <p class="editor-num-input-label">{{ this.label }}</p>
        <div class="input-container">
            <DebouncedTextInput
              :value="String(value)"
              type="text"
              @update:value="onChange"
              :container-style="cssInputContainer"
              :wrapper-style="cssInputWrapper"
              :input-style="cssInput"
              />
        </div>
    </div>
  </template>

<script lang="ts">

import { defineComponent } from 'vue'

import DebouncedTextInput from './DebouncedTextInput.vue'

export default defineComponent({
  name: 'EditorNumInputComponent',
  components: {
    DebouncedTextInput
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    style: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      internalValue: this.value,
      valid: true
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    },
    cssInputContainer () {
      return {
        height: '100%'
      }
    },
    cssInputWrapper () {
      return {
        width: '100%',
        border: 'none',
        background: 'transparent',
        height: '100%',
        padding: '0',
        display: 'flex',
        alignItems: 'center'
      }
    },
    cssInput () {
      return {
        color: 'var(--color-nearly-black)',
        textAlign: 'right',
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px' /* 153.846% */
      }
    }
  },
  watch: {
    value (value) {
      this.internalValue = value
    }
  },
  methods: {
    onChange (value: string) {
      this.valid = /^(0|[1-9][0-9]*)$/.test(value)
      if (this.valid) {
        this.$emit('onChange', Number(value))
      }
    }
  }
})

</script>

<style scoped>

  div.editor-num-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--color-light-grey);
    padding: 10px;
    width: 125px;
    height: 36px;
    flex-shrink: 0;
  }

  div.color-indicator {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid rgba(33, 37, 41, 0.10);
    background: var(--indicator-color);
  }

  div.input-container {
    display: flex;
    width: 80%;
  }

  p.editor-num-input-label {
    margin: 0;
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px; /* 100% */
    opacity: 0.5;
  }

  </style>
