<script setup lang="ts">
import { computed, PropType } from 'vue'

import { QrSection, ImageSectionContent } from '@/api/types'
import { useSubProfileStore } from '@/store'

const subProfileStore = useSubProfileStore()

const { getImageUrl } = subProfileStore

const { imageSection } = defineProps({
  imageSection: {
    type: Object as PropType<QrSection<ImageSectionContent, null>>,
    required: true
  }
})

const imageUrl = computed(() => getImageUrl(imageSection.section.uri))

</script>

<template >
    <div v-if="imageSection" class="image-container d-flex justify-content-center py-5">
        <img class="image" :src=imageUrl crossorigin="anonymous">
    </div>
</template>

<style scoped lang="scss">
    div.image-container{
        width: 100%;
        background-color: #F1F1F1;
    }
    img.image{
        width: 60%;
        aspect-ratio: 1 / 1;
    }
</style>
