<template>
     <div class="editor-toggle-container" :style="cssVars">
      <img class="editor-toggle-icon" :src="iconSrc"/>
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EditorToggle',
  props: {
    styleMode: {
      type: Boolean,
      required: true
    },
    style: {
      type: Object,
      default: null
    }
  },
  computed: {

    iconSrc () {
      if (this.styleMode) return require('@/assets/icons/svg/editor-panel-s.svg')
      else return require('@/assets/icons/svg/editor-panel-e.svg')
    },
    cssVars () {
      return {
        ...this.style
      }
    }
  }
})
</script>

<style scoped>
  div.editor-toggle-container {
    width: 76px;
    height: 40px;
  }

  img.editor-toggle-icon {
    width: 100%;
    height: 100%;
    cursor: pointer;
    vertical-align: top;
  }

</style>
