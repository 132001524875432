<template>
  <div class="login-page">
      <div class="container">
         <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
               <div class="card login" v-bind:class="{ error: emptyFields }">
                  <img :src="require(`@/assets/logo.png`)"/>
                  <p>We have sent a 6 digit code to your email address. Enter it to verify your account</p>
                  <form class="form-group" @submit.prevent="verify">
                     <input v-model="code" type="text" class="form-control" placeholder="Code" required>
                     <input
                        type="submit"
                        class="btn btn-primary"
                        value="Verify Email"
                        :disabled="!codeValid"
                        >
                  </form>
               </div>
            </div>
         </div>

      </div>
   </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { validate } from '@babel/types'

export default defineComponent({
  name: 'VerifyEmailView',
  data () {
    return {
      email: '',
      code: '',
      codeValid: false,
      emptyFields: false
    }
  },
  watch: {
    code (value) {
      this.code = value
      this.validateCode()
    }
  },
  mounted () {
    this.email = this.$store.state.verificationEmail
  }
})
</script>

<style scoped lang="scss">
  p {
    line-height: 1rem;
    margin-bottom: 25px;
  }
  img {
    height: 200px;
    width: 200px;
    align-self: center;
    margin: 10px;
  }

  .card {
    padding: 20px;
  }

  .form-group {
    input {
      margin-bottom: 20px;
    }
  }

  .login-page {
    align-items: center;
    display: flex;
    height: 100vh;

    .wallpaper-login {
      background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
        no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
    }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }
   .fade-enter,
   .fade-leave-to {
      opacity: 0;
   }

   .wallpaper-register {
      background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
         no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
   }

   h1 {
      margin-bottom: 1.5rem;
   }
}

.error {
   animation-name: errorShake;
   animation-duration: 0.3s;
}

@keyframes errorShake {
   0% {
      transform: translateX(-25px);
   }
   25% {
      transform: translateX(25px);
   }
   50% {
      transform: translateX(-25px);
   }
   75% {
      transform: translateX(25px);
   }
   100% {
      transform: translateX(0);
   }
}

</style>
