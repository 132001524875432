<script setup lang="ts">
import { computed, ref, PropType } from 'vue'

import { QrSectionTargetEnum } from '@/api/types'
import { useEditorStore } from '@/store'

import DropdownQrSectionEditDelete from '@/components/dropdown/DropdownQrSectionEditDelete.vue'
import EditorToggle from '../switch/EditorToggle.vue'
import SwitchComponent from '../switch/Switch.vue'
import { HandleDirective } from 'vue-slicksort'

const emit = defineEmits(['onToggleMode'])

const editorStore = useEditorStore()
const { patchQrSection, deleteQrSection, duplicateQrSection } = editorStore

const { id, title, active, persistent, styleMode, hasStyle, containerStyle } = defineProps({
  id: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  active: {
    type: Boolean,
    required: true
  },
  persistent: {
    type: Boolean,
    required: true
  },
  styleMode: {
    type: Boolean,
    required: true
  },
  hasStyle: {
    type: Boolean,
    default: true
  },
  containerStyle: {
    type: Object,
    default: null
  }
})

const vHandle = HandleDirective
const isActive = ref(active)

const cssVarsContainer = computed(() => {
  return {
    ...containerStyle
  }
})

function onSetActive (value: boolean) {
  isActive.value = value
  patchQrSection(id, QrSectionTargetEnum.HEADER, 'active', value)
}

function onDelete () {
  deleteQrSection(id)
}

function onDuplicate () {
  duplicateQrSection(id)
}

function onToggleMode () {
  emit('onToggleMode')
}
</script>

<template>
    <div class="editor-panel-container" :style="cssVarsContainer">
      <div class="editor-panel-header-container">
        <span v-handle class="handle grip-handle"><a class="grip-icon" href="#"><img class="grip-icon-image" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
        <p class="label-text">{{ title }}</p>
        <div class="controls-container d-flex flex-row align-items-center justify-content-end">
          <DropdownQrSectionEditDelete :id="'qr-section-edit-delete-dropdown-'+id" @onClickDelete="onDelete" @onClickDuplicate="onDuplicate"/>
          <SwitchComponent :initialValue="isActive" :v-model="isActive" @update:modelValue="onSetActive"/>
          <EditorToggle v-if="hasStyle" :styleMode="styleMode" style="margin-left: 10px" @click="onToggleMode"/>
        </div>
      </div>
      <slot />
    </div>
</template>

<style scoped>

div.editor-panel-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 20px;
  padding: 0.64rem;
  border-radius: 0.52rem;
}

div.editor-panel-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.64rem;
}

span.grip-handle {
  display: flex;
  align-items: center;
  line-height: 0;
}
img.grip-icon-image {
  height: 0.53rem;
  aspect-ratio: 0.75;
  margin-right: 0.32rem;
}

div.controls-container {
  margin-left: auto;
}

p.label-text {
  color: var(--color-nearly-black);
  font-family: Inter;
  font-weight: 600;
  line-height: 20px; /* 125% */
  font-size: var(--font-size-16);
  margin: 0;
}

</style>
