import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'
import { useRoute } from 'vue-router'

import Palette from '@/theme/palette'


export default /*@__PURE__*/_defineComponent({
  __name: 'SideBarLink',
  props: {
  name: {
    type: String,
    default: ''
  },
  src: {
    type: String,
    default: ''
  },
  srcSelected: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const route = useRoute()



const cssVarsContainer = computed(() => {
  return {
    '--background': __props.name === route.name
      ? 'linear-gradient(116deg, rgba(63, 128, 255, 0.1) 16.27%, rgba(123, 61, 255, 0.1) 83.73%)'
      : Palette.white,
    '--opacity': __props.disabled ? '0.5' : '1.0',
    '--cursor': __props.disabled ? 'default' : 'pointer'
  }
})

const cssVarsText = computed(() => {
  if (__props.name === route.name) return { '--font-weight': 600, '--opacity': 1.0 }
  else return { '--font-weight': 400, '--opacity': 1.0 }
})

const srcImg = computed(() => {
  if (__props.name === route.name) return require(`@/assets/icons/svg/${__props.srcSelected}`)
  else return require(`@/assets/icons/svg/${__props.src}`)
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "sidebar-link-container",
    style: _normalizeStyle(cssVarsContainer.value),
    href: "/analytics"
  }, [
    _createElementVNode("img", {
      class: "sidebar-link-icon",
      src: srcImg.value
    }, null, 8, _hoisted_1),
    _createElementVNode("span", {
      class: "sidebar-link-text",
      style: _normalizeStyle(cssVarsText.value)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ], 4))
}
}

})