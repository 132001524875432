import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo-grad-text.png'


const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "header-text" }
const _hoisted_4 = { class: "header-sub-text" }

import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { useOnboardingStore } from '@/store'

import LoginForm from '@/components/form/LoginForm.vue'
import RegisterForm from '@/components/form/RegisterForm.vue'
import { storeToRefs } from 'pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const router = useRouter()

const onboardingStore = useOnboardingStore()
const { mode } = storeToRefs(onboardingStore)
const { setMode } = onboardingStore

const headingText = computed(() => {
  return mode.value === 'login' ? 'Login to Keypin' : 'Join Keypin'
})

const subHeadingText = computed(() => {
  return mode.value === 'login' ? 'Please enter your details' : 'Sign up for free!'
})

function onNavigateLanding () {
  router.push('/')
}

function onNavigateLogin () {
  setMode('login')
}

function onNavigateRegister () {
  setMode('register')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "header-image",
        src: _imports_0,
        onClick: onNavigateLanding
      }),
      _createElementVNode("p", _hoisted_3, _toDisplayString(headingText.value), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(subHeadingText.value), 1),
      (_unref(mode) === 'login')
        ? (_openBlock(), _createBlock(LoginForm, {
            key: 0,
            onOnNavigateRegister: onNavigateRegister
          }))
        : _createCommentVNode("", true),
      (_unref(mode) === 'register')
        ? (_openBlock(), _createBlock(RegisterForm, {
            key: 1,
            onOnNavigateLogin: onNavigateLogin
          }))
        : _createCommentVNode("", true)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "carousel-placeholder" }, null, -1))
  ]))
}
}

})