import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sub-profile-view" }
const _hoisted_2 = {
  key: 0,
  class: "sub-profile-content"
}

import { onMounted } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useSubProfileStore } from '@/store'

import SubProfileContainerModular from '@/components/profile/SubProfileContainerModular.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SubProfileView',
  setup(__props) {

const router = useRouter()
const subProfileStore = useSubProfileStore()

const { subProfile, contentActive, qrScanId, accessType } = storeToRefs(subProfileStore)
const { setContentActive, fetchFromUrl } = subProfileStore

function beforeUnloadListener (e: Event) {
  e.preventDefault()
  return ''
}

function onSetContentActive (active: boolean) {
  setContentActive(active)
  if (active) addEventListener('beforeunload', beforeUnloadListener, { capture: true })
  else removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
}

function onSectionClick (id: number) {
  // SECTION CLICK
}

onBeforeRouteLeave((to, from, next) => {
  onSetContentActive(false)
  next(false)
})

onMounted(async () => {
  if (subProfile.value !== null) return
  const { name, suffix } = router.currentRoute.value.params as { name: string, suffix: string }
  const status = await fetchFromUrl(name, suffix)
  router.push({ params: { name: subProfile.value.name, subProfileSuffix: subProfile.value.id.slice(-2) } })
  if (subProfile.value.qrRedirect.active) window.location.href = subProfile.value.qrRedirect.url
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(subProfile) && _unref(subProfile).id.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(SubProfileContainerModular, {
            subProfile: _unref(subProfile),
            contentActive: _unref(contentActive),
            onOnSectionClick: onSectionClick
          }, null, 8, ["subProfile", "contentActive"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})