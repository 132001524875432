import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_2 = { class: "my-2 align-self-start" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex flex-column socials-style-panel-content"
}
const _hoisted_4 = { class: "d-flex socials-style-panel-row align-items-center" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "d-flex socials-style-panel-row align-items-center" }
const _hoisted_7 = {
  class: "d-flex align-items-center socials-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_8 = {
  class: "d-flex",
  style: {"padding":"0 0 0 2px"}
}
const _hoisted_9 = { class: "d-flex socials-style-panel-row align-items-center" }
const _hoisted_10 = {
  class: "d-flex align-items-center socials-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_11 = {
  class: "d-flex align-items-center",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_12 = { class: "d-flex socials-style-panel-row align-items-center" }
const _hoisted_13 = {
  class: "d-flex align-items-center socials-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_14 = {
  class: "d-flex align-items-center",
  style: {"padding":"0 0 0 10px"}
}
const _hoisted_15 = { class: "d-flex socials-style-panel-row align-items-center" }
const _hoisted_16 = { class: "d-flex" }
const _hoisted_17 = { class: "d-flex socials-style-panel-row align-items-center" }
const _hoisted_18 = { class: "d-flex" }

import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, SocialsSectionContent, SocialsSectionStyle, QrSectionItemType } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorAddLink from '@/components/button/editor-panel/EditorAddLink.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'
import EditorNumInputComponent from '@/components/input/EditorNumInputComponent.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import StyleIconButton from '@/components/button/style-panel/StyleIconButton.vue'
import StyleTextButton from '@/components/button/style-panel/StyleTextButton.vue'

import { SOCIAL_TYPE_TO_ICON, SOCIALS_LOGO_THEME_TO_OPTIONS, SOCIALS_LOGO_THEME_FROM_OPTIONS } from '@/constants'

import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'


export default /*@__PURE__*/_defineComponent({
  __name: 'SocialsPanel',
  props: {
  socialsSection: {
    type: Object as PropType<QrSection<SocialsSectionContent, SocialsSectionStyle>>,
    required: true
  }
},
  emits: ['onToggleMode', 'onAddSocials'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { patchQrSection, setSelectedQrSectionID, patchQrSectionItem, putQrSectionItemOrder, deleteQrSectionItem } = editorStore

const emit = __emit



const { id, label, active, persistent, section, style } = __props.socialsSection

const vHandle = HandleDirective

const styleMode = ref(false)
const drag = ref(false)
const logoTheme = ref(SOCIALS_LOGO_THEME_TO_OPTIONS[style.logoTheme].value)
const logoThemeOptions = [
  'Brand Colors',
  'Custom'
]

const cssItemIcon = computed(() => {
  return {
    width: '0.64rem',
    height: '0.64rem'
  }
})

const cssItemIconContainer = computed(() => {
  return {
    backgroundColor: 'var(--color-white)',
    borderRadius: '0.26rem',
    border: '1px solid rgba(33, 37, 41, 0.15)',
    width: '1.28rem',
    height: '1.28rem',
    alignItems: 'center',
    padding: '0.32rem',
    display: 'flex',
    marginRight: '0.32rem'
  }
})

const styleList = computed(() => {
  return style.layout === 'list'
})

function getIcon (type: string): string {
  if (__props.socialsSection) return SOCIAL_TYPE_TO_ICON[type].value
  return SOCIAL_TYPE_TO_ICON.default.value
}

function getLabel (type: string): string {
  const parts = type.split('-')
  return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
}

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onAddSocials () {
  setSelectedQrSectionID(id)
  emit('onAddSocials')
}

function onEditItemValue (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'value', value)
}

function onChangeItemOrder (items: QrSectionItemType[]) {
  drag.value = false
  putQrSectionItemOrder(id, items)
}

function onDeleteItem (itemID: number) {
  deleteQrSectionItem(id, itemID)
}

function onEditLayout (value: string) {
  if (value === style.layout) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'layout', value)
}

function onEditStyle (value: string) {
  if (value === style.style) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'style', value)
}

function onEditCorners (corners: string) {
  if (corners === style.corners) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'corners', corners)
}

function onEditSize (size: string) {
  if (size === style.size) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', size)
}

function onEditAlign (align: string) {
  if (align === style.align) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', align)
}

function onEditLogoTheme (value: string) {
  value = SOCIALS_LOGO_THEME_FROM_OPTIONS[value].value
  if (value === style.logoTheme) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'logoTheme', value)
}

function onEditLogoColor (value: string) {
  if (value === style.logoColor) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'logoColor', value)
}

function onEditBackgroundColor (value: string) {
  if (value === style.backgroundColor) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'backgroundColor', value)
}

function onEditSpacingLeft (value: number) {
  if (value === style.spacingLeft) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingLeft', value)
}

function onEditSpacingTop (value: number) {
  if (value === style.spacingTop) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingTop', value)
}

function onEditSpacingRight (value: number) {
  if (value === style.spacingRight) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingRight', value)
}

function onEditSpacingBottom (value: number) {
  if (value === style.spacingBottom) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingBottom', value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: __props.socialsSection.label,
    active: __props.socialsSection.active,
    persistent: __props.socialsSection.persistent,
    styleMode: styleMode.value,
    onOnToggleMode: onToggleMode
  }, {
    default: _withCtx(() => [
      (!styleMode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_unref(SlickList), {
              axis: "y",
              list: _unref(section).items,
              "onUpdate:list": [
                _cache[0] || (_cache[0] = ($event: any) => ((_unref(section).items) = $event)),
                onChangeItemOrder
              ],
              useDragHandle: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).items, (item, i) => {
                  return (_openBlock(), _createBlock(_unref(SlickItem), {
                    key: i,
                    index: i
                  }, {
                    default: _withCtx(() => [
                      _createVNode(EditorInputComponent, {
                        deleteable: "",
                        sortable: "",
                        label: getLabel(item.content.type),
                        "icon-src": require(`@/assets/${getIcon(item.content.type)}`),
                        "icon-style": cssItemIcon.value,
                        "icon-container-style": cssItemIconContainer.value,
                        value: item.content.value,
                        onOnChangeValue: (value) => onEditItemValue(item.id, value),
                        onOnDelete: () => onDeleteItem(item.id)
                      }, null, 8, ["label", "icon-src", "icon-style", "icon-container-style", "value", "onOnChangeValue", "onOnDelete"])
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["list"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(EditorAddLink, { onClick: onAddSocials }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode("Add item")
                ])),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[19] || (_cache[19] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Layout", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(StyleTextIconButton, {
                  icon: 'style-layout-list',
                  selected: styleList.value,
                  onClick: _cache[1] || (_cache[1] = () => onEditLayout('list'))
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("List")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextIconButton, {
                  icon: 'style-layout-row',
                  selected: !styleList.value,
                  onClick: _cache[2] || (_cache[2] = () => onEditLayout('row'))
                }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("Row")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[25] || (_cache[25] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Style", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.style == 'solid',
                  onClick: _cache[3] || (_cache[3] = () => onEditStyle('solid')),
                  style: {"width":"94px","border-radius":"0"}
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode(" Solid ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.style == 'outline',
                  onClick: _cache[4] || (_cache[4] = () => onEditStyle('outline')),
                  style: {"width":"94px","border-radius":"0","background-color":"#FFFFFF","box-shadow":"0px 0px 0px 2px rgba(33, 37, 41, 0.20) inset"}
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode(" Outline ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.style == 'pill',
                  onClick: _cache[5] || (_cache[5] = () => onEditStyle('pill')),
                  style: {"width":"94px","border-radius":"18px"}
                }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode(" Pill ")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.corners == 'sharp',
                  onClick: _cache[6] || (_cache[6] = () => onEditCorners('sharp')),
                  style: {"width":"108px","border-radius":"0"}
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode(" Sharp Corners ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.corners == 'rounded',
                  onClick: _cache[7] || (_cache[7] = () => onEditCorners('rounded')),
                  style: {"width":"135px"}
                }, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode(" Rounded Corners ")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[29] || (_cache[29] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Size & Align", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.size == 'sm',
                  onClick: _cache[8] || (_cache[8] = () => onEditSize('sm')),
                  style: {"width":"30px","height":"30px"}
                }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode(" S ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.size == 'md',
                  onClick: _cache[9] || (_cache[9] = () => onEditSize('md')),
                  style: {"width":"36px","height":"36px"}
                }, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createTextVNode(" M ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: __props.socialsSection.style.size == 'lg',
                  onClick: _cache[10] || (_cache[10] = () => onEditSize('lg')),
                  style: {"width":"40px"}
                }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createTextVNode(" L ")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(StyleIconButton, {
                  icon: 'style-align-left',
                  selected: __props.socialsSection.style.align == 'left',
                  onClick: _cache[11] || (_cache[11] = () => onEditAlign('left'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-center',
                  selected: __props.socialsSection.style.align == 'center',
                  onClick: _cache[12] || (_cache[12] = () => onEditAlign('center'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-right',
                  selected: __props.socialsSection.style.align == 'right',
                  onClick: _cache[13] || (_cache[13] = () => onEditAlign('right'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-fill',
                  selected: __props.socialsSection.style.align == 'fill',
                  onClick: _cache[14] || (_cache[14] = () => onEditAlign('fill'))
                }, null, 8, ["selected"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[30] || (_cache[30] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Logo Theme", -1)),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(SelectInput, {
                  options: logoThemeOptions,
                  modelValue: logoTheme.value,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((logoTheme).value = $event)),
                  "onUpdate:value": onEditLogoTheme
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(ColorInput, {
                  label: 'Custom Color',
                  value: __props.socialsSection.style.logoColor,
                  onOnChangeColor: onEditLogoColor,
                  disabled: __props.socialsSection.style.logoTheme === 'brand'
                }, null, 8, ["value", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[31] || (_cache[31] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Block Colors", -1)),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(ColorInput, {
                  label: 'Background',
                  value: __props.socialsSection.style.backgroundColor,
                  onOnChangeColor: onEditBackgroundColor
                }, null, 8, ["value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[32] || (_cache[32] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Block Spacing", -1)),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(EditorNumInputComponent, {
                  label: "Left",
                  value: __props.socialsSection.style.spacingLeft,
                  onOnChange: onEditSpacingLeft
                }, null, 8, ["value"]),
                _createVNode(EditorNumInputComponent, {
                  label: "Top",
                  value: __props.socialsSection.style.spacingTop,
                  onOnChange: onEditSpacingTop,
                  style: {"margin-left":"10px"}
                }, null, 8, ["value"]),
                _createVNode(EditorNumInputComponent, {
                  label: "Right",
                  value: __props.socialsSection.style.spacingRight,
                  onOnChange: onEditSpacingRight,
                  style: {"margin-left":"10px"}
                }, null, 8, ["value"]),
                _createVNode(EditorNumInputComponent, {
                  label: "Bottom",
                  value: __props.socialsSection.style.spacingBottom,
                  onOnChange: onEditSpacingBottom,
                  style: {"margin-left":"10px"}
                }, null, 8, ["value"])
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})