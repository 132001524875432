import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'

import TextInput from './TextInput.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchInput',
  props: {
  label: {
    type: String,
    default: ''
  },
  name: String,
  placeholder: String,
  modelValue: {
    type: String,
    required: true
  },
  containerStyle: {
    type: Object,
    default: null
  },
  wrapperStyle: {
    type: Object,
    default: null
  },
  textStyle: {
    type: Object,
    default: null
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const value = computed({
  get () {
    return __props.modelValue
  },
  set (value: string) {
    emit('update:modelValue', value)
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TextInput, {
    label: __props.label,
    name: __props.name,
    placeholder: __props.placeholder,
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (value.value = $event)),
    rightImageUri: "icons/svg/search.svg",
    containerStyle: __props.containerStyle,
    wrapperStyle: __props.wrapperStyle,
    textStyle: __props.textStyle
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: require(`@/assets/icons/svg/search.svg`)
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["label", "name", "placeholder", "modelValue", "containerStyle", "wrapperStyle", "textStyle"]))
}
}

})