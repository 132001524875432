<script setup lang="ts">
import { computed, defineProps, PropType, onRenderTracked, onRenderTriggered } from 'vue'

import { QrSection, HeaderSectionContent } from '@/api/types'

/* onRenderTracked((event) => {
  debugger
})

onRenderTriggered((event) => {
  debugger
}) */

const { headerSection } = defineProps({
  headerSection: {
    type: Object as PropType<QrSection<HeaderSectionContent, null>>,
    required: true
  }
})

const { section } = headerSection

const cssVars = computed(() => {
  return {
    '--pre-title-color': '#00FF00',
    '--title-color': '#000000',
    '--sub-title-color': '#787878'
  }
})
</script>

<template >
    <div v-if="headerSection" class="header-container d-flex flex-column align px-2 py-3" :style="cssVars">
      <p class="header-pretitle-text" >{{ section.preTitle }}</p>
      <p class="header-title-text" >{{ section.title }}</p>
      <p class="header-subtitle-text" >{{ section.subTitle }}</p>
    </div>
</template>

<style scoped lang="scss">
    div.header-container {
        height: 150px;
        background-color: transparent;
    }

    p {
      height: 6em;
      margin: 0.1em 0 0.1em 0;
    }
    p.header-pretitle-text {
      font-size: small;
      color: var(--pre-title-color);
      text-align: center;
    }

    p.header-title-text {
      font-size: large;
      font-weight: bold;
      color: var(--title-color);
      text-align: center;
    }

    p.header-subtitle-text {
      font-size: small;
      color: var(--sub-title-color);
      text-align: center;
    }
</style>
