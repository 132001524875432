<script setup lang="ts">
import { computed } from 'vue'
import DropdownButton from './DropdownButton.vue'

const emit = defineEmits(['onClickDuplicate', 'onClickDelete'])

const { id } = defineProps({
  id: {
    type: String,
    required: true
  }
})

const items = [
  {
    icon: '',
    text: 'Duplicate',
    subItems: []
  },
  {
    icon: '',
    text: 'Delete',
    subItems: []
  }
]

const cssButton = computed(() => {
  return {
    backgroundColor: 'var(--color-white)',
    width: '1.02rem',
    height: '1.02rem',
    padding: '0.18rem',
    marginRight: '0.32rem'
  }
})

const cssLink = computed(() => {
  return {
    padding: '0.38rem 0.44rem'
  }
})

const cssLinkText = computed(() => {
  return {
    color: 'var(--color-nearly-black)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-14)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px'
  }
})

function onClick (id: number) {
  switch (id) {
    case 0:
      emit('onClickDuplicate')
      break
    case 1:
      emit('onClickDelete')
      break
  }
}
</script>

<template>
    <DropdownButton
     :id="id"
      btn-icon="dots-vertical"
      :btn-style="cssButton"
      :link-style="cssLink"
      :link-text-style="cssLinkText"
      :items="items"
      @onClick="onClick"/>
</template>

<style scoped>
</style>
