<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import FaIconButton from '../button/FaIconButton.vue'
import SideBarLink from './SideBarLink.vue'

import Palette from '@/theme/palette'

const route = useRoute()

const visible = computed(() => {
  return route.name === 'manage' || route.name === 'manageqr' || route.name === 'analytics'
})

function getBackgroundOverride (name: string) {
  if (name === route.name) return { backgroundColor: Palette.hover_blue, color: Palette.black }
  else return { backgroundColor: Palette.white, color: Palette.border_grey }
}

function onToggle () {
  const sidebar = document.querySelector('.sidebar')
  sidebar?.classList.toggle('active')
}
</script>

<template>
    <!-- ========== Menu ========== -->
    <div v-if="visible" class="toggle-button-container">
        <FaIconButton @click="onToggle" icon="bars"/>
    </div>
    <div v-if="visible" class="sidebar">
        <div class="close-button-container">
            <FaIconButton @click="onToggle" icon="xmark"/>
        </div>
        <!-- Brand Logo -->
        <div class="logo-container">
            <!-- Brand Logo Dark -->
            <a href="#">
                <img :src="require('@/assets/logo-grad.png')" alt="dark logo" class="logo">
                <h4 class="logo-text">Keypin</h4>
             </a>
        </div>

        <!-- menu-left -->
        <div class="sidebar-menu">
            <!--- Menu -->
           <router-link to="/dashboard">
                <SideBarLink
                    name="manage"
                    src="qrs.svg"
                    src-selected="qrs-blue.svg">
                    QR Codes
                </SideBarLink>
            </router-link>
            <router-link to="#">
                <SideBarLink
                    name="analytics"
                    disabled
                    src="analytics.svg"
                    src-selected="analytics-blue.svg">
                    Analytics
                </SideBarLink>
            </router-link>
            <router-link to="#">
                <SideBarLink
                    name="users"
                    disabled
                    src="users.svg"
                    src-selected="users-blue.svg">
                    Users
                </SideBarLink>
            </router-link>
            <router-link to="#">
                <SideBarLink
                    name="settings"
                    disabled
                    src="settings.svg"
                    src-selected="settings-blue.svg">
                    Settings
                </SideBarLink>
            </router-link>
            <router-link to="#">
                <SideBarLink
                    name="resources"
                    disabled
                    src="resources.svg"
                    src-selected="resources-blue.svg">
                    Resources
                </SideBarLink>
            </router-link>
        </div>
            <!--- End Menu -->
            <div class="clearfix"></div>
    </div>
    <!-- ========== Left menu End ========== -->
</template>

<style scoped>

div.sidebar {
  top: 0;
  position: fixed;
  height: 100vh;
  width: var(--sidebar-width);
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.toggle-button-container {
  top: 10;
  display: none;
  width: 50px;
  margin: 1em;
  z-index: 3;
  position: fixed;
}

div.close-button-container {
    display: none;
    width: 50px;
    align-self: flex-end;
    margin: 1em;
}

@media screen and (min-width: 769px) {
    div.sidebar {
        width: 15vw;
        min-width: 220px;
    }
}

@media screen and (max-width: 768px) {
    div.sidebar {
        min-width: 0;
        width: 0
    }
    div.sidebar.active {
        width: 100vw;
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
    }
    div.toggle-button-container {
        display: block;
    }

    div.close-button-container {
        display: block;
    }
}
div.logo-container {
    width: 33%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

img.logo {
    width: 60px;
    height: 60px;
}

h4.logo-text {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: 18px;
    font-weight: 800;
    line-height: normal;
    margin-top: 8px;
    background: var(--bp---Gradient, linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

div.sidebar-menu {
    margin-top: 2.5rem;
    width: 100%;
    overflow:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

a {
    text-decoration: none;
}

</style>
