import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "d-flex flex-column align-items-end" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

import { computed, PropType } from 'vue'

import { QrSection, AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle } from '@/api/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'AmazonReviewCTASection',
  props: {
  amazonReviewCTASection: {
    type: Object as PropType<QrSection<AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle>>,
    required: true
  }
},
  emits: ['onPushClick'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const { section, style } = __props.amazonReviewCTASection

const cssVars = computed(() => {
  return {
    '--background-color': style.theme === 'dark' ? '#202F3F' : '#FFFFFF',
    '--title-color': style.theme === 'dark' ? '#FFFFFF' : '#212529',
    '--subtitle-color': style.theme === 'dark' ? '#FFFFFF' : '#212529'
  }
})

const logoSrc = computed(() => {
  return style.theme === 'dark'
    ? require('@/assets/images/qr-sections/amazon-logo-dark.png')
    : require('@/assets/images/qr-sections/amazon-logo.png')
})


return (_ctx: any,_cache: any) => {
  return (__props.amazonReviewCTASection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "amazon-review-cta-body",
        style: _normalizeStyle(cssVars.value)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", {
            style: _normalizeStyle(cssVars.value),
            class: "amazon-review-cta-title"
          }, _toDisplayString(_unref(section).title), 5),
          _createElementVNode("p", {
            style: _normalizeStyle(cssVars.value),
            class: "amazon-review-cta-subtitle"
          }, _toDisplayString(_unref(section).subtitle), 5),
          _createElementVNode("a", {
            href: _unref(section).amazonUrl,
            class: "amazon-review-cta-btn",
            onClick: _cache[0] || (_cache[0] = () => emit('onPushClick'))
          }, _toDisplayString(_unref(section).btnText), 9, _hoisted_2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: logoSrc.value,
            class: "logo-image mb-auto"
          }, null, 8, _hoisted_4),
          _createElementVNode("img", {
            src: require('@/assets/images/qr-sections/amazon-stars.svg'),
            class: "stars-image"
          }, null, 8, _hoisted_5)
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})