<script setup lang="ts">

const { text, linkText } = defineProps({
  text: {
    type: String,
    required: true
  },
  linkText: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['onClick'])

function onClick () {
  emit('onClick')
}

</script>

<template>
    <div>
      <p>
        {{ text }}
      </p>
      <a href='#' @click="onClick">
        {{ linkText }}
      </a>
    </div>
</template>

<style scoped lang="scss">
    div {
      display: flex;
      justify-content: center
    }
    p {
        color: rgba(33, 37, 41, 0.5);
        text-align: center;
        font-family: Inter;
        font-size: var(--font-size-14);
        font-weight: 400;
        margin: 0 0.12rem 0 0;
    }
    a {
        color: var(--color-keypin-blue);
        font-family: Inter;
        font-size: var(--font-size-14);
        font-weight: 400;
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
    }
</style>
