<template>
    <div :style="cssVars">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
        <path d="M1 1L6 6L1 11" :stroke="color" stroke-width="2"/>
      </svg>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconChevronNewsSectionSvg',
  props: {
    color: {
      type: String,
      required: true
    },
    style: {
      type: Object,
      default: null
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    }
  }
})
</script>
