import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, PropType } from 'vue'

import { QrSection, ButtonSectionContent, ButtonSectionStyle } from '@/api/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonSection',
  props: {
  buttonSection: {
    type: Object as PropType<QrSection<ButtonSectionContent, ButtonSectionStyle>>,
    required: true
  }
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const { id, label, active, persistent, section, style } = __props.buttonSection

const btnStyleWidth = computed(() => {
  if (style.align === 'fill') {
    return '100%'
  } else if (style.size === 'sm') {
    return '100px'
  } else if (style.size === 'md') {
    return '200px'
  } else {
    return '300px'
  }
})

const btnStyleHeight = computed(() => {
  if (style.size === 'sm') {
    return '30px'
  } else if (style.size === 'md') {
    return '40px'
  } else {
    return '50px'
  }
})

const btnStyleBorderRadius = computed(() => {
  if (style.style === 'pill') {
    return '18px'
  } else if (style.corners === 'rounded') {
    return '12px'
  } else {
    return '0px'
  }
})

const btnStyleContainerMargin = computed(() => {
  if (style.align === 'left') {
    return '0 auto 0 0'
  } else if (style.align === 'right') {
    return '0 0 0 auto'
  } else {
    return '0 0 0 0'
  }
})

const btnStyleBackground = computed(() => {
  if (style.style === 'outline') {
    return 'transparent'
  } else {
    return style.fillColor
  }
})

const btnTextFontSize = computed(() => {
  if (style.size === 'sm') {
    return 'var(--font-size-11)'
  } else if (style.size === 'md') {
    return 'var(--font-size-14)'
  } else {
    return 'var(--font-size-16)'
  }
})

const cssVarsContent = computed(() => {
  return {
    '--height': btnStyleHeight.value,
    '--background': btnStyleBackground.value,
    '--border-radius': btnStyleBorderRadius.value,
    '--border': '2px solid ' + style.fillColor
  }
})

const cssVarsContainer = computed(() => {
  return {
    '--width': btnStyleWidth.value,
    '--container-margin': btnStyleContainerMargin.value
  }
})

const cssVarsText = computed(() => {
  return {
    '--color': style.textColor,
    '--font-size': btnTextFontSize.value
  }
})

function onClick () {
  window.open(section.url, '_blank')
  emit('onClick')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: "button-container",
    style: _normalizeStyle(cssVarsContainer.value)
  }, [
    (__props.buttonSection)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "button-body",
          style: _normalizeStyle(cssVarsContent.value),
          onClick: onClick
        }, [
          _createElementVNode("p", {
            class: "button-text",
            style: _normalizeStyle(cssVarsText.value)
          }, _toDisplayString(_unref(section).text), 5)
        ], 4))
      : _createCommentVNode("", true)
  ], 4))
}
}

})