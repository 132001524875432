<script setup lang="ts">
import { computed } from 'vue'
import Palette from '@/theme/palette'

const { tabs, currentTab } = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  currentTab: {
    type: Number,
    default: 0
  }
})

const cssVars = computed(() => {
  return {
    '--border-color': Palette.light_grey
  }
})

function getItemStyleOverride (tab: number) {
  if (tab === currentTab) return { '--fontWeight': '600', '--background': 'linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%)', '--opacity': 1.0 }
  else return { '--fontWeight': '400', '--background': '#212529', '--opacity': 0.5 }
}

</script>

<template>
  <nav class="navbar navbar-submenu d-flex justify-content-start" :style="cssVars">
    <div class="nav-item-wrapper" v-for="(tab, idx) in tabs" :key=idx>
      <a  class="nav-item" href="#" :style="getItemStyleOverride(idx)" @click="() => $emit('selectTab', idx)">{{ tab }}</a>
      <div v-if="idx == currentTab" class="nav-item-underline"/>
    </div>
  </nav>
</template>

<style scoped lang="scss">

  nav.navbar-submenu {
    border-bottom: 1px solid rgba(33, 37, 41, .15);
    padding: 0px;
    position: relative;
  }

  div.nav-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  a.nav-item {
    font-family: Inter;
    font-size: var(--font-size-14);
    line-height: 14px; /* 100% */
    font-weight: var(--font-weight);
    text-decoration: none;
    opacity: var(--opacity);
    background: var(--background);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 30px 18px 0;
  }

  div.nav-item-underline-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  div.nav-item-underline {
    width: calc(100% - 30px);
    height: 2px;
    background: linear-gradient(106deg, #3F80FF 0%, #7B3DFF 44.51%);
    position: absolute;
    bottom: -1px;
    left: 0;
  }

</style>
