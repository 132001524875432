import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/svg/popup-close.svg'


const _hoisted_1 = {
  key: 0,
  class: "overlay-container d-flex flex-row justify-content-center"
}
const _hoisted_2 = { class: "overlay-content" }
const _hoisted_3 = { class: "overlay-header" }
const _hoisted_4 = { class: "title-text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'PopupOverlay',
  props: {
  title: {
    type: String,
    required: true
  },
  visible: {
    type: Boolean,
    default: false
  }
},
  emits: ['onDismiss'],
  setup(__props, { emit: __emit }) {




const emit = __emit

function onDismiss () {
  emit('onDismiss')
}


return (_ctx: any,_cache: any) => {
  return (__props.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(__props.title), 1),
            _createElementVNode("img", {
              src: _imports_0,
              class: "close-button",
              onClick: onDismiss
            })
          ]),
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})