import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { api } from '@/api'
import { useEditorStore } from './editorStore'

export const useOnboardingStore = defineStore('onboarding', () => {
  const editorStore = useEditorStore()

  const mode = ref('login')

  const email = ref('')
  const password = ref('')
  const confirmPassword = ref('')
  const rootName = ref('test')
  const lastName = ref('')
  const regCode = ref('')

  const isAuthenticated = computed(() => !!api.token)
  const emailValidationError = computed(() => !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.value))
  const passwordValidationError = computed(() => password.value.length < 8)
  const confirmPasswordValidationError = computed(() => password.value !== confirmPassword.value)
  const rootNameValidationError = computed(() => rootName.value.length < 1)
  const regCodeValidationError = computed(() => !/^[0-9]{6}$/.test(regCode.value))

  function setMode (value : string) {
    mode.value = value
  }

  function setEmail (value : string) {
    email.value = value
  }

  function setPassword (value : string) {
    password.value = value
  }

  function setConfirmPassword (value : string) {
    confirmPassword.value = value
  }

  function setRootName (value : string) {
    rootName.value = value
  }

  function setRegCode (value : string) {
    regCode.value = value
  }

  async function register () : Promise<string> {
    const response = await api.postRegistration(email.value, password.value, regCode.value)
    if (response.kind !== 'ok') {
      console.log(`Error logging in: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    return response.kind
  }

  async function registerRoot () : Promise<string> {
    const response = await api.postRootRegistration(rootName.value)
    if (response.kind !== 'ok') {
      console.log(`Error logging in: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const newRootProfileID = response.rootProfileID
    editorStore.setSubProfileID(newRootProfileID)
    return response.kind
  }

  return {
    mode,
    setMode,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    rootName,
    setRootName,
    regCode,
    setRegCode,
    isAuthenticated,
    emailValidationError,
    passwordValidationError,
    confirmPasswordValidationError,
    rootNameValidationError,
    regCodeValidationError,
    register,
    registerRoot
  }
})
