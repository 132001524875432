import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "toggle-button-container"
}
const _hoisted_2 = {
  key: 1,
  class: "sidebar"
}
const _hoisted_3 = { class: "close-button-container" }
const _hoisted_4 = { class: "logo-container" }
const _hoisted_5 = { href: "#" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "sidebar-menu" }

import { computed } from 'vue'
import { useRoute } from 'vue-router'

import FaIconButton from '../button/FaIconButton.vue'
import SideBarLink from './SideBarLink.vue'

import Palette from '@/theme/palette'


export default /*@__PURE__*/_defineComponent({
  __name: 'SideBar',
  setup(__props) {

const route = useRoute()

const visible = computed(() => {
  return route.name === 'manage' || route.name === 'manageqr' || route.name === 'analytics'
})

function getBackgroundOverride (name: string) {
  if (name === route.name) return { backgroundColor: Palette.hover_blue, color: Palette.black }
  else return { backgroundColor: Palette.white, color: Palette.border_grey }
}

function onToggle () {
  const sidebar = document.querySelector('.sidebar')
  sidebar?.classList.toggle('active')
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (visible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(FaIconButton, {
            onClick: onToggle,
            icon: "bars"
          })
        ]))
      : _createCommentVNode("", true),
    (visible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(FaIconButton, {
              onClick: onToggle,
              icon: "xmark"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", _hoisted_5, [
              _createElementVNode("img", {
                src: require('@/assets/logo-grad.png'),
                alt: "dark logo",
                class: "logo"
              }, null, 8, _hoisted_6),
              _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "logo-text" }, "Keypin", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_link, { to: "/dashboard" }, {
              default: _withCtx(() => [
                _createVNode(SideBarLink, {
                  name: "manage",
                  src: "qrs.svg",
                  "src-selected": "qrs-blue.svg"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" QR Codes ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "#" }, {
              default: _withCtx(() => [
                _createVNode(SideBarLink, {
                  name: "analytics",
                  disabled: "",
                  src: "analytics.svg",
                  "src-selected": "analytics-blue.svg"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Analytics ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "#" }, {
              default: _withCtx(() => [
                _createVNode(SideBarLink, {
                  name: "users",
                  disabled: "",
                  src: "users.svg",
                  "src-selected": "users-blue.svg"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Users ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "#" }, {
              default: _withCtx(() => [
                _createVNode(SideBarLink, {
                  name: "settings",
                  disabled: "",
                  src: "settings.svg",
                  "src-selected": "settings-blue.svg"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Settings ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_router_link, { to: "#" }, {
              default: _withCtx(() => [
                _createVNode(SideBarLink, {
                  name: "resources",
                  disabled: "",
                  src: "resources.svg",
                  "src-selected": "resources-blue.svg"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Resources ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "clearfix" }, null, -1))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})