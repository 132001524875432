import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-details-container" }
const _hoisted_2 = { class: "add-container" }
const _hoisted_3 = { class: "form-container" }

import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { storeToRefs } from 'pinia'

import { useEditorStore } from '@/store'

import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginButtonSkip from '@/components/button/LoginButtonSkip.vue'
import LoginInput from '@/components/input/LoginInput.vue'

import { QrSection, HeaderSectionContent, TextSectionContent, QrSectionTargetEnum } from '@/api'


export default /*@__PURE__*/_defineComponent({
  __name: 'AddDetailsForm',
  setup(__props) {

const router = useRouter()

const editorStore = useEditorStore()

const { subProfile } = storeToRefs(editorStore)
const { patchQrSection } = editorStore

const headerSectionID = computed(() => subProfile.value.sections[1].id)
const textSectionID = computed(() => subProfile.value.sections[2].id)
const headerValue = computed(() => (subProfile.value.sections[1] as unknown as QrSection<HeaderSectionContent, null>).section.title)
const textValue = computed(() => (subProfile.value.sections[2] as unknown as QrSection<TextSectionContent, null>).section.content)

const cssInputContainer = {
  width: '100%'
}

const cssInputWrapper = {
  fontSize: 'var(--font-size-14)'
}

function onEditTitle (value: string) {
  patchQrSection(headerSectionID.value, QrSectionTargetEnum.CONTENT, 'title', value)
}

function onEditText (value: string) {
  patchQrSection(textSectionID.value, QrSectionTargetEnum.CONTENT, 'content', value)
}

function onContinue () {
  // set mode?
  router.push('/dashboard')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(LoginInput, {
        "label-visible": "",
        name: "title",
        label: "Root Page title",
        type: "text",
        "model-value": headerValue.value,
        "onUpdate:modelValue": onEditTitle,
        "container-style": cssInputContainer,
        "wrapper-style": cssInputWrapper
      }, null, 8, ["model-value"]),
      _createVNode(LoginInput, {
        "label-visible": "",
        multiline: "",
        name: "text",
        label: "Page Description / Bio",
        type: "text",
        "model-value": textValue.value,
        "onUpdate:modelValue": onEditText,
        "container-style": cssInputContainer,
        "wrapper-style": cssInputWrapper
      }, null, 8, ["model-value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(LoginButtonGrad, {
        text: "Continue",
        onClick: onContinue
      }),
      _createVNode(LoginButtonSkip, {
        text: "Skip",
        onClick: onContinue
      })
    ])
  ]))
}
}

})