<script setup lang="ts">
import { ref, defineProps, PropType } from 'vue'

import { QrSection, EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle } from '@/api/types'

import EmbeddedVideoPlayer from '@/components/video/EmbeddedVideoPlayer.vue'

const emit = defineEmits(['onPushClick'])

const { embeddedVideoSection } = defineProps({
  embeddedVideoSection: {
    type: Object as PropType<QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>>,
    required: true
  }
})

const activeContentID = ref('')

</script>

<template>
    <div v-if="embeddedVideoSection" class="embedded-video-container" @click="() => emit('onPushClick')">
        <EmbeddedVideoPlayer
            :embeddedVideoSection="embeddedVideoSection"
            :playerWidth="310"
            :playerHeight="174"/>
    </div>
</template>

<style scoped lang="scss">
div.embedded-video-container {
    margin: 15px 0 0 0;
    min-width: 100%;
    display: flex;
    justify-content: center;
}
</style>
