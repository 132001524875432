<script setup lang="ts">

const { title, visible } = defineProps({
  title: {
    type: String,
    required: true
  },
  visible: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onDismiss'])

function onDismiss () {
  emit('onDismiss')
}

</script>

<template>
    <div class="overlay-container d-flex flex-row justify-content-center" v-if="visible">
        <div class="overlay-content">
            <div class="overlay-header">
                <p class="title-text"> {{ title }}</p>
                <img src="@/assets/icons/svg/popup-close.svg" class="close-button" @click="onDismiss"/>
            </div>
            <slot/>
        </div>
    </div>
</template>

<style scoped>

  div.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vw;
    overflow: hidden;
    background-color: rgba(0,0,0,.5);
    display: flex;
    flex-direction: column;
  }

  div.overlay-content {
    margin-top: 5vh;
    width: 45vw;
    height: 90vh;
    border-radius: 20px;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
  }

  div.overlay-header {
    display: flex;
    justify-content: space-between;
    width: '100%';
    padding: 0.64rem;
    border-bottom: 1px solid rgba(33, 37, 41, 0.15);
  }

  img.close-button {
    cursor: pointer;
    width: 0.64rem;
    height: 0.64rem;
  }

  p.title-text {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-16);
    font-weight: 600;
    margin: 0
  }
</style>
