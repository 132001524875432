import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "socials-content" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "social-list-button-text" }

import { computed, PropType } from 'vue'

import { QrSection, QrSectionItem, SocialsSectionContent, SocialsSectionStyle, SocialsSectionItemContent } from '@/api/types'

import IconFacebookSvg from '@/components/svg/socials/IconFacebookSvg.vue'
import IconInstagramSvg from '@/components/svg/socials/IconInstagramSvg.vue'
import IconTwitterSvg from '@/components/svg/socials/IconTwitterSvg.vue'

import { SOCIAL_TYPE_TO_ICON } from '@/constants/constants'
import { isHexColorAboveLumaThreshold } from '@/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'SocialsSection',
  props: {
  socialsSection: {
    type: Object as PropType<QrSection<SocialsSectionContent, SocialsSectionStyle>>,
    required: true
  }
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const { section, style } = __props.socialsSection

const cssVars = computed(() => {
  return {
    '--background-color': style.backgroundColor,
    '--justify-content': style.align === 'left' ? 'flex-start' : style.align === 'center' ? 'center' : 'flex-end',
    '--align-items': style.align === 'left' ? 'flex-start' : style.align === 'center' ? 'center' : 'flex-end',
    '--content-margin': style.spacingTop.toString() + 'px ' + style.spacingRight.toString() + 'px ' + style.spacingBottom.toString() + 'px ' + style.spacingLeft.toString() + 'px'
  }
})

const cssVarsTitle = computed(() => {
  return {
    '--title-margin': style.align === 'left' ? '0 0 0 5px' : style.align === 'center' ? '0 0 0 0' : '0 5px 0 0',
    '--title-color': isHexColorAboveLumaThreshold(style.backgroundColor) ? '#000' : '#FFF'
  }
})

const listBtnStyleWidth = () => {
  if (style.size === 'sm') {
    return '80%'
  } else if (style.size === 'md') {
    return '90%'
  } else {
    return '100%'
  }
}

const listStyleHeight = () => {
  if (style.size === 'sm') {
    return '30px'
  } else if (style.size === 'md') {
    return '50px'
  } else {
    return '60px'
  }
}

const rowBtnStyleWidthHeight = () => {
  if (style.size === 'sm') {
    return '40px'
  } else if (style.size === 'md') {
    return '50px'
  } else {
    return '60px'
  }
}

const btnStyleBorderRadius = () => {
  if (style.style === 'pill') {
    return '15px'
  } else if (style.corners === 'rounded') {
    return '10px'
  } else {
    return '0px'
  }
}

const cssVarsRowBtn = computed(() => {
  return {
    '--width': rowBtnStyleWidthHeight(),
    '--height': rowBtnStyleWidthHeight(),
    '--border-radius': btnStyleBorderRadius()
  }
})

const cssVarsListBtn = computed(() => {
  return {
    '--width': listBtnStyleWidth(),
    '--height': listStyleHeight(),
    '--border-radius': btnStyleBorderRadius()
  }
})

function getIcon (type: string): string {
  if (__props.socialsSection) return SOCIAL_TYPE_TO_ICON[type].value
  return SOCIAL_TYPE_TO_ICON.default.value
}

function getLabel (type: string): string {
  const parts = type.split('-')
  return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
}

function onClick (id: number, url: string) {
  window.open(url, '_blank')
  emit('onClick', id)
}

return (_ctx: any,_cache: any) => {
  return (__props.socialsSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "socials-container",
        style: _normalizeStyle(cssVars.value)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", {
            class: "socials-title",
            style: _normalizeStyle(cssVarsTitle.value)
          }, "Social Links", 4),
          (_unref(style).layout === 'row')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "socials-buttons-row-container",
                style: _normalizeStyle(cssVars.value)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).items, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "social-row-button",
                    style: _normalizeStyle(cssVarsRowBtn.value),
                    onClick: () => onClick(item.id, item.content.value)
                  }, [
                    (_unref(style).logoTheme === 'brand')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "social-row-button-icon",
                          src: require(`@/assets/${getIcon(item.content.type)}`)
                        }, null, 8, _hoisted_3))
                      : (item.content.type === 'facebook')
                        ? (_openBlock(), _createBlock(IconFacebookSvg, {
                            key: 1,
                            color: _unref(style).logoColor
                          }, null, 8, ["color"]))
                        : (item.content.type === 'instagram')
                          ? (_openBlock(), _createBlock(IconInstagramSvg, {
                              key: 2,
                              color: _unref(style).logoColor
                            }, null, 8, ["color"]))
                          : (item.content.type === 'twitter')
                            ? (_openBlock(), _createBlock(IconTwitterSvg, {
                                key: 3,
                                color: _unref(style).logoColor
                              }, null, 8, ["color"]))
                            : (_openBlock(), _createElementBlock("img", {
                                key: 4,
                                class: "social-row-button-icon",
                                src: require(`@/assets/${getIcon(item.content.type)}`)
                              }, null, 8, _hoisted_4))
                  ], 12, _hoisted_2))
                }), 128))
              ], 4))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "socials-buttons-list-container",
                style: _normalizeStyle(cssVars.value)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).items, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "social-list-button",
                    style: _normalizeStyle(cssVarsListBtn.value),
                    onClick: () => onClick(item.id, item.content.value)
                  }, [
                    (_unref(style).logoTheme === 'brand')
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "social-list-button-icon",
                          src: require(`@/assets/${getIcon(item.content.type)}`)
                        }, null, 8, _hoisted_6))
                      : (item.content.type === 'facebook')
                        ? (_openBlock(), _createBlock(IconFacebookSvg, {
                            key: 1,
                            color: _unref(style).logoColor,
                            style: {"margin-right":"15px"}
                          }, null, 8, ["color"]))
                        : (item.content.type === 'instagram')
                          ? (_openBlock(), _createBlock(IconInstagramSvg, {
                              key: 2,
                              color: _unref(style).logoColor,
                              style: {"margin-right":"15px"}
                            }, null, 8, ["color"]))
                          : (item.content.type === 'twitter')
                            ? (_openBlock(), _createBlock(IconTwitterSvg, {
                                key: 3,
                                color: _unref(style).logoColor,
                                style: {"margin-right":"15px"}
                              }, null, 8, ["color"]))
                            : (_openBlock(), _createElementBlock("img", {
                                key: 4,
                                class: "social-list-button-icon",
                                src: require(`@/assets/${getIcon(item.content.type)}`)
                              }, null, 8, _hoisted_7)),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(getLabel(item.content.type)), 1)
                  ], 12, _hoisted_5))
                }), 128))
              ], 4))
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})