import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_2 = { class: "my-2 align-self-start" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex flex-column news-style-panel-content"
}
const _hoisted_4 = { class: "d-flex news-style-panel-row align-items-center" }
const _hoisted_5 = {
  class: "d-flex align-items-center news-style-panel-row-group-br",
  style: {"padding":"0 10px 0 0"}
}
const _hoisted_6 = {
  class: "d-flex",
  style: {"padding":"0 0 0 10px"}
}
const _hoisted_7 = { class: "d-flex news-style-panel-row align-items-center" }
const _hoisted_8 = {
  class: "d-flex align-items-center news-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_9 = {
  class: "d-flex align-items-center",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_10 = { class: "d-flex news-style-panel-row align-items-center" }
const _hoisted_11 = { class: "d-flex" }

import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, NewsSectionContent, NewsSectionStyle, QrSectionItemType } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorInputComponent from '../input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconLink from '../button/FaIconLink.vue'
import ImageInput from '../input/ImageInput.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'
import StyleTextButton from '../button/style-panel/StyleTextButton.vue'
import SwitchComponent from '../switch/Switch.vue'
import EditorAddLink from '@/components/button/editor-panel/EditorAddLink.vue'

import { SlickItem, SlickList } from 'vue-slicksort'
import NewsInputComponent from '../input/NewsInputComponent.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsPanel',
  props: {
  newsSection: {
    type: Object as PropType<QrSection<NewsSectionContent, NewsSectionStyle>>,
    required: true
  }
},
  emits: ['onToggleMode'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { patchQrSection, postQrSectionItem, patchQrSectionItem, putQrSectionItemOrder, deleteQrSectionItem, postQrSectionItemImage } = editorStore

const emit = __emit



const styleMode = ref(false)
const drag = ref(false)

const { id, label, labelVisible, active, persistent, section, style } = __props.newsSection

const isLabelVisible = ref(labelVisible)

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onCreateItem () {
  postQrSectionItem(id)
}

function onEditLabel (value: string) {
  patchQrSection(id, QrSectionTargetEnum.HEADER, 'label', value)
}

function onSetLabelVisible (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.HEADER, 'labelVisible', value)
}

function onEditNewsItemImage (itemID: number, file: File) {
  postQrSectionItemImage(id, itemID, 'imageUri', file)
}

function onEditNewsItemTitle (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'title', value)
}

function onEditNewsItemSubTitle (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'subTitle', value)
}

function onEditNewsItemUrl (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'url', value)
}

function onChangeItemOrder (items: QrSectionItemType[]) {
  drag.value = false
  putQrSectionItemOrder(id, items)
}

function onDeleteNewsItem (itemID: number) {
  deleteQrSectionItem(id, itemID)
}

function onEditTextColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', value)
}

function onEditBackgroundColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'backgroundColor', value)
}

function onEditSize (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', value)
}

function onEditAlign (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', value)
}

function onEditImageStyle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'imageStyle', value)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    onOnToggleMode: onToggleMode
  }, {
    default: _withCtx(() => [
      (!styleMode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(EditorInputComponent, {
              label: "Title",
              toggleable: "",
              value: __props.newsSection.label,
              active: __props.newsSection.labelVisible,
              onOnChangeValue: onEditLabel,
              onOnSetActive: onSetLabelVisible
            }, null, 8, ["value", "active"]),
            _createVNode(_unref(SlickList), {
              axis: "y",
              list: _unref(section).items,
              "onUpdate:list": [
                _cache[0] || (_cache[0] = ($event: any) => ((_unref(section).items) = $event)),
                onChangeItemOrder
              ],
              useDragHandle: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).items, (item, i) => {
                  return (_openBlock(), _createBlock(_unref(SlickItem), {
                    key: item,
                    index: i
                  }, {
                    default: _withCtx(() => [
                      _createVNode(NewsInputComponent, {
                        title: item.content.title,
                        subTitle: item.content.subtitle,
                        url: item.content.url,
                        imageUri: item.content.imageUri,
                        "onUpdate:title": (value) => onEditNewsItemTitle(item.id, value),
                        "onUpdate:subtitle": (value) => onEditNewsItemSubTitle(item.id, value),
                        "onUpdate:url": (value) => onEditNewsItemUrl(item.id, value),
                        "onUpdate:image": (file) => onEditNewsItemImage(item.id, file),
                        onDelete: () => onDeleteNewsItem(item.id)
                      }, null, 8, ["title", "subTitle", "url", "imageUri", "onUpdate:title", "onUpdate:subtitle", "onUpdate:url", "onUpdate:image", "onDelete"])
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["list"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(EditorAddLink, { onClick: onCreateItem }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Add item")
                ])),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[11] || (_cache[11] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Color", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(ColorInput, {
                  label: 'Text',
                  value: __props.newsSection.style.textColor,
                  onOnChangeColor: onEditTextColor
                }, null, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(ColorInput, {
                  label: 'Fill',
                  value: __props.newsSection.style.backgroundColor,
                  onOnChangeColor: onEditBackgroundColor
                }, null, 8, ["value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[15] || (_cache[15] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Size & Align", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(StyleTextButton, {
                  selected: __props.newsSection.style.size == 'sm',
                  onClick: _cache[1] || (_cache[1] = () => onEditSize('sm')),
                  style: {"width":"30px","height":"30px"}
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" S ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: __props.newsSection.style.size == 'md',
                  onClick: _cache[2] || (_cache[2] = () => onEditSize('md')),
                  style: {"width":"36px","height":"36px"}
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" M ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: __props.newsSection.style.size == 'lg',
                  onClick: _cache[3] || (_cache[3] = () => onEditSize('lg')),
                  style: {"width":"40px"}
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode(" L ")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(StyleIconButton, {
                  icon: 'style-align-left',
                  selected: __props.newsSection.style.align == 'left',
                  onClick: _cache[4] || (_cache[4] = () => onEditAlign('left'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-center',
                  selected: __props.newsSection.style.align == 'center',
                  onClick: _cache[5] || (_cache[5] = () => onEditAlign('center'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-right',
                  selected: __props.newsSection.style.align == 'right',
                  onClick: _cache[6] || (_cache[6] = () => onEditAlign('right'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-fill',
                  selected: __props.newsSection.style.align == 'fill',
                  onClick: _cache[7] || (_cache[7] = () => onEditAlign('fill'))
                }, null, 8, ["selected"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[18] || (_cache[18] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Image Style", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(StyleTextButton, {
                  icon: 'style-layout-list',
                  selected: __props.newsSection.style.imageStyle === 'square',
                  onClick: _cache[8] || (_cache[8] = () => onEditImageStyle('square'))
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("Square")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  icon: 'style-layout-grid',
                  selected: __props.newsSection.style.imageStyle === 'auto',
                  onClick: _cache[9] || (_cache[9] = () => onEditImageStyle('auto'))
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Auto Height")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ]),
              _cache[19] || (_cache[19] = _createTextVNode("` "))
            ])
          ]))
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})