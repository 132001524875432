<script setup lang="ts">
import { computed } from 'vue'

const { options, label, modelValue, layout, containerStyle, style } = defineProps({
  options: Object,
  label: String,
  modelValue: Boolean,
  layout: {
    type: String,
    default: 'column'
  },
  containerStyle: {
    type: Object,
    default: null
  },
  style: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get () {
    return modelValue
  },
  set (value: string) {
    emit('update:modelValue', value)
  }
})

const cssVarsContainer = computed(() => {
  return {
    '--container-flex-direction': layout,
    ...containerStyle
  }
})

const cssVarsComponentContainer = computed(() => {
  return {
    '--component-container-width': layout === 'row' ? '70%' : '100%',
    ...style
  }
})

const cssVarsButtonGrid = computed(() => {
  return {
    '--button-background-color': value.value ? 'white' : '#F1F1F1',
    ...style
  }
})

const cssVarsButtonList = computed(() => {
  return {
    '--button-background-color': !value.value ? 'white' : '#F1F1F1',
    ...style
  }
})

</script>

<template>
    <div class="select-view-container" :style="cssVarsContainer">
      <label class="select-view-label" for="select-view">View as</label>
      <div id="select-view" class="select-view-wrapper" :style="cssVarsComponentContainer">
        <div class="select-view-button" :style="cssVarsButtonGrid" @click="() => value = true">
            <img class="select-view-button-icon" :src="require('@/assets/icons/svg/grid.svg')"/>
        </div>
        <div class="select-view-button" :style="cssVarsButtonList" @click="() => value = false">
          <img class="select-view-button-icon" :src="require('@/assets/icons/svg/list.svg')"/>
        </div>
      </div>
    </div>
  </template>

<style scoped>

  div.select-view-container {
    display: flex;
    flex-direction: var(--container-flex-direction);
    align-items: center;
  }

  label.select-view-label {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
    opacity: 0.5;
    margin-right: 10px;
  }

  div.select-view-wrapper {
    display: flex;
    flex-direction: row;
  }
  div.select-view-button {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid rgba(33, 37, 41, 0.10);
    background: var(--button-background-color);
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img.select-view-button-icon {
    width: 20px;
    height: 20px;
  }

</style>
