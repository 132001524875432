<script setup lang="ts">
import { computed } from 'vue'

const { icon, iconStyle, style, selected } = defineProps({
  icon: {
    type: String,
    required: true
  },
  iconStyle: {
    type: Object,
    default: null
  },
  style: {
    type: Object,
    default: null
  },
  selected: {
    type: Boolean,
    default: false
  }
})

const cssVars = computed(() => {
  return {
    ...style,
    background: selected
      ? 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
      : 'var(--color-light-grey)'
  }
})

const iconSrc = computed(() => {
  if (selected) return require('@/assets/icons/svg/' + icon + '-grad.svg')
  else return require('@/assets/icons/svg/' + icon + '.svg')
})

const cssImgVars = computed(() => {
  return {
    ...iconStyle
  }
})

</script>

<template>
    <div class="d-flex justify-content-center align-items-center button-content" :style="cssVars">
        <img :src="iconSrc" :style="cssImgVars" alt="icon" class="icon">
    </div>
</template>

<style scoped lang="scss">
  div.button-content {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin: 0 0 0 8px;
  }
  img.icon {
    width: 20px;
    height: 20px;
  }
</style>
