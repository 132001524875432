import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "select-socials-container" }
const _hoisted_2 = { class: "select-container" }
const _hoisted_3 = { class: "form-container" }

import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import { useEditorStore, useOnboardingStore } from '@/store'

import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginButtonSkip from '@/components/button/LoginButtonSkip.vue'
import SelectSocialButton from '@/components/button/SelectSocialButton.vue'

import { SOCIAL_LINKS } from '@/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectSocialsForm',
  setup(__props) {

const editorStore = useEditorStore()
const onboardingStore = useOnboardingStore()

const { selectedSocials } = storeToRefs(editorStore)
const { selectSocial, clearSocials, fetchUserSubProfile } = editorStore

const { setMode } = onboardingStore

const socialTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'social')
})

const streamingTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'streaming')
})

function onSelect (type: string) {
  selectSocial(type)
}

function onContinue () {
  console.log(selectedSocials)
  setMode('add-socials')
}

async function onSkip () {
  clearSocials()
  const status = await fetchUserSubProfile()
  setMode('add-details')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(socialTypes.value, (item) => {
        return (_openBlock(), _createBlock(SelectSocialButton, {
          key: item.type,
          type: item.type,
          label: item.label,
          isSelected: _unref(selectedSocials).map(i => i.type).includes(item.type),
          onOnSelect: onSelect
        }, null, 8, ["type", "label", "isSelected"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(streamingTypes.value, (item) => {
        return (_openBlock(), _createBlock(SelectSocialButton, {
          key: item.type,
          type: item.type,
          label: item.label,
          isSelected: _unref(selectedSocials).map(i => i.type).includes(item.type),
          onOnSelect: onSelect
        }, null, 8, ["type", "label", "isSelected"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(LoginButtonGrad, {
        text: "Continue",
        onClick: onContinue
      }),
      _createVNode(LoginButtonSkip, {
        text: "Skip",
        onClick: onSkip
      })
    ])
  ]))
}
}

})