import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }

import { ref, useTemplateRef, PropType } from 'vue'
import { QrSection, QrSectionTargetEnum, TextSectionContent } from '@/api/types'
import { useEditorStore } from '@/store'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextPanel',
  props: {
  textSection: {
    type: Object as PropType<QrSection<TextSectionContent, null>>,
    required: true
  }
},
  setup(__props) {

const editorStore = useEditorStore()
const { patchQrSection } = editorStore



const styleMode = ref(false)

const textInput = useTemplateRef('content')

const { id, label, active, persistent, section } = __props.textSection

function focusInput () {
  textInput.value?.focus()
}

function onEditContent (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'content', value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    hasStyle: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(DebouncedTextInput, {
          ref_key: "textInput",
          ref: textInput,
          value: _unref(section).content,
          multiline: true,
          type: "text",
          placeholder: "title",
          "onUpdate:value": onEditContent
        }, null, 8, ["value"])
      ])
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})