<script setup lang="ts">
import { computed } from 'vue'
import DropdownButton from './DropdownButton.vue'

const emit = defineEmits(['onClick'])

const { id, btnIcon, items, btnStyle } = defineProps({
  id: {
    type: String,
    required: true
  },
  btnIcon: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  btnStyle: {
    type: Object,
    required: true
  }
})

const cssButton = computed(() => {
  return {
    ...btnStyle,
    backgroundColor: 'var(--color-light-grey)',
    width: '1.28rem',
    height: '1.28rem',
    borderRadius: '0.26rem',
    padding: '0.32rem'
  }
})

const cssLink = computed(() => {
  return {
    padding: '0.38rem 0.44rem'
  }
})

const cssLinkText = computed(() => {
  return {
    color: 'var(--color-nearly-black)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-14)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px'
  }
})

function onClick (id: number) {
  emit('onClick', id)
}
</script>

<template>
    <DropdownButton
     :id="id"
      :btn-icon="btnIcon"
      :btn-style="cssButton"
      :link-style="cssLink"
      :link-text-style="cssLinkText"
      :items="items"
      @onClick="onClick"/>
</template>

<style scoped>
</style>
