<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue'

import { QrSection, EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle } from '@/api/types'
import { youtubeUrlToEmbed } from '@/utils'

const { embeddedVideoSection, playerStyle, playerWidth, playerHeight } = defineProps({
  embeddedVideoSection: {
    type: Object as PropType<QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>>,
    required: true
  },
  playerStyle: {
    type: Object,
    default: null
  },
  playerWidth: {
    type: Number,
    required: true
  },
  playerHeight: {
    type: Number,
    required: true
  }
})

const { section, style } = embeddedVideoSection

const cssVarsPlayer = computed(() => {
  return {
    ...playerStyle
  }
})

const controlsShow = computed(() => style.controls === 'show')
const getSrc = computed(() => youtubeUrlToEmbed(section.url))

</script>

<template>
    <div class="d-flex">
        <iframe :width="playerWidth" :height="playerHeight" :style="cssVarsPlayer" :src="getSrc" class="embedded-video-player">
        </iframe>
    </div>
</template>

<style scoped>

.embedded-video-player {
    border-radius: 0.256rem;
}

</style>
