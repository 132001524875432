<script setup lang="ts">
import { onMounted } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useSubProfileStore } from '@/store'

import SubProfileContainerModular from '@/components/profile/SubProfileContainerModular.vue'

const router = useRouter()
const subProfileStore = useSubProfileStore()

const { subProfile, contentActive, qrScanId, accessType } = storeToRefs(subProfileStore)
const { setContentActive, fetchFromUrl } = subProfileStore

function beforeUnloadListener (e: Event) {
  e.preventDefault()
  return ''
}

function onSetContentActive (active: boolean) {
  setContentActive(active)
  if (active) addEventListener('beforeunload', beforeUnloadListener, { capture: true })
  else removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
}

function onSectionClick (id: number) {
  // SECTION CLICK
}

onBeforeRouteLeave((to, from, next) => {
  onSetContentActive(false)
  next(false)
})

onMounted(async () => {
  if (subProfile.value !== null) return
  const { name, suffix } = router.currentRoute.value.params as { name: string, suffix: string }
  const status = await fetchFromUrl(name, suffix)
  router.push({ params: { name: subProfile.value.name, subProfileSuffix: subProfile.value.id.slice(-2) } })
  if (subProfile.value.qrRedirect.active) window.location.href = subProfile.value.qrRedirect.url
})

</script>

<template>
  <div class="sub-profile-view">
    <div v-if="subProfile && subProfile.id.length > 0" class="sub-profile-content">
      <SubProfileContainerModular
        :subProfile="subProfile"
        :contentActive="contentActive"
        @onSectionClick="onSectionClick"
        />
    </div>
  </div>
</template>

<style scoped lang="scss">
  div.sub-profile-view{
    width: 100%;
    min-height: 500px;
  }
  div.sub-profile-content{
    width: 25%;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 15px;
    min-height: 500px;
  }
  html, body {
    padding:0;
    margin:0;
    border: none
  }

  @media only screen and (min-width: 1200px){
    div.sub-profile-content{
      width: 40%;
    }
  }
  @media only screen and (min-width: 1001px){
    div.sub-profile-content{
      width: 45%;
    }
  }
  @media only screen and (max-width: 1000px){
    div.sub-profile-content{
      width: 50%;
    }
  }
  @media only screen and (max-width: 850px){
    div.sub-profile-content{
      width: 65%;
    }
  }
  @media only screen and (max-width: 750px){
    div.sub-profile-content{
      width: 75%;
    }
  }
  @media only screen and (max-width: 650px){
    div.sub-profile-content{
      width: 100%;
    }
  }
</style>
