import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderSlot as _renderSlot } from "vue"
import _imports_0 from '@/assets/icons/svg/editor-xmark.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 4,
  class: "handle grip-handle"
}
const _hoisted_3 = {
  class: "grip-icon",
  href: "#"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DebouncedTextInput = _resolveComponent("DebouncedTextInput")!
  const _component_SwitchComponent = _resolveComponent("SwitchComponent")!
  const _directive_handle = _resolveDirective("handle")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editor-input-container",
    style: _normalizeStyle(_ctx.cssVarsContainer)
  }, [
    (this.iconSrc)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(_ctx.iconContainerStyle)
        }, [
          _createElementVNode("img", {
            class: "editor-input-icon",
            src: this.iconSrc,
            style: _normalizeStyle(_ctx.iconStyle)
          }, null, 12, _hoisted_1)
        ], 4))
      : _createCommentVNode("", true),
    (this.label)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "editor-input-label",
          style: _normalizeStyle(_ctx.cssLabel)
        }, _toDisplayString(this.label), 5))
      : _createCommentVNode("", true),
    _createVNode(_component_DebouncedTextInput, {
      value: this.internalValue,
      type: "text",
      multiline: this.multiline,
      "input-style": _ctx.cssInput,
      "onUpdate:value": _ctx.onChangeValue,
      "container-style": {marginRight: '0.32rem'}
    }, null, 8, ["value", "multiline", "input-style", "onUpdate:value"]),
    (this.deleteable)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          class: "delete-button-image",
          src: _imports_0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDelete && _ctx.onDelete(...args)))
        }))
      : _createCommentVNode("", true),
    (this.toggleable)
      ? (_openBlock(), _createBlock(_component_SwitchComponent, {
          key: 3,
          initialValue: _ctx.isActive,
          "v-model": _ctx.isActive,
          "onUpdate:modelValue": _ctx.onSetActive
        }, null, 8, ["initialValue", "v-model", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    (this.sortable)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("a", _hoisted_3, [
            _createElementVNode("img", {
              class: "grip-icon-image",
              src: require('@/assets/icons/icon-grip-1.png')
            }, null, 8, _hoisted_4)
          ])
        ])), [
          [_directive_handle]
        ])
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}