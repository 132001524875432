<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue'

import { QrSection, LinkSectionContent, LinkSectionStyle } from '@/api/types'

const { linkSection } = defineProps({
  linkSection: {
    type: Object as PropType<QrSection<LinkSectionContent, LinkSectionStyle>>,
    required: true
  }
})

const emit = defineEmits(['onClick'])

const { id, label, active, persistent, section, style } = linkSection

const cssVarsText = computed(() => {
  return {
    '--text-align': style.align,
    '--font-size': style.size + 'px',
    '--line-height': Math.round(style.size * 1.57143) + 'px',
    '--font-weight': style.bold ? 'bold' : 'normal',
    '--font-style': style.italic ? 'italic' : 'normal',
    '--text-decoration': style.underline ? 'underline' : 'none',
    '--color': style.textColor,
    '--background-color': style.backgroundColor,
    '--margin': `${style.spacingTop}px ${style.spacingRight}px ${style.spacingBottom}px ${style.spacingLeft}px`
  }
})

function onClick () {
  window.open(section.url, '_blank')
  emit('onClick')
}

</script>

<template>
    <div v-if="linkSection" class="link-body">
        <p
            class="link-text"
            :style="cssVarsText"
            @click="onClick"
            >
            {{ section.text }}
        </p>

    </div>
</template>

<style scoped lang="scss">
  div.link-body{
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
  p.link-text{
    width: 100%;
    text-align: var(--text-align);
    font-family: Inter;
    font-size: var(--font-size);
    font-style: var(--font-style);
    font-weight: var(--font-weight);
    line-height: var(--line-height); /* 157.143% */
    text-decoration: var(--text-decoration);
    color: var(--color);
    background-color: var(--background-color);
    margin: var(--margin);
    cursor: pointer;
  }
</style>
