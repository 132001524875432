import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-clipboard-container" }
const _hoisted_2 = { class: "text-clipboard-text-wrapper" }
const _hoisted_3 = { class: "text-clipboard-text" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "tooltip-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipComponent = _resolveComponent("TooltipComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(this.text), 1)
    ]),
    _createElementVNode("div", {
      class: "text-clipboard-button-wrapper",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCopy && _ctx.onCopy(...args))),
      onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeImage && _ctx.changeImage(...args))),
      onMouseout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resetImage && _ctx.resetImage(...args)))
    }, [
      _createElementVNode("img", {
        src: this.srcTextClipboardButton,
        class: "text-clipboard-button-image"
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_TooltipComponent, null, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Copy to clipboard")
          ])),
          _: 1
        })
      ])
    ], 32)
  ]))
}