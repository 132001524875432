<script setup lang="ts">
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useEditorStore, useOnboardingStore } from '@/store'
import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginInput from '@/components/input/LoginInput.vue'

const router = useRouter()

const onboardingStore = useOnboardingStore()
const editorStore = useEditorStore()

const { rootName } = storeToRefs(onboardingStore)
const { setMode, setRootName, registerRoot } = onboardingStore

const { subProfileID } = storeToRefs(editorStore)

const cssInputContainer = {
  width: '100%',
  margin: '0.48rem 0'
}

async function onRegisterRoot () {
  const status = await registerRoot()
  setMode('select-socials')
  router.push('/welcome')
}

</script>

<template>
  <div class="login-form-container">
    <LoginInput
       name="rootName"
       label="Root URL"
       type="rootName"
       placeholder="RootName"
       :model-value="rootName"
       @update:model-value="setRootName"
       @keydown.enter="onRegisterRoot"
       :container-style="cssInputContainer"
        />
    <LoginButtonGrad text="Continue" @click="onRegisterRoot"/>
   </div>
 </template>

<style scoped lang="scss">
  div.login-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
  }
</style>
