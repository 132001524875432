<script setup lang="ts">
import { computed, getCurrentInstance, useTemplateRef } from 'vue'
import { useEditorStore } from '@/store'

const editorStore = useEditorStore()
const { getImageUrl } = editorStore

const emit = defineEmits(['onSelect'])

const { uri, style } = defineProps({
  uri: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    required: false
  }
})
const imageInputSelect = useTemplateRef('imageSelect')
const imageUrl = computed(() => getImageUrl(uri))
const imageExists = computed(() => uri !== 'default')

const cssVars = computed(() => {
  return {
    ...style
  }
})

function onSelectImageStart () {
  if (imageInputSelect.value) {
    imageInputSelect.value.click()
  }
}
function onSelectImageEnd (event : Event) {
  if (!imageInputSelect.value) return
  const files: ReadonlyArray<File> = [...(imageInputSelect.value.files ? imageInputSelect.value.files : [])]
  if (files) {
    emit('onSelect', files[0])
    imageInputSelect.value.value = ''
  }
}
</script>

<template>
  <div class="image-input-container" :style=cssVars>
      <input id="image-select" ref="imageSelect" type="file" hidden @change="onSelectImageEnd"/>
      <img v-if="imageExists" :src="imageUrl" alt="image" class="image-input-image" @click="onSelectImageStart">
      <div v-else class="image-input-content">
        <p class="image-input-text">Image</p>
        <div class="image-input-button-content" @click="onSelectImageStart">
            <img :src="require('@/assets/icons/svg/editor-image.svg')" alt="icon" class="image-input-icon">
            <p class="image-input-button-text">Select</p>
        </div>
        <div class="image-input-button-content" @click="onSelectImageStart">
            <img :src="require('@/assets/icons/svg/editor-upload.svg')" alt="icon" class="image-input-icon">
            <p class="image-input-button-text">Upload</p>
        </div>
      </div>
  </div>
</template>

<style scoped lang="scss">

  div.image-input-container {
    display: flex;
    background: var(--color-white);
    border-radius: 0.19rem;
  }

  div.image-input-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.32rem;
    padding: 0.32rem;
  }

  .image-input-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.19rem;
    cursor: pointer;
  }

  div.image-input-button-content {
      flex: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.32rem;
      border-radius: 0.25rem;
      background: var(--color-light-grey);
  }

  img.image-input-icon {
    width: 0.64rem;
    height: 0.64rem;
  }

  p.image-input-text {
    margin: 0;
    color: var(--color-nearly-black);
    text-align: center;
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    opacity: 0.5;
  }

  p.image-input-button-text {
    margin: 0;
    color: var(--color-nearly-black);
    text-align: left;
    font-family: Inter;
    font-size: var(--font-size-13);
    font-weight: 500;
    opacity: 0.5;
  }
</style>
