<template>
    <div :style="cssVars">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.2033 1.875H17.9599L11.9374 8.75833L19.0224 18.125H13.4749L9.12992 12.4442L4.15826 18.125H1.39992L7.84159 10.7625L1.04492 1.875H6.73326L10.6608 7.0675L15.2033 1.875ZM14.2358 16.475H15.7633L5.90326 3.43833H4.26409L14.2358 16.475Z" :fill="color"/>
      </svg>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconTwitterSvg',
  props: {
    color: {
      type: String,
      required: true
    },
    style: {
      type: Object,
      default: null
    }
  },
  computed: {
    cssVars () {
      return {
        ...this.style
      }
    }
  }
})
</script>
