import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header-pretitle-text" }
const _hoisted_2 = { class: "header-title-text" }
const _hoisted_3 = { class: "header-subtitle-text" }

import { computed, PropType, onRenderTracked, onRenderTriggered } from 'vue'

import { QrSection, HeaderSectionContent } from '@/api/types'

/* onRenderTracked((event) => {
  debugger
})

onRenderTriggered((event) => {
  debugger
}) */


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSection',
  props: {
  headerSection: {
    type: Object as PropType<QrSection<HeaderSectionContent, null>>,
    required: true
  }
},
  setup(__props) {



const { section } = __props.headerSection

const cssVars = computed(() => {
  return {
    '--pre-title-color': '#00FF00',
    '--title-color': '#000000',
    '--sub-title-color': '#787878'
  }
})

return (_ctx: any,_cache: any) => {
  return (__props.headerSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "header-container d-flex flex-column align px-2 py-3",
        style: _normalizeStyle(cssVars.value)
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(section).preTitle), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(section).title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(section).subTitle), 1)
      ], 4))
    : _createCommentVNode("", true)
}
}

})