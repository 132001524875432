import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "image-input-content"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

import { computed, getCurrentInstance, useTemplateRef } from 'vue'
import { useEditorStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageInput',
  props: {
  uri: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    required: false
  }
},
  emits: ['onSelect'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { getImageUrl } = editorStore

const emit = __emit


const imageInputSelect = useTemplateRef('imageSelect')
const imageUrl = computed(() => getImageUrl(__props.uri))
const imageExists = computed(() => __props.uri !== 'default')

const cssVars = computed(() => {
  return {
    ...__props.style
  }
})

function onSelectImageStart () {
  if (imageInputSelect.value) {
    imageInputSelect.value.click()
  }
}
function onSelectImageEnd (event : Event) {
  if (!imageInputSelect.value) return
  const files: ReadonlyArray<File> = [...(imageInputSelect.value.files ? imageInputSelect.value.files : [])]
  if (files) {
    emit('onSelect', files[0])
    imageInputSelect.value.value = ''
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "image-input-container",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("input", {
      id: "image-select",
      ref: "imageSelect",
      type: "file",
      hidden: "",
      onChange: onSelectImageEnd
    }, null, 544),
    (imageExists.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: imageUrl.value,
          alt: "image",
          class: "image-input-image",
          onClick: onSelectImageStart
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "image-input-text" }, "Image", -1)),
          _createElementVNode("div", {
            class: "image-input-button-content",
            onClick: onSelectImageStart
          }, [
            _createElementVNode("img", {
              src: require('@/assets/icons/svg/editor-image.svg'),
              alt: "icon",
              class: "image-input-icon"
            }, null, 8, _hoisted_3),
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "image-input-button-text" }, "Select", -1))
          ]),
          _createElementVNode("div", {
            class: "image-input-button-content",
            onClick: onSelectImageStart
          }, [
            _createElementVNode("img", {
              src: require('@/assets/icons/svg/editor-upload.svg'),
              alt: "icon",
              class: "image-input-icon"
            }, null, 8, _hoisted_4),
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "image-input-button-text" }, "Upload", -1))
          ])
        ]))
  ], 4))
}
}

})