import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, PropType } from 'vue'

import { QrSection, EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle } from '@/api/types'

import EmbeddedVideoPlayer from '@/components/video/EmbeddedVideoPlayer.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'EmbeddedVideoSection',
  props: {
  embeddedVideoSection: {
    type: Object as PropType<QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>>,
    required: true
  }
},
  emits: ['onPushClick'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const activeContentID = ref('')


return (_ctx: any,_cache: any) => {
  return (__props.embeddedVideoSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "embedded-video-container",
        onClick: _cache[0] || (_cache[0] = () => emit('onPushClick'))
      }, [
        _createVNode(EmbeddedVideoPlayer, {
          embeddedVideoSection: __props.embeddedVideoSection,
          playerWidth: 310,
          playerHeight: 174
        }, null, 8, ["embeddedVideoSection"])
      ]))
    : _createCommentVNode("", true)
}
}

})