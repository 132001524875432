<script setup lang="ts">
import { computed, ref, PropType, onRenderTracked, onRenderTriggered } from 'vue'
import { QrSection, QrSectionTargetEnum, HeaderSectionContent } from '@/api/types'
import { useEditorStore } from '@/store'
import { Palette } from '@/theme'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorAddLink from '../button/editor-panel/EditorAddLink.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const { headerSection } = defineProps({
  headerSection: {
    type: Object as PropType<QrSection<HeaderSectionContent, null>>,
    required: true
  }
})

const { id, label, active, persistent, section } = headerSection

const styleMode = ref(false)
const editingPreTitle = ref(false)
const editingSubTitle = ref(false)

const cssAddIcon = computed(() => {
  return {
    backgroundImage: Palette.gradientBP,
    width: '1.95em',
    borderRadius: '0.975em',
    padding: '0.5em',
    color: 'white'
  }
})

const cssAddText = computed(() => {
  return {
    backgroundImage: Palette.gradientBP,
    backgroundClip: 'text',
    backgroundSize: '100%',
    textFillColor: 'transparent'
  }
})

const cssDeleteButton = computed(() => {
  return {
    color: Palette.neutral800,
    backgroundColor: 'transparent'
  }
})

function onSelectPreTitle () {
  editingPreTitle.value = true
}

function onSelectSubTitle () {
  editingSubTitle.value = true
}

function onEditTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'title', value)
}

function onEditPreTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'preTitle', value)
}

function onEditSubTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'subTitle', value)
}

function onResetPreTitle () {
  editingPreTitle.value = false
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'preTitle', '')
}

function onResetSubTitle () {
  editingSubTitle.value = false
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'subTitle', '')
}

</script>

<template>
    <EditorPanel :id="id" :title="label" :active="active" :persistent="persistent" :styleMode="styleMode" :hasStyle="false">
        <div class="d-flex flex-column">
          <DebouncedTextInput :value="section.title" type="text" placeholder="title" @update:value="onEditTitle"/>
          <EditorInputComponent
            v-if="editingPreTitle || section.preTitle.length > 0"
            label="Pre-title"
            bold-label
            deleteable
            :value="section.preTitle"
            @onChangeValue="onEditPreTitle"
            @onDelete="onResetPreTitle"
          />
          <EditorInputComponent
            v-if="editingSubTitle || section.subTitle.length > 0"
            label="Sub-title"
            bold-label
            deleteable
            :value="section.subTitle"
            @onChangeValue="onEditSubTitle"
            @onDelete="onResetSubTitle"
          />
          <div class="add-button-container align-self-start" v-if="!(editingPreTitle || section.preTitle.length > 0)">
            <EditorAddLink @click="onSelectPreTitle">Add Pre-title</EditorAddLink>
          </div>
          <div class="add-button-container align-self-start" v-if="!(editingSubTitle || section.subTitle.length > 0)">
            <EditorAddLink @click="onSelectSubTitle">Add Sub-title</EditorAddLink>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>

div.add-button-container {
  margin-top: 0.38rem;
}

</style>
