<script setup lang="ts">
import { ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, LinkSectionContent, LinkSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorNumInputComponent from '@/components/input/EditorNumInputComponent.vue'
import EditorNumSelectComponent from '@/components/input/EditorNumSelectComponent.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = defineEmits(['onToggleMode'])

const { linkSection } = defineProps({
  linkSection: {
    type: Object as PropType<QrSection<LinkSectionContent, LinkSectionStyle>>,
    required: true
  }
})

const styleMode = ref(false)

const { id, label, active, persistent, section, style } = linkSection

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onEditText (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'text', value)
}

function onEditUrl (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'url', value)
}

function onEditAlign (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', value)
}

function onEditBold (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'bold', value)
}

function onEditItalic (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'italic', value)
}

function onEditUnderline (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'underline', value)
}

function onEditSize (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', value)
}

function onEditTextColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', value)
}

function onEditBackgroundColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'backgroundColor', value)
}

function onEditSpacingLeft (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingLeft', value)
}

function onEditSpacingTop (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingTop', value)
}

function onEditSpacingRight (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingRight', value)
}

function onEditSpacingBottom (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingBottom', value)
}

</script>

<template>
    <EditorPanel
      :id="id"
      :title="label"
      :active="active"
      :persistent="persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!styleMode" class="d-flex flex-column">
        <EditorInputComponent label="Link Text" :value="section.text" @onChangeValue="onEditText"/>
        <EditorInputComponent label="URL" :value="section.url" @onChangeValue="onEditUrl"/>
        </div>
      <div v-else class="d-flex flex-column link-style-panel-content">
        <div class="d-flex link-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Format</p>
            <div class="d-flex align-items-center link-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'style-align-left'" :selected="style.align === 'left'" @click="() => onEditAlign('left')"/>
              <StyleIconButton :icon="'style-align-center'" :selected="style.align === 'center'" @click="() => onEditAlign('center')"/>
              <StyleIconButton :icon="'style-align-right'" :selected="style.align === 'right'" @click="() => onEditAlign('right')"/>
            </div>
            <div class="d-flex align-items-center link-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'text-bold'" :selected="style.bold" @click="() => onEditBold(!style.bold)"/>
              <StyleIconButton :icon="'text-italic'" :selected="style.italic" @click="() => onEditItalic(!style.italic)"/>
              <StyleIconButton :icon="'text-underline'" :selected="style.underline" @click="() => onEditUnderline(!style.underline)"/>
            </div>
            <div class="d-flex" style="padding: 0 0 0 10px">
                <EditorNumSelectComponent label="Size" :value="style.size" :min="8" :max="24" @onChange="onEditSize"/>
              </div>
          </div>
          <div class="d-flex link-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Color</p>
            <div class="d-flex align-items-center link-style-panel-row-group-br" style="padding: 0 10px 0 0">
              <ColorInput :label="'Text'" :value="style.textColor" @onChangeColor="onEditTextColor"/>
            </div>
            <div class="d-flex" style="padding: 0 0 0 10px">
              <ColorInput :label="'Background'" :value="style.backgroundColor" @onChangeColor="onEditBackgroundColor"/>
            </div>
          </div>
          <div class="d-flex link-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Spacing</p>
            <div class="d-flex ">
              <EditorNumInputComponent label="Left" :value="style.spacingLeft" @onChange="onEditSpacingLeft"/>
              <EditorNumInputComponent label="Top" :value="style.spacingTop" @onChange="onEditSpacingTop" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Right" :value="style.spacingRight" @onChange="onEditSpacingRight" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Bottom" :value="style.spacingBottom" @onChange="onEditSpacingBottom" style="margin-left: 10px"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>

div.link-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.link-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.link-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
