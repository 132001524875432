import { ref } from 'vue'
import { defineStore } from 'pinia'

import { api } from '@/api'
import { SubProfile } from '@/api/types'
import { getSortedSubProfile } from '@/utils'

export const useSubProfileStore = defineStore('subProfile', () => {
  const subProfile = ref<SubProfile | null>(null)
  const qrScanId = ref(0)
  const accessType = ref('')
  const contentActive = ref(false)

  function setContentActive (active: boolean) {
    contentActive.value = active
  }

  function getImageUrl (uri: string) : string {
    return api.getImageUrl(uri)
  }

  async function fetchFromQr (id: string) : Promise<string> {
    const response = await api.fetchSubProfileFromQr(id)
    if (response.kind !== 'ok') {
      console.log(`Error fetching sub profile from qr: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    subProfile.value = getSortedSubProfile(response.subProfile)
    qrScanId.value = response.qrScanId
    accessType.value = response.accessType

    return response.kind
  }

  async function fetchFromUrl (name: string, suffix: string) : Promise<string> {
    const response = await api.fetchSubProfileFromUrl(name, suffix)
    if (response.kind !== 'ok') {
      console.log(`Error fetching sub profile from link: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    subProfile.value = getSortedSubProfile(response.subProfile)
    qrScanId.value = response.qrScanId
    accessType.value = response.accessType

    console.log(`SubProfile: ${JSON.stringify(subProfile.value)}`, [])

    return response.kind
  }

  return {
    subProfile,
    contentActive,
    qrScanId,
    accessType,
    setContentActive,
    getImageUrl,
    fetchFromQr,
    fetchFromUrl
  }
})
