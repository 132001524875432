import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DebouncedTextInput = _resolveComponent("DebouncedTextInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: "color-input-container",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("p", {
      class: "color-input-label",
      style: _normalizeStyle(_ctx.cssVarsLabel)
    }, _toDisplayString(this.label), 5),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DebouncedTextInput, {
        value: _ctx.value,
        type: "text",
        disabled: _ctx.disabled,
        "onUpdate:value": _ctx.onChangeColor,
        "container-style": _ctx.cssInputContainer,
        "wrapper-style": _ctx.cssInputWrapper,
        "input-style": _ctx.cssInput
      }, null, 8, ["value", "disabled", "onUpdate:value", "container-style", "wrapper-style", "input-style"])
    ]),
    _createElementVNode("div", {
      class: "color-indicator align-middle mx-2",
      style: _normalizeStyle(_ctx.indicatorColor)
    }, null, 4)
  ], 4))
}