import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "nav-item-underline"
}

import { computed } from 'vue'
import Palette from '@/theme/palette'


export default /*@__PURE__*/_defineComponent({
  __name: 'SubNav',
  props: {
  tabs: {
    type: Array,
    required: true
  },
  currentTab: {
    type: Number,
    default: 0
  }
},
  setup(__props) {



const cssVars = computed(() => {
  return {
    '--border-color': Palette.light_grey
  }
})

function getItemStyleOverride (tab: number) {
  if (tab === __props.currentTab) return { '--fontWeight': '600', '--background': 'linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%)', '--opacity': 1.0 }
  else return { '--fontWeight': '400', '--background': '#212529', '--opacity': 0.5 }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", {
    class: "navbar navbar-submenu d-flex justify-content-start",
    style: _normalizeStyle(cssVars.value)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tabs, (tab, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "nav-item-wrapper",
        key: idx
      }, [
        _createElementVNode("a", {
          class: "nav-item",
          href: "#",
          style: _normalizeStyle(getItemStyleOverride(idx)),
          onClick: () => _ctx.$emit('selectTab', idx)
        }, _toDisplayString(tab), 13, _hoisted_1),
        (idx == __props.currentTab)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 4))
}
}

})