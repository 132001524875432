import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "image-container d-flex justify-content-center py-5"
}
const _hoisted_2 = ["src"]

import { computed, PropType } from 'vue'

import { QrSection, ImageSectionContent } from '@/api/types'
import { useSubProfileStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageSection',
  props: {
  imageSection: {
    type: Object as PropType<QrSection<ImageSectionContent, null>>,
    required: true
  }
},
  setup(__props) {

const subProfileStore = useSubProfileStore()

const { getImageUrl } = subProfileStore



const imageUrl = computed(() => getImageUrl(__props.imageSection.section.uri))


return (_ctx: any,_cache: any) => {
  return (__props.imageSection)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "image",
          src: imageUrl.value,
          crossorigin: "anonymous"
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
}

})