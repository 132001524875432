<script setup lang="ts">
import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = defineEmits(['onToggleMode'])

const { amazonReviewCTASection } = defineProps({
  amazonReviewCTASection: {
    type: Object as PropType<QrSection<AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle>>,
    required: true
  }
})

const styleMode = ref(false)

const { id, label, active, persistent, section, style } = amazonReviewCTASection

const styleDark = computed(() => {
  return style.theme === 'dark'
})

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', amazonReviewCTASection.id, styleMode.value)
  }
}

function onEditTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'title', value)
}

function onEditSubTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'subtitle', value)
}

function onEditBtnText (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'btnText', value)
}

function onEditAmazonUrl (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'amazonUrl', value)
}

function onEditTheme (value: string) {
  if (value === style.theme) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'theme', value)
}

</script>

<template>
    <EditorPanel
      :id="id"
      :title="label"
      :active="active"
      :persistent="persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!styleMode" class="d-flex flex-column">
        <EditorInputComponent label="CTA Title" :value="section.title" @onChangeValue="onEditTitle"/>
        <EditorInputComponent label="CTA Subtitle" :value="section.subtitle" @onChangeValue="onEditSubTitle"/>
        <EditorInputComponent label="Button Text" :value="section.btnText" @onChangeValue="onEditBtnText"/>
        <EditorInputComponent label="Amazon URL" :value="section.amazonUrl" @onChangeValue="onEditAmazonUrl"/>
        </div>
      <div v-else class="d-flex flex-column amazon-review-cta-style-panel-content">
          <div class="d-flex amazon-review-cta-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Theme</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'amazon-review-cta-theme-light'" :selected="!styleDark" @click="() => onEditTheme('light')">Light</StyleTextIconButton>
              <StyleTextIconButton :icon="'amazon-review-cta-theme-dark'" :selected="styleDark" @click="() => onEditTheme('dark')">Dark</StyleTextIconButton>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>

div.amazon-review-cta-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.amazon-review-cta-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
