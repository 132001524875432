import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mask"
}
const _hoisted_2 = {
  key: 0,
  class: "dropdown-content"
}
const _hoisted_3 = { class: "qr-container" }
const _hoisted_4 = ["src"]

import { computed, ref, onMounted, onBeforeUnmount, useTemplateRef } from 'vue'

import SvgButton from '@/components/button/SvgButton.vue'
import SvgLink from '@/components/button/SvgLink.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownQrViewDownload',
  props: {
  qrUri: {
    type: String,
    required: true
  }
},
  emits: ['onDownload'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const content = useTemplateRef('content')

const visible = ref(false)
const srcBtnImg = ref('qr-grad')
const btnBackground = ref('#FFFFFF')

const cssBtn = computed(() => {
  return {
    background: btnBackground.value,
    width: '1.024rem',
    height: '1.024rem',
    padding: '0.224rem',
    borderRadius: '0.256rem'
  }
})

const cssLink = computed(() => {
  return {
    display: 'flex',
    width: '100%',
    padding: '0.32rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.256rem',
    borderRadius: '0.256rem',
    background: 'var(--color-nearly-black)'
  }
})

const cssLinkIcon = computed(() => {
  return {
    width: '0.64rem',
    height: '0.64rem'
  }
})

const cssLinkText = computed(() => {
  return {
    color: 'var(--color-white)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-13)',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px'
  }
})

function toggle () {
  visible.value = !visible.value
  btnBackground.value = visible.value ? 'linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%)' : '#FFFFFF'
  srcBtnImg.value = visible.value ? 'qr-white' : 'qr-grad'
}

function onClickPng () {
  emit('onDownload', 'png')
}

function onClickSvg () {
  emit('onDownload', 'svg')
}

function handleClickOutside (event) {
  if (
    content.value === event.target ||
    event.composedPath().includes(content.value)
  ) {
    return
  }
  visible.value = false
  btnBackground.value = '#FFFFFF'
  srcBtnImg.value = 'qr-grad'
}

onMounted(() => document.addEventListener('click', handleClickOutside))
onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (visible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref_key: "content",
      ref: content,
      class: "dropdown-qr-view-download-container"
    }, [
      _createVNode(SvgButton, {
        onClick: toggle,
        icon: srcBtnImg.value,
        style: _normalizeStyle(cssBtn.value)
      }, null, 8, ["icon", "style"]),
      (visible.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                class: "qr-code",
                src: __props.qrUri
              }, null, 8, _hoisted_4)
            ]),
            _createVNode(SvgLink, {
              onClick: onClickPng,
              icon: "download-white",
              style: _normalizeStyle({...cssLink.value, margin: '20px 0 10px 0'}),
              "icon-style": cssLinkIcon.value,
              "text-style": cssLinkText.value
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Download PNG")
              ])),
              _: 1
            }, 8, ["style", "icon-style", "text-style"]),
            _createVNode(SvgLink, {
              onClick: onClickSvg,
              icon: "download-white",
              style: _normalizeStyle(cssLink.value),
              "icon-style": cssLinkIcon.value,
              "text-style": cssLinkText.value
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Download SVG")
              ])),
              _: 1
            }, 8, ["style", "icon-style", "text-style"])
          ]))
        : _createCommentVNode("", true)
    ], 512)
  ], 64))
}
}

})