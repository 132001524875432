<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { storeToRefs } from 'pinia'

import { useEditorStore } from '@/store'

import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginButtonSkip from '@/components/button/LoginButtonSkip.vue'
import LoginInput from '@/components/input/LoginInput.vue'

import { QrSection, HeaderSectionContent, TextSectionContent, QrSectionTargetEnum } from '@/api'

const router = useRouter()

const editorStore = useEditorStore()

const { subProfile } = storeToRefs(editorStore)
const { patchQrSection } = editorStore

const headerSectionID = computed(() => subProfile.value.sections[1].id)
const textSectionID = computed(() => subProfile.value.sections[2].id)
const headerValue = computed(() => (subProfile.value.sections[1] as unknown as QrSection<HeaderSectionContent, null>).section.title)
const textValue = computed(() => (subProfile.value.sections[2] as unknown as QrSection<TextSectionContent, null>).section.content)

const cssInputContainer = {
  width: '100%'
}

const cssInputWrapper = {
  fontSize: 'var(--font-size-14)'
}

function onEditTitle (value: string) {
  patchQrSection(headerSectionID.value, QrSectionTargetEnum.CONTENT, 'title', value)
}

function onEditText (value: string) {
  patchQrSection(textSectionID.value, QrSectionTargetEnum.CONTENT, 'content', value)
}

function onContinue () {
  // set mode?
  router.push('/dashboard')
}

</script>

<template>
  <div class="add-details-container">
    <div class="add-container">
      <LoginInput
       label-visible
       name="title"
       label="Root Page title"
       type="text"
       :model-value="headerValue"
       @update:model-value="onEditTitle"
       :container-style="cssInputContainer"
       :wrapper-style="cssInputWrapper"
      />
      <LoginInput
       label-visible
       multiline
       name="text"
       label="Page Description / Bio"
       type="text"
       :model-value="textValue"
       @update:model-value="onEditText"
       :container-style="cssInputContainer"
       :wrapper-style="cssInputWrapper"
      />
    </div>
    <div class=form-container>
      <LoginButtonGrad text="Continue" @click="onContinue"/>
      <LoginButtonSkip text="Skip" @click="onContinue"/>
    </div>
  </div>
</template>

<style scoped>

div.add-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
div.add-container {
  display: flex;
  flex-direction: column;
  gap: 1.15rem;
  width: 100%;
}

div.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 0.96rem;
  margin-top: 1.32rem;
  width: 100%;
}
</style>
