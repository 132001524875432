<script setup lang="ts">
import { computed } from 'vue'

import { Palette } from '@/theme'

const { icon, style, selected } = defineProps({
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  },
  selected: {
    type: Boolean,
    default: false
  }
})

const cssVars = computed(() => {
  return {
    ...style,
    background: selected
      ? 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
      : 'var(--color-light-grey)'
  }
})

const cssVarsText = computed(() => {
  if (selected) {
    return {
      backgroundImage: Palette.gradientBP,
      backgroundClip: 'text',
      backgroundSize: '100%',
      textFillColor: 'transparent'
    }
  } else {
    return {
      color: 'var(--color-nearly-black)'
    }
  }
})

const cssTextClass = computed(() => {
  const baseClass = 'button-text'
  return selected ? baseClass + ' text-gradient' : baseClass
})

const iconSrc = computed(() => {
  if (selected) return require('@/assets/icons/svg/' + icon + '-grad.svg')
  else return require('@/assets/icons/svg/' + icon + '.svg')
})
</script>

<template>
    <div class="d-flex justify-content-center align-items-center button-content" :style="cssVars">
        <div class="d-flex flex-column justify-content-center icon-container">
          <img :src="iconSrc" alt="icon" class="icon">
        </div>
        <p :class="cssTextClass" :style="cssVarsText"><slot/></p>
    </div>
</template>

<style scoped lang="scss">
  div.button-content {
      cursor: pointer;
      width: 132px;
      height: 40px;
      border-radius: 8px;
      margin: 0 0 0 10px;
  }

  div.icon-container {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  img.icon {
    width: 100%;
  }

  p.button-text {
    margin: 0px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px; /* 100% */
  }

  p.text-gradient {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
</style>
