<script setup lang="ts">
import { computed } from 'vue'

const { icon, style, textStyle, iconStyle } = defineProps({
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  },
  textStyle: {
    type: Object,
    default: null
  },
  iconStyle: {
    type: Object,
    default: null
  }
})

const imgSrc = computed(() => require('@/assets/icons/svg/' + icon + '.svg'))

const cssVars = computed(() => {
  return {
    ...style
  }
})

const cssVarsText = computed(() => {
  return {
    ...textStyle
  }
})

const cssVarsIcon = computed(() => {
  return {
    ...iconStyle
  }
})

</script>

<template>
    <div class="svg-icon-link-wrapper" :style="cssVars">
        <img :src="imgSrc" alt="icon" :style="cssVarsIcon">
        <p class="svg-icon-link-text" :style="cssVarsText"><slot/></p>
    </div>
</template>

<style scoped lang="scss">
    div.svg-icon-link-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    p.svg-icon-link-text {
      margin: 0;
    }
</style>
