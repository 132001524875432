<script setup lang="ts">
import { computed, PropType } from 'vue'

import { QrSection, AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle } from '@/api/types'

const emit = defineEmits(['onPushClick'])

const { amazonReviewCTASection } = defineProps({
  amazonReviewCTASection: {
    type: Object as PropType<QrSection<AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle>>,
    required: true
  }
})

const { section, style } = amazonReviewCTASection

const cssVars = computed(() => {
  return {
    '--background-color': style.theme === 'dark' ? '#202F3F' : '#FFFFFF',
    '--title-color': style.theme === 'dark' ? '#FFFFFF' : '#212529',
    '--subtitle-color': style.theme === 'dark' ? '#FFFFFF' : '#212529'
  }
})

const logoSrc = computed(() => {
  return style.theme === 'dark'
    ? require('@/assets/images/qr-sections/amazon-logo-dark.png')
    : require('@/assets/images/qr-sections/amazon-logo.png')
})

</script>

<template>
    <div v-if="amazonReviewCTASection" class="amazon-review-cta-body" :style="cssVars">
        <div class="d-flex flex-column">
            <p :style="cssVars" class="amazon-review-cta-title">{{ section.title }}</p>
            <p :style="cssVars" class="amazon-review-cta-subtitle">{{ section.subtitle }}</p>
            <a :href="section.amazonUrl" class="amazon-review-cta-btn" @click="() => emit('onPushClick')">{{ section.btnText }}</a>
        </div>
        <div class="d-flex flex-column align-items-end">
          <img :src=logoSrc class="logo-image mb-auto"/>
          <img :src="require('@/assets/images/qr-sections/amazon-stars.svg')" class="stars-image"/>
        </div>
    </div>
</template>

<style scoped lang="scss">
  div.amazon-review-cta-body{
    width: 111%;
    margin-top: 20px;
    display: flex;
    background-color: var(--background-color);
    padding: 20px 30px 20px 30px;
  }
  p.amazon-review-cta-title{
    color: var(--title-color);
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    margin: 0px;
    text-align: left;
    width: 155px;
  }
  p.amazon-review-cta-subtitle{
    color: var(--subtitle-color);
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    opacity: 0.5;
    margin: 10px 0 0 0;
    text-align: left;
    width: 208px;
  }
  a.amazon-review-cta-btn{
    display: inline-flex;
    padding: 13px 24px;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    background: var(--color-amazon-orange);
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 500;
    line-height: 14px; /* 100% */
    height: fit-content;
    width: fit-content;
    margin: 20px 0 0 0;
    text-decoration: none;
    white-space: nowrap;
  }
  img.logo-image {
    width: 65.294px;
    height: 20px;
    flex-shrink: 0;
  }
  img.stars-image {
    width: 100px;
    height: 20px;
    flex-shrink: 0;
  }
</style>
