import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-center icon-container" }
const _hoisted_2 = ["src"]

import { computed } from 'vue'

import { Palette } from '@/theme'


export default /*@__PURE__*/_defineComponent({
  __name: 'StyleTextIconButton',
  props: {
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  },
  selected: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



const cssVars = computed(() => {
  return {
    ...__props.style,
    background: __props.selected
      ? 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
      : 'var(--color-light-grey)'
  }
})

const cssVarsText = computed(() => {
  if (__props.selected) {
    return {
      backgroundImage: Palette.gradientBP,
      backgroundClip: 'text',
      backgroundSize: '100%',
      textFillColor: 'transparent'
    }
  } else {
    return {
      color: 'var(--color-nearly-black)'
    }
  }
})

const cssTextClass = computed(() => {
  const baseClass = 'button-text'
  return __props.selected ? baseClass + ' text-gradient' : baseClass
})

const iconSrc = computed(() => {
  if (__props.selected) return require('@/assets/icons/svg/' + __props.icon + '-grad.svg')
  else return require('@/assets/icons/svg/' + __props.icon + '.svg')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-content-center align-items-center button-content",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: iconSrc.value,
        alt: "icon",
        class: "icon"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(cssTextClass.value),
      style: _normalizeStyle(cssVarsText.value)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6)
  ], 4))
}
}

})