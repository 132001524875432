<script setup lang="ts">
import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EmbeddedVideoPlayer from '@/components/video/EmbeddedVideoPlayer.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = defineEmits(['onToggleMode'])

const { embeddedVideoSection } = defineProps({
  embeddedVideoSection: {
    type: Object as PropType<QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>>,
    required: true
  }
})

const styleMode = ref(false)

const { id, label, active, persistent, section, style } = embeddedVideoSection

const controlsShow = computed(() => {
  return embeddedVideoSection.style.controls === 'show'
})

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', embeddedVideoSection.id, styleMode.value)
  }
}

function onEditUrl (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'url', value)
}

function onEditControls (value: string) {
  if (value === embeddedVideoSection.style.controls) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'controls', value)
}

function onEditThumbnail (value: string) {
  if (value === embeddedVideoSection.style.thumbnail) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'thumbnail', value)
}
</script>

<template>
    <EditorPanel
      :id="id"
      :title="label"
      :active="active"
      :persistent="persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!styleMode" class="d-flex flex-column">
        <EditorInputComponent
          label="URL"
          :value="section.url"
          @onChangeValue="onEditUrl"
          :multiline="true"
          :input-component-style="{height: '138px', width: '387px'}"
          style="height: display: flex; align-items: flex-start; padding: 10px 10px 10px 20px;"
          >
          <EmbeddedVideoPlayer :player-width="245" :player-height="138" :player-style="{margin: '0 0 10px 10px'}" :embeddedVideoSection="embeddedVideoSection"/>
        </EditorInputComponent>
        </div>
      <div v-else class="d-flex flex-column embedded-video-style-panel-content">
          <div class="d-flex embedded-video-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Controls</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'style-controls-show'" :selected="controlsShow" @click="() => onEditControls('show')">Show</StyleTextIconButton>
              <StyleTextIconButton :icon="'style-controls-hide'" :selected="!controlsShow" @click="() => onEditControls('hide')">Hide</StyleTextIconButton>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>

div.embedded-video-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.embedded-video-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
