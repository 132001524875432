<script setup lang="ts">
import { ref, useTemplateRef, PropType } from 'vue'
import { QrSection, QrSectionTargetEnum, TextSectionContent } from '@/api/types'
import { useEditorStore } from '@/store'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const { textSection } = defineProps({
  textSection: {
    type: Object as PropType<QrSection<TextSectionContent, null>>,
    required: true
  }
})

const styleMode = ref(false)

const textInput = useTemplateRef('content')

const { id, label, active, persistent, section } = textSection

function focusInput () {
  textInput.value?.focus()
}

function onEditContent (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'content', value)
}

</script>

<template>
    <EditorPanel :id="id" :title="label" :active="active" :persistent="persistent" :styleMode="styleMode" :hasStyle="false">
        <div class="d-flex flex-column">
          <DebouncedTextInput ref="textInput" :value="section.content" :multiline=true type="text" placeholder="title" @update:value="onEditContent"/>
        </div>
      </EditorPanel>
</template>

<style scoped>

div.pre-title-container {
  background-color: lightgray;
  border-radius: 1em;
  width: 100%;
  height: 4em;
}

p.pre-title-label {
  width: 25%;
  padding: 0em 0em 0em 2em;
  margin: 0;
  text-align: left;
}

div.pre-title-input-container {
  width: 65%;
  margin: 0em 1em 0em 1em;
}

div.pre-title-delete-button-container {
  width: 10%;
}

</style>
