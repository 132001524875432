import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-column button-style-panel-content"
}
const _hoisted_3 = { class: "d-flex button-style-panel-row align-items-center" }
const _hoisted_4 = {
  class: "d-flex align-items-center button-style-panel-row-group-br",
  style: {"padding":"0 10px 0 0"}
}
const _hoisted_5 = {
  class: "d-flex",
  style: {"padding":"0 0 0 10px"}
}
const _hoisted_6 = { class: "d-flex button-style-panel-row align-items-center" }
const _hoisted_7 = {
  class: "d-flex align-items-center button-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_8 = {
  class: "d-flex",
  style: {"padding":"0 0 0 2px"}
}
const _hoisted_9 = { class: "d-flex button-style-panel-row align-items-center" }
const _hoisted_10 = {
  class: "d-flex align-items-center button-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_11 = {
  class: "d-flex align-items-center",
  style: {"padding":"0 10px 0 2px"}
}

import { ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, ButtonSectionContent, ButtonSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleTextButton from '@/components/button/style-panel/StyleTextButton.vue'
import StyleIconButton from '@/components/button/style-panel/StyleIconButton.vue'
import { Palette } from '@/theme'


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonPanel',
  props: {
  buttonSection: {
    type: Object as PropType<QrSection<ButtonSectionContent, ButtonSectionStyle>>,
    required: true
  }
},
  emits: ['onToggleMode'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = __emit



const styleMode = ref(false)

const { id, label, active, persistent, section, style } = __props.buttonSection

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onEditText (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'text', value)
}

function onEditUrl (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'url', value)
}

function onEditTextColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', value)
}

function onEditFillColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'fillColor', value)
}

function onEditStyle (value: string) {
  if (value === 'outline' && style.textColor === Palette.white) {
    patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', style.fillColor)
  } else if (style.textColor === style.fillColor) {
    patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', Palette.white)
  }
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'style', value)
}

function onEditCorners (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'corners', value)
}

function onEditSize (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', value)
}

function onEditAlign (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    onOnToggleMode: onToggleMode
  }, {
    default: _withCtx(() => [
      (!styleMode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(EditorInputComponent, {
              label: "Button Text",
              value: _unref(section).text,
              onOnChangeValue: onEditText
            }, null, 8, ["value"]),
            _createVNode(EditorInputComponent, {
              label: "URL",
              value: _unref(section).url,
              onOnChangeValue: onEditUrl
            }, null, 8, ["value"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Color", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(ColorInput, {
                  label: 'Text',
                  value: _unref(style).textColor,
                  onOnChangeColor: onEditTextColor
                }, null, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(ColorInput, {
                  label: 'Fill',
                  value: _unref(style).fillColor,
                  onOnChangeColor: onEditFillColor
                }, null, 8, ["value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[18] || (_cache[18] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Style", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(StyleTextButton, {
                  selected: _unref(style).style == 'solid',
                  onClick: _cache[0] || (_cache[0] = () => onEditStyle('solid')),
                  style: {"width":"94px","border-radius":"0"}
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Solid ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: _unref(style).style == 'outline',
                  onClick: _cache[1] || (_cache[1] = () => onEditStyle('outline')),
                  style: {"width":"94px","border-radius":"0","background-color":"#FFFFFF","box-shadow":"0px 0px 0px 2px rgba(33, 37, 41, 0.20) inset"}
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode(" Outline ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: _unref(style).style == 'pill',
                  onClick: _cache[2] || (_cache[2] = () => onEditStyle('pill')),
                  style: {"width":"94px","border-radius":"18px"}
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Pill ")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(StyleTextButton, {
                  selected: _unref(style).corners == 'sharp',
                  onClick: _cache[3] || (_cache[3] = () => onEditCorners('sharp')),
                  style: {"width":"108px","border-radius":"0"}
                }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode(" Sharp Corners ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: _unref(style).corners == 'rounded',
                  onClick: _cache[4] || (_cache[4] = () => onEditCorners('rounded')),
                  style: {"width":"135px"}
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode(" Rounded Corners ")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[22] || (_cache[22] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Size & Align", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(StyleTextButton, {
                  selected: _unref(style).size == 'sm',
                  onClick: _cache[5] || (_cache[5] = () => onEditSize('sm')),
                  style: {"width":"30px","height":"30px"}
                }, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode(" S ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: _unref(style).size == 'md',
                  onClick: _cache[6] || (_cache[6] = () => onEditSize('md')),
                  style: {"width":"36px","height":"36px"}
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode(" M ")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextButton, {
                  selected: _unref(style).size == 'lg',
                  onClick: _cache[7] || (_cache[7] = () => onEditSize('lg')),
                  style: {"width":"40px"}
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode(" L ")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(StyleIconButton, {
                  icon: 'style-align-left',
                  selected: _unref(style).align == 'left',
                  onClick: _cache[8] || (_cache[8] = () => onEditAlign('left'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-center',
                  selected: _unref(style).align == 'center',
                  onClick: _cache[9] || (_cache[9] = () => onEditAlign('center'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-right',
                  selected: _unref(style).align == 'right',
                  onClick: _cache[10] || (_cache[10] = () => onEditAlign('right'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-fill',
                  selected: _unref(style).align == 'fill',
                  onClick: _cache[11] || (_cache[11] = () => onEditAlign('fill'))
                }, null, 8, ["selected"])
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})