<script setup lang="ts">
import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, FeaturesSectionContent, FeaturesSectionStyle, QrSectionItemType } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorAddLink from '@/components/button/editor-panel/EditorAddLink.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleTextIconButton from '../button/style-panel/StyleTextIconButton.vue'

import { SlickItem, SlickList } from 'vue-slicksort'

const editorStore = useEditorStore()
const { patchQrSection, postQrSectionItem, patchQrSectionItem, putQrSectionItemOrder, deleteQrSectionItem } = editorStore

const emit = defineEmits(['onToggleMode'])

const { featuresSection } = defineProps({
  featuresSection: {
    type: Object as PropType<QrSection<FeaturesSectionContent, FeaturesSectionStyle>>,
    required: true
  }
})

const styleMode = ref(false)
const drag = ref(false)

const { id, label, active, persistent, section, style } = featuresSection

const styleList = computed(() => {
  return style.layout === 'list'
})

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onCreateItem () {
  postQrSectionItem(id)
}

function onEditItemValue (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'value', value)
}

function onChangeItemOrder (items: QrSectionItemType[]) {
  drag.value = false
  putQrSectionItemOrder(id, items)
}

function onDeleteItem (itemID: number) {
  deleteQrSectionItem(id, itemID)
}

function onEditLayout (value: string) {
  if (value === style.layout) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'layout', value)
}

function onEditFillColor (value: string) {
  if (value === style.fillColor) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'fillColor', value)
}

</script>

<template>
    <EditorPanel
      :id="id"
      :title="label"
      :active="active"
      :persistent="persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!styleMode" class="d-flex flex-column">
      <SlickList axis="y" v-model:list="section.items" @update:list="onChangeItemOrder" :useDragHandle="true">
              <SlickItem v-for="(item, i) in section.items" :key="i" :index="i">
                <EditorInputComponent
                  deleteable
                  sortable
                  :value="item.content.value"
                  @onChangeValue="(value: string) => onEditItemValue(item.id, value)"
                  @onDelete="() => onDeleteItem(item.id)"
                />
            </SlickItem>
        </SlickList>
        <div class="my-2 align-self-start">
          <EditorAddLink @click="onCreateItem">Add item</EditorAddLink>
          </div>
        </div>
          <div v-else class="d-flex flex-column features-style-panel-content">
          <div class="d-flex features-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Layout</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'style-layout-list'" :selected="styleList" @click="() => onEditLayout('list')">List</StyleTextIconButton>
              <StyleTextIconButton :icon="'style-layout-grid'" :selected="!styleList" @click="() => onEditLayout('grid')">Grid</StyleTextIconButton>
            </div>
          </div>
          <div class="d-flex features-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Color</p>
            <div class="d-flex">
              <ColorInput :label="'Fill'" :value="style.fillColor" @onChangeColor="onEditFillColor"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>
div.features-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.features-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
