import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "select-label",
  for: "select-input"
}
const _hoisted_2 = ["selected"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectInput',
  props: {
  options: Object,
  label: String,
  modelValue: String,
  layout: {
    type: String,
    default: 'column'
  },
  containerStyle: {
    type: Object,
    default: null
  }
},
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {


const emit = __emit

const value = computed({
  get () {
    return __props.modelValue
  },
  set (value: string) {
    emit('update:value', value)
  }
})

const cssVarsContainer = computed(() => {
  return {
    '--container-flex-direction': __props.layout,
    ...__props.containerStyle
  }
})

const cssVarsComponent = computed(() => {
  return {
    '--component-width': __props.layout === 'column' ? '100%' : '70%'
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "select-container",
    style: _normalizeStyle(cssVarsContainer.value)
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(__props.label), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      class: "select-wrapper",
      selected: __props.modelValue,
      id: "select-input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
      style: _normalizeStyle(cssVarsComponent.value)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(__props.options), ([k,v]) => {
        return (_openBlock(), _createElementBlock("option", { key: k }, _toDisplayString(v), 1))
      }), 128))
    ], 12, _hoisted_2), [
      [_vModelSelect, value.value]
    ])
  ], 4))
}
}

})