import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useDropdownStore = defineStore('dropdown', () => {
  const activeDropdown = ref<string>('')

  function toggleActiveDropdown (value: string) {
    if (activeDropdown.value === value) activeDropdown.value = ''
    else activeDropdown.value = value
  }

  return {
    activeDropdown,
    toggleActiveDropdown
  }
})
