import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "submit-button" }
const _hoisted_2 = { class: "submit-button-text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginButtonSkip',
  props: {
  text: {
    type: String,
    required: true
  }
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.text), 1)
  ]))
}
}

})