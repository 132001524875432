<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { useOnboardingStore } from '@/store'

import LoginForm from '@/components/form/LoginForm.vue'
import RegisterForm from '@/components/form/RegisterForm.vue'
import { storeToRefs } from 'pinia'

const router = useRouter()

const onboardingStore = useOnboardingStore()
const { mode } = storeToRefs(onboardingStore)
const { setMode } = onboardingStore

const headingText = computed(() => {
  return mode.value === 'login' ? 'Login to Keypin' : 'Join Keypin'
})

const subHeadingText = computed(() => {
  return mode.value === 'login' ? 'Please enter your details' : 'Sign up for free!'
})

function onNavigateLanding () {
  router.push('/')
}

function onNavigateLogin () {
  setMode('login')
}

function onNavigateRegister () {
  setMode('register')
}

</script>

<template>
   <div class="login-container">
      <div class="form-container">
         <img class="header-image" src="@/assets/logo-grad-text.png" @click="onNavigateLanding">
         <p class="header-text">{{ headingText }}</p>
         <p class="header-sub-text">{{ subHeadingText }}</p>
         <LoginForm v-if="mode === 'login'" @onNavigateRegister="onNavigateRegister"/>
         <RegisterForm v-if="mode === 'register'" @onNavigateLogin="onNavigateLogin" />
      </div>
     <div class="carousel-placeholder">
     </div>
   </div>
 </template>

<style scoped lang="scss">
   div.login-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 0.64rem;
  }
  div.form-container, div.carousel-placeholder{
    flex: 1;
    display: flex;
  }
  div.carousel-placeholder{
    background-color: var(--color-light-grey);
    border-radius: 16px;
    flex: 1;
  }
  div.form-container{
    flex-direction: column;
    align-items: center;
  }
  img.header-image {
    cursor: pointer;
    margin-top: 0.32rem;
    width: 100px;
    height: 36px;
  }
  p.header-text, p.header-sub-text {
    text-align: center;
    font-family: Inter;
    color: var(--color-nearly-black);
  }
  p.header-text {
   margin: 2.68rem 0 0 0;
   font-size: var(--font-size-25);
   font-weight: 700;
  }
  p.header-sub-text {
   margin: 0.64rem 0 0.48rem 0;
   font-size: var(--font-size-14);
   font-weight: 500;
   opacity: 0.5;
  }
  div.submit-button {
   cursor: pointer;
   width: 60%;
   height: 1.6rem;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0.48rem 0;
   background: var(--color-grad-bp);
   border-radius: 10px;
  }
  p.submit-button-text {
   font-family: Inter;
   font-size: var(--font-size-14);
   font-weight: 500;
   color: var(--color-white);
   text-align: center;
   margin: 0;
  }
</style>
