import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TagComponent',
  props: {
  backgroundColor: {
    type: String,
    default: 'white'
  },
  color: {
    type: String,
    default: 'black'
  },
  style: {
    type: Object,
    default: null
  }
},
  setup(__props) {




const cssVars = computed(() => {
  return {
    '--background-color': __props.backgroundColor,
    '--color': __props.color,
    ...__props.style
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "tag-container",
    style: _normalizeStyle(cssVars.value)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}
}

})