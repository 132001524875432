import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qr-list-item-image-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "qr-list-item-label-container" }
const _hoisted_4 = { class: "qr-list-item-label" }
const _hoisted_5 = { class: "qr-list-item-tag-container" }
const _hoisted_6 = { class: "qr-list-item-tag-container" }
const _hoisted_7 = { class: "qr-grid-item-code-container" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "qr-grid-item-buttons-container" }

import { computed, PropType } from 'vue'
import { ListSubProfile } from '@/api/types'
import { useDashboardStore, useDropdownStore } from '@/store'

import { Palette } from '@/theme'

import { getTruncatedLabel } from '@/utils'

import QrPublishedTag from '@/components/tag/QrPublishedTag.vue'
import QrUnpublishedTag from '@/components/tag/QrUnpublishedTag.vue'
import QrRootTag from '@/components/tag/QrRootTag.vue'
import DropdownQrDownload from '@/components/dropdown/DropdownQrDownload.vue'
import DropdownQrEditDelete from '@/components/dropdown/DropdownQrEditDelete.vue'
import { storeToRefs } from 'pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'QRListItem',
  props: {
  subProfile: {
    type: Object as PropType<ListSubProfile>,
    required: true
  }
},
  emits: ['onDownload', 'onSelect', 'onDelete'],
  setup(__props, { emit: __emit }) {


const dashboardStore = useDashboardStore()
const { getImageUrl } = dashboardStore

const dropdownStore = useDropdownStore()
const { activeDropdown } = storeToRefs(dropdownStore)

const emit = __emit



const cssVars = computed(() => {
  return {
    '--background-color': Palette.white,
    '--border-color': __props.subProfile.isRootProfile ? Palette.primary : Palette.white,
    '--qr-type-color': __props.subProfile.isRootProfile ? Palette.primary : Palette.white
  }
})

const truncatedLabel = computed(() => {
  return getTruncatedLabel(__props.subProfile.label)
})

const imageUrl = computed(() => {
  return getImageUrl(__props.subProfile.imageUri)
})

function onDownloadPng () {
  emit('onDownload', 'png', __props.subProfile.id)
}

function onDownloadSvg () {
  emit('onDownload', 'svg', __props.subProfile.id)
}

function onSelect () {
  emit('onSelect', __props.subProfile.id)
}

function onDelete () {
  emit('onDelete', __props.subProfile.id)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "qr-list-item-container",
    style: _normalizeStyle(cssVars.value),
    onClick: onSelect
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "qr-list-item-image",
        src: imageUrl.value,
        crossorigin: "anonymous"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(truncatedLabel.value), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (__props.subProfile.isRootProfile)
        ? (_openBlock(), _createBlock(QrRootTag, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (__props.subProfile.active)
        ? (_openBlock(), _createBlock(QrPublishedTag, { key: 0 }))
        : (_openBlock(), _createBlock(QrUnpublishedTag, { key: 1 }))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("img", {
        class: "qr-grid-item-code",
        src: __props.subProfile.qrUri
      }, null, 8, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(DropdownQrDownload, {
        id: 'dropdown-qr-download-'+__props.subProfile.id,
        onOnClickPng: onDownloadPng,
        onOnClickSvg: onDownloadSvg
      }, null, 8, ["id"]),
      _createVNode(DropdownQrEditDelete, {
        id: 'dropdown-qr-edit-delete-'+__props.subProfile.id,
        onOnClickEdit: onSelect,
        onOnClickDelete: onDelete
      }, null, 8, ["id"])
    ])
  ], 4))
}
}

})