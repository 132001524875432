import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "content",
  class: "dropdown-container"
}
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "option-text" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "option-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "option-wrapper",
        style: _normalizeStyle(_ctx.cssVarsOptionSelected),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, [
        _createElementVNode("div", {
          class: "option-dot",
          style: _normalizeStyle(_ctx.cssVarsOptionSelected)
        }, null, 4),
        _createElementVNode("p", _hoisted_3, _toDisplayString(this.textSelected), 1),
        _createElementVNode("img", {
          class: "option-chevron",
          src: require(`@/assets/icons/svg/chevron-${this.pathChevronSuffix}.svg`)
        }, null, 8, _hoisted_4)
      ], 4),
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "option-wrapper dropdown-content",
            style: _normalizeStyle(_ctx.cssVarsOptionUnselected),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSwitch && _ctx.onSwitch(...args)))
          }, [
            _createElementVNode("div", {
              class: "option-dot",
              style: _normalizeStyle(_ctx.cssVarsOptionUnselected)
            }, null, 4),
            _createElementVNode("p", _hoisted_5, _toDisplayString(this.textUnselected), 1)
          ], 4))
        : _createCommentVNode("", true)
    ])
  ], 512))
}