import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SvgLink',
  props: {
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  },
  textStyle: {
    type: Object,
    default: null
  },
  iconStyle: {
    type: Object,
    default: null
  }
},
  setup(__props) {



const imgSrc = computed(() => require('@/assets/icons/svg/' + __props.icon + '.svg'))

const cssVars = computed(() => {
  return {
    ...__props.style
  }
})

const cssVarsText = computed(() => {
  return {
    ...__props.textStyle
  }
})

const cssVarsIcon = computed(() => {
  return {
    ...__props.iconStyle
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "svg-icon-link-wrapper",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("img", {
      src: imgSrc.value,
      alt: "icon",
      style: _normalizeStyle(cssVarsIcon.value)
    }, null, 12, _hoisted_1),
    _createElementVNode("p", {
      class: "svg-icon-link-text",
      style: _normalizeStyle(cssVarsText.value)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ], 4))
}
}

})