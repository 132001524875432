import { ref } from 'vue'
import { defineStore } from 'pinia'

import { api } from '@/api'
import { ListSubProfile } from '@/api/types'
import { useEditorStore } from './editorStore'

export const useDashboardStore = defineStore('dashboard', () => {
  const editorStore = useEditorStore()

  const subProfiles = ref<ListSubProfile[]>([])
  const qrData = ref<any>(null)

  function sortListSubProfileCallback (a: ListSubProfile, b: ListSubProfile) {
    for (let i = 3; i < a.id.length && i < b.id.length; i++) {
      if (a.isRootProfile) {
        return -1
      }
      if (b.isRootProfile || a.id.charCodeAt(i) > b.id.charCodeAt(i)) {
        return 1
      }
    }
    return -1
  }

  function getImageUrl (uri: string) : string {
    return api.getImageUrl(uri)
  }

  async function fetchListSubProfiles (): Promise<string> {
    const response = await api.fetchListSubProfiles()
    if (response.kind !== 'ok') {
      console.log(`Error fetching dashboard: ${JSON.stringify(response)}`, [])
      return response.kind
    }
    subProfiles.value = response.subProfiles.sort(sortListSubProfileCallback)
    return response.kind
  }

  async function createSubProfile () {
    const response = await api.postSubProfile()
    if (response.kind !== 'ok') {
      console.log(`Error creating subprofile: ${JSON.stringify(status)}`, [])
      return response.kind
    }

    const newSubProfileID = response.newSubProfileID
    editorStore.setSubProfileID(newSubProfileID)
    return response.kind
  }

  async function deleteSubProfile (id: string) {
    const status = await api.deleteSubProfile(id)
    if (status.kind !== 'ok') {
      console.log(`Error deleting subprofile: ${JSON.stringify(status)}`, [])
      return status.kind
    }

    this.subProfiles.splice(this.subProfiles.findIndex(item => item.id === id), 1)
    return status.kind
  }

  async function getQrDownload (id: string, type: string) {
    const response = await api.getQrDownload(id, type)
    if (response.status !== 200) {
      console.log(`Error fetching QR code: ${JSON.stringify(response)}`, [])
      return response.status
    }
    qrData.value = response.data
    return response.status
  }

  return {
    subProfiles,
    qrData,
    getImageUrl,
    getQrDownload,
    fetchListSubProfiles,
    createSubProfile,
    deleteSubProfile
  }
})
