import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewSelect',
  props: {
  options: Object,
  label: String,
  modelValue: Boolean,
  layout: {
    type: String,
    default: 'column'
  },
  containerStyle: {
    type: Object,
    default: null
  },
  style: {
    type: Object,
    default: null
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const value = computed({
  get () {
    return __props.modelValue
  },
  set (value: string) {
    emit('update:modelValue', value)
  }
})

const cssVarsContainer = computed(() => {
  return {
    '--container-flex-direction': __props.layout,
    ...__props.containerStyle
  }
})

const cssVarsComponentContainer = computed(() => {
  return {
    '--component-container-width': __props.layout === 'row' ? '70%' : '100%',
    ...__props.style
  }
})

const cssVarsButtonGrid = computed(() => {
  return {
    '--button-background-color': value.value ? 'white' : '#F1F1F1',
    ...__props.style
  }
})

const cssVarsButtonList = computed(() => {
  return {
    '--button-background-color': !value.value ? 'white' : '#F1F1F1',
    ...__props.style
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "select-view-container",
    style: _normalizeStyle(cssVarsContainer.value)
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("label", {
      class: "select-view-label",
      for: "select-view"
    }, "View as", -1)),
    _createElementVNode("div", {
      id: "select-view",
      class: "select-view-wrapper",
      style: _normalizeStyle(cssVarsComponentContainer.value)
    }, [
      _createElementVNode("div", {
        class: "select-view-button",
        style: _normalizeStyle(cssVarsButtonGrid.value),
        onClick: _cache[0] || (_cache[0] = () => value.value = true)
      }, [
        _createElementVNode("img", {
          class: "select-view-button-icon",
          src: require('@/assets/icons/svg/grid.svg')
        }, null, 8, _hoisted_1)
      ], 4),
      _createElementVNode("div", {
        class: "select-view-button",
        style: _normalizeStyle(cssVarsButtonList.value),
        onClick: _cache[1] || (_cache[1] = () => value.value = false)
      }, [
        _createElementVNode("img", {
          class: "select-view-button-icon",
          src: require('@/assets/icons/svg/list.svg')
        }, null, 8, _hoisted_2)
      ], 4)
    ], 4)
  ], 4))
}
}

})