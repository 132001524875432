<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import Palette from '@/theme/palette'

const route = useRoute()

const { name, src, srcSelected, disabled } = defineProps({
  name: {
    type: String,
    default: ''
  },
  src: {
    type: String,
    default: ''
  },
  srcSelected: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const cssVarsContainer = computed(() => {
  return {
    '--background': name === route.name
      ? 'linear-gradient(116deg, rgba(63, 128, 255, 0.1) 16.27%, rgba(123, 61, 255, 0.1) 83.73%)'
      : Palette.white,
    '--opacity': disabled ? '0.5' : '1.0',
    '--cursor': disabled ? 'default' : 'pointer'
  }
})

const cssVarsText = computed(() => {
  if (name === route.name) return { '--font-weight': 600, '--opacity': 1.0 }
  else return { '--font-weight': 400, '--opacity': 1.0 }
})

const srcImg = computed(() => {
  if (name === route.name) return require(`@/assets/icons/svg/${srcSelected}`)
  else return require(`@/assets/icons/svg/${src}`)
})

</script>

<template>
   <div class="sidebar-link-container" :style="cssVarsContainer" href="/analytics">
        <img class="sidebar-link-icon" :src="srcImg"/>
        <span class="sidebar-link-text" :style="cssVarsText"><slot/></span>
    </div>
  </template>

<style scoped lang="scss">
    div.sidebar-link-container {
        width: 180px;
        height: 34px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--background);
        opacity: var(--opacity);
        border: none;
        border-radius: 8px;
        cursor: var(--cursor);
        margin-top: 6px;
    }

    .sidebar-link-icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        opacity: var(--opacity);
        margin: 0 15px 0 15px;
    }

    span.sidebar-link-text {
        color: var(--color-nearly-black);
        font-family: Inter;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight);
        line-height: 14px; /* 100% */
        opacity: var(--opacity);
    }

  </style>
