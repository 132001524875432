import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  for: "id",
  class: "label"
}
const _hoisted_2 = ["name", "type", "placeholder"]
const _hoisted_3 = ["name", "type", "placeholder"]
const _hoisted_4 = {
  key: 3,
  class: "error-message"
}

import { computed, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginInput',
  props: {
  name: String,
  label: String,
  labelVisible: Boolean,
  type: String,
  placeholder: String,
  modelValue: String,
  hasError: Boolean,
  errorMessage: String,
  containerStyle: {
    type: Object,
    default: null
  },
  wrapperStyle: {
    type: Object,
    default: null
  },
  multiline: {
    type: Boolean,
    default: false
  }
},
  emits: ['update:modelValue', 'onFocus'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const isFocused = ref(false)

const value = computed({
  get () {
    return __props.modelValue
  },
  set (value: string) {
    emit('update:modelValue', value)
  }
})

const cssContainerStyle = computed(() => {
  return {
    ...__props.containerStyle,
    height: __props.multiline ? 'auto' : '1.6rem'
  }
})

const cssWrapperStyle = computed(() => {
  return {
    ...__props.wrapperStyle
  }
})

function onFocus () {
  isFocused.value = true
  emit('onFocus', true)
}

function onBlur () {
  isFocused.value = false
  emit('onFocus', false)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-container", { focused: isFocused.value }]),
    style: _normalizeStyle(cssContainerStyle.value)
  }, [
    (isFocused.value || __props.labelVisible)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(__props.label), 1))
      : _createCommentVNode("", true),
    (__props.multiline)
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 1,
          id: "id",
          name: __props.name,
          type: __props.type,
          placeholder: __props.placeholder,
          class: "input-wrapper",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
          onFocus: onFocus,
          onBlur: onBlur,
          style: _normalizeStyle(cssWrapperStyle.value),
          rows: "17"
        }, null, 44, _hoisted_2)), [
          [_vModelText, value.value]
        ])
      : _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 2,
          id: "id",
          name: __props.name,
          type: __props.type,
          placeholder: __props.placeholder,
          class: "input-wrapper",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((value).value = $event)),
          onFocus: onFocus,
          onBlur: onBlur,
          style: _normalizeStyle(cssWrapperStyle.value)
        }, null, 44, _hoisted_3)), [
          [_vModelDynamic, value.value]
        ]),
    (__props.hasError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(__props.errorMessage), 1),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "error-icon" }, "⚠", -1))
        ]))
      : _createCommentVNode("", true)
  ], 6))
}
}

})