import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo-grad-text.png'


const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "form-container" }

import RegisterRootForm from '@/components/form/RegisterRootForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterRootView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "header-image",
        src: _imports_0
      }, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "header-text" }, "Almost Done!", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "header-sub-text" }, "Create your root/account URL", -1)),
      _createVNode(RegisterRootForm)
    ])
  ]))
}
}

})