import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "panel-section panel-section-image-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "panel-section panel-section-image-select" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = { class: "select-button-container" }
const _hoisted_8 = {
  for: "image-select",
  style: {"width":"100%"}
}

import { computed, PropType, ref, useTemplateRef } from 'vue'
import { QrSection, ImageSectionContent, QrSectionTargetEnum } from '@/api/types'
import { useEditorStore } from '@/store'

import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorTextIconButton from '@/components/button/editor-panel/EditorTextIconButton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImagePanel',
  props: {
  imageSection: {
    type: Object as PropType<QrSection<ImageSectionContent, null>>,
    required: true
  }
},
  setup(__props) {

const editorStore = useEditorStore()
const { getImageUrl, postQrSectionImage, resetQrSectionImage } = editorStore



const { id, label, active, persistent, section, style } = __props.imageSection

const styleMode = ref(false)

const imageSelect = useTemplateRef('imageSelect')

const imageUrl = computed(() => getImageUrl(section.uri))

const imageExists = computed(() => section.uri !== 'default')

const cssButton = computed(() => {
  return {
    width: '4.8rem',
    height: '1.28rem',
    borderRadius: '0.26rem',
    backgroundColor: '#fff',
    lineHeight: '0'
  }
})

function onSelectImageStart () {
  if (imageSelect.value) {
    imageSelect.value.click()
  }
}

async function onSelectImageEnd (event : Event) {
  if (!imageSelect.value) return
  const files: ReadonlyArray<File> = [...(imageSelect.value.files ? imageSelect.value.files : [])]
  if (files) {
    const status = await postQrSectionImage(id, QrSectionTargetEnum.CONTENT, 'uri', files[0])
    imageSelect.value.value = ''
  }
}

async function onResetImage () {
  const status = await resetQrSectionImage(id, QrSectionTargetEnum.CONTENT, 'uri')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    hasStyle: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (imageExists.value)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "delete-button",
                href: "#",
                onClick: onResetImage
              }, [
                _createElementVNode("img", {
                  class: "delete-button-image",
                  src: require('@/assets/icons/button/btn-close.png')
                }, null, 8, _hoisted_3)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("img", {
            class: "header-image",
            src: imageUrl.value,
            crossorigin: "anonymous"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "image-select-header-text" }, "Replace Header image", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "image-select-info-text" }, "Use a square image with a resolution that's at least 600 pixels.", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("input", {
                type: "file",
                id: "image-select",
                ref_key: "imageSelect",
                ref: imageSelect,
                hidden: "",
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ( onSelectImageEnd &&  onSelectImageEnd(...args)))
              }, null, 544),
              _createElementVNode("label", _hoisted_8, [
                _createVNode(EditorTextIconButton, {
                  icon: "editor-image",
                  onClick: onSelectImageStart,
                  style: _normalizeStyle(cssButton.value)
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Upload Image ")
                  ])),
                  _: 1
                }, 8, ["style"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})