import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'

import DropdownButton from './DropdownButton.vue'
import { Palette } from '@/theme'

import { DropdownItem } from '@/components/types'
import { QrSectionTypeEnum } from '@/api/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownQrAddSection',
  emits: ['onClickLink', 'onAddSocials'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const items: DropdownItem[] = [
  {
    icon: 'editor-create-section-dropdown-text',
    text: 'Text',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Link',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-socials',
    text: 'Socials',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-embedded-video',
    text: 'Video',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Amazon Review CTA',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Button',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Image',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Features',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Header',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Image',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'News',
    subItems: []
  },
  {
    icon: 'editor-create-section-dropdown-link',
    text: 'Table',
    subItems: []
  }
]

const itemToType = [
  QrSectionTypeEnum.TEXT,
  QrSectionTypeEnum.LINK,
  QrSectionTypeEnum.SOCIALS,
  QrSectionTypeEnum.EMBEDDED_VIDEO,
  QrSectionTypeEnum.AMAZAON_REVIEW_CTA,
  QrSectionTypeEnum.BUTTON,
  QrSectionTypeEnum.IMAGE,
  QrSectionTypeEnum.FEATURES,
  QrSectionTypeEnum.HEADER,
  QrSectionTypeEnum.IMAGE,
  QrSectionTypeEnum.NEWS,
  QrSectionTypeEnum.TABLE
]

function onClickLink (id: number) {
  if (id === QrSectionTypeEnum.SOCIALS) {
    emit('onAddSocials')
  } else {
    emit('onClickLink', id)
  }
}

const cssButton = computed(() => {
  return {
    backgroundImage: Palette.gradientBP,
    borderRadius: '0.38rem',
    padding: '0.54rem 0.96rem',
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const cssButtonText = computed(() => {
  return {
    color: 'var(--color-white)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-16)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px', /* 100% */
    opacity: '1',
    margin: '0 0 0 0.38rem'
  }
})

/* const cssIconContainer = computed(() => {
  return {
    width: '0.36rem',
    height: '0.36rem'
  }
}) */

const cssLink = computed(() => {
  return {
    minWidth: '8.25rem',
    padding: '0.38rem 0.44rem'
  }
})

const cssLinkText = computed(() => {
  return {
    color: 'var(--color-nearly-black)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-14)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    marginLeft: '0.64rem'
  }
})

const cssLinkIcon = computed(() => {
  return {
    width: '0.64rem',
    height: '0.64rem'
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownButton, {
    id: "dropdown-qr-add-section",
    "btn-icon": "editor-plus",
    "btn-text": "Add Section",
    "btn-style": cssButton.value,
    "btn-text-style": cssButtonText.value,
    "link-style": cssLink.value,
    "link-text-style": cssLinkText.value,
    "link-icon-style": cssLinkIcon.value,
    items: items,
    onOnClick: _cache[0] || (_cache[0] = (idx) => onClickLink(itemToType[idx]))
  }, null, 8, ["btn-style", "btn-text-style", "link-style", "link-text-style", "link-icon-style"]))
}
}

})