<script setup lang="ts">
import { computed, defineProps } from 'vue'

const { icon, style } = defineProps({
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  }
})

const imgSrc = computed(() => require('@/assets/icons/svg/' + icon + '.svg'))

const cssVars = computed(() => {
  return {
    ...style
  }
})

</script>

<template>
    <div class="svg-icon-button-wrapper" :style="cssVars">
        <img :src="imgSrc" alt="icon">
    </div>
</template>

<style scoped lang="scss">
    div.svg-icon-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .2s ease-in-out;
      cursor: pointer;
    }
    div.svg-icon-button-wrapper:hover{
      transform: scale(1.1);
    }
    div.svg-icon-button-wrapper:active{
      transform: scale(1.05);
      transition: .2s;
    }
    img {
        width: 100%;
        height: 100%;
    }
</style>
