import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-column embedded-video-style-panel-content"
}
const _hoisted_3 = { class: "d-flex embedded-video-style-panel-row align-items-center" }
const _hoisted_4 = { class: "d-flex" }

import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EmbeddedVideoPlayer from '@/components/video/EmbeddedVideoPlayer.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'EmbeddedVideoPanel',
  props: {
  embeddedVideoSection: {
    type: Object as PropType<QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>>,
    required: true
  }
},
  emits: ['onToggleMode'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = __emit



const styleMode = ref(false)

const { id, label, active, persistent, section, style } = __props.embeddedVideoSection

const controlsShow = computed(() => {
  return __props.embeddedVideoSection.style.controls === 'show'
})

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', __props.embeddedVideoSection.id, styleMode.value)
  }
}

function onEditUrl (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'url', value)
}

function onEditControls (value: string) {
  if (value === __props.embeddedVideoSection.style.controls) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'controls', value)
}

function onEditThumbnail (value: string) {
  if (value === __props.embeddedVideoSection.style.thumbnail) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'thumbnail', value)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    onOnToggleMode: onToggleMode
  }, {
    default: _withCtx(() => [
      (!styleMode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(EditorInputComponent, {
              label: "URL",
              value: _unref(section).url,
              onOnChangeValue: onEditUrl,
              multiline: true,
              "input-component-style": {height: '138px', width: '387px'},
              style: {"height":"display: flex","align-items":"flex-start","padding":"10px 10px 10px 20px"}
            }, {
              default: _withCtx(() => [
                _createVNode(EmbeddedVideoPlayer, {
                  "player-width": 245,
                  "player-height": 138,
                  "player-style": {margin: '0 0 10px 10px'},
                  embeddedVideoSection: __props.embeddedVideoSection
                }, null, 8, ["embeddedVideoSection"])
              ]),
              _: 1
            }, 8, ["value"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Controls", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(StyleTextIconButton, {
                  icon: 'style-controls-show',
                  selected: controlsShow.value,
                  onClick: _cache[0] || (_cache[0] = () => onEditControls('show'))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Show")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextIconButton, {
                  icon: 'style-controls-hide',
                  selected: !controlsShow.value,
                  onClick: _cache[1] || (_cache[1] = () => onEditControls('hide'))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Hide")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})