<script setup lang="ts">
import { ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, ButtonSectionContent, ButtonSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleTextButton from '@/components/button/style-panel/StyleTextButton.vue'
import StyleIconButton from '@/components/button/style-panel/StyleIconButton.vue'
import { Palette } from '@/theme'

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = defineEmits(['onToggleMode'])

const { buttonSection } = defineProps({
  buttonSection: {
    type: Object as PropType<QrSection<ButtonSectionContent, ButtonSectionStyle>>,
    required: true
  }
})

const styleMode = ref(false)

const { id, label, active, persistent, section, style } = buttonSection

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onEditText (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'text', value)
}

function onEditUrl (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'url', value)
}

function onEditTextColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', value)
}

function onEditFillColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'fillColor', value)
}

function onEditStyle (value: string) {
  if (value === 'outline' && style.textColor === Palette.white) {
    patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', style.fillColor)
  } else if (style.textColor === style.fillColor) {
    patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', Palette.white)
  }
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'style', value)
}

function onEditCorners (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'corners', value)
}

function onEditSize (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', value)
}

function onEditAlign (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', value)
}

</script>

<template>
    <EditorPanel
      :id="id"
      :title="label"
      :active="active"
      :persistent="persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!styleMode" class="d-flex flex-column">
        <EditorInputComponent label="Button Text" :value="section.text" @onChangeValue="onEditText"/>
        <EditorInputComponent label="URL" :value="section.url" @onChangeValue="onEditUrl"/>
        </div>
      <div v-else class="d-flex flex-column button-style-panel-content">
        <div class="d-flex button-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Color</p>
            <div class="d-flex align-items-center button-style-panel-row-group-br" style="padding: 0 10px 0 0">
              <ColorInput :label="'Text'" :value="style.textColor" @onChangeColor="onEditTextColor"/>
            </div>
            <div class="d-flex" style="padding: 0 0 0 10px">
              <ColorInput :label="'Fill'" :value="style.fillColor" @onChangeColor="onEditFillColor"/>
            </div>
          </div>
        <div class="d-flex button-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Style</p>
            <div class="d-flex align-items-center button-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="style.style == 'solid'"
                @click="() => onEditStyle('solid')"
                style="width: 94px; border-radius: 0">
                Solid
              </StyleTextButton>
              <StyleTextButton
                :selected="style.style == 'outline'"
                @click="() => onEditStyle('outline')"
                style="width: 94px; border-radius: 0; background-color: #FFFFFF; box-shadow: 0px 0px 0px 2px rgba(33, 37, 41, 0.20) inset">
                Outline
              </StyleTextButton>
              <StyleTextButton
                :selected="style.style == 'pill'"
                @click="() => onEditStyle('pill')"
                style="width: 94px; border-radius: 18px">
                Pill
              </StyleTextButton>
            </div>
            <div class="d-flex" style="padding: 0 0 0 2px">
              <StyleTextButton
                :selected="style.corners == 'sharp'"
                @click="() => onEditCorners('sharp')"
                style="width: 108px; border-radius: 0">
                Sharp Corners
              </StyleTextButton>
              <StyleTextButton
                :selected="style.corners == 'rounded'"
                @click="() => onEditCorners('rounded')"
                style="width: 135px">
                Rounded Corners
              </StyleTextButton>
            </div>
          </div>
          <div class="d-flex button-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Size & Align</p>
            <div class="d-flex align-items-center button-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="style.size == 'sm'"
                @click="() => onEditSize('sm')"
                style="width: 30px; height: 30px">
                S
              </StyleTextButton>
              <StyleTextButton
                :selected="style.size == 'md'"
                @click="() => onEditSize('md')"
                style="width: 36px; height: 36px;">
                M
              </StyleTextButton>
              <StyleTextButton
                :selected="style.size == 'lg'"
                @click="() => onEditSize('lg')"
                style="width: 40px;">
                L
              </StyleTextButton>
            </div>
            <div class="d-flex align-items-center" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'style-align-left'" :selected="style.align == 'left'" @click="() => onEditAlign('left')"/>
              <StyleIconButton :icon="'style-align-center'" :selected="style.align == 'center'" @click="() => onEditAlign('center')"/>
              <StyleIconButton :icon="'style-align-right'" :selected="style.align == 'right'" @click="() => onEditAlign('right')"/>
              <StyleIconButton :icon="'style-align-fill'" :selected="style.align == 'fill'" @click="() => onEditAlign('fill')"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>

div.button-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.button-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.button-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
