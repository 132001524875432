import axios from 'axios'
import {
  ApisauceInstance,
  create
} from 'apisauce'
import Config from '@/config'
import type {
  ApiConfig,
  ApiTokenResponse,
  AuthDataResponse,
  BoolValue,
  DataResponse,
  EmptyResponse,
  EntityID,
  Keypin,
  ListSubProfile,
  ListSubProfileResponse,
  QrSection,
  QrSectionContentType,
  QrSectionItemContentType,
  QrSectionItemType,
  QrSectionStyleType,
  QrSectionType,
  SubProfile,
  SubProfileQrResponse,
  UserSubProfile,
  URL,
  QrSectionItemIterable
} from './types'
import { GeneralApiProblem, getGeneralApiProblem } from './apiProblem'

/**
 * Configuring apisauce instance
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL + '/api',
  imageUrl: Config.API_URL + '/image',
  qrBaseUrl: Config.QR_BASE_URL,
  timeout: 10000
}

export class Api {
  apisauce: ApisauceInstance
  config: ApiConfig
  imageUrl: string
  qrBaseUrl: string

  token: string | null = null

  constructor (config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
    this.imageUrl = config.imageUrl
    this.qrBaseUrl = config.qrBaseUrl
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json'
      }
    })

    this.loadToken()
    console.log('API initialized with', this.token)
  }

  /**
   * TOKEN
   */
  loadToken () {
    const value = localStorage.getItem('token')
    this.token = value ? JSON.parse(value) : null
    this.token && this.apisauce.setHeader('Authorization', `bearer ${this.token}`)
  }

  setToken (value: string | null) {
    localStorage.setItem('token', JSON.stringify(value))
    this.token = value
    this.token && this.apisauce.setHeader('Authorization', `bearer ${this.token}`)
  }

  clearToken () {
    localStorage.removeItem('token')
    this.token = null
    this.apisauce.setHeader('Authorization', '')
  }

  /**
   * APP CONTROLLER
   */
  async postLoginEmail (email: string): Promise<{ kind: 'ok'; keypin: string } | GeneralApiProblem> {
    this.apisauce.setHeader('Authorization', '')
    const response: DataResponse<Keypin> = await this.apisauce.post(
      '/keypin-email',
      { value: email }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem?.kind === 'unauthorized') this.clearToken()
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const keypin = response.data.data.value

    return { kind: 'ok', keypin }
  }

  async getLogout (): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    const response: EmptyResponse = await this.apisauce.get(
      '/logout'
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    this.setToken(null)

    return { kind: 'ok' }
  }

  /**
   * AUTH
   */
  async postLogin (username: string, password: string): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    const response: ApiTokenResponse = await this.apisauce.post(
      '/login_check',
      { username, password }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const token = response.data.token

    this.setToken(token)

    return { kind: 'ok' }
  }

  async postRegistration (email: string, password: string, regCode: string): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    this.apisauce.setHeader('Authorization', '')
    const response: ApiTokenResponse = await this.apisauce.post(
      '/onboarding/register-web',
      { email, password, regCode }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const token = response.data.token

    this.setToken(token)

    return { kind: 'ok' }
  }

  async postRootRegistration (rootName: string): Promise<{ kind: 'ok'; rootProfileID: string } | GeneralApiProblem> {
    this.loadToken()
    const response: AuthDataResponse<EntityID> = await this.apisauce.post(
      '/onboarding/register-root',
      { value: rootName }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const token = response.data.token
    this.setToken(token)

    const rootProfileID = response.data.data.value

    return { kind: 'ok', rootProfileID }
  }

  /**
   * DASHBOARD
   */
  async fetchListSubProfiles (): Promise<{ kind: 'ok'; subProfiles: ListSubProfile[] } | GeneralApiProblem> {
    this.loadToken()
    const response: DataResponse<ListSubProfileResponse> = await this.apisauce.get(
      '/dashboard/all'
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem?.kind === 'unauthorized') this.clearToken()
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const subProfiles = response.data.data.subProfiles

    return { kind: 'ok', subProfiles: subProfiles }
  }

  async fetchUserSubProfile (id: string): Promise<{ kind: 'ok'; subProfile: UserSubProfile } | GeneralApiProblem> {
    this.loadToken()
    const response: AuthDataResponse<UserSubProfile> = await this.apisauce.get(
      `/dashboard/${encodeURIComponent(id)}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const subProfile = response.data.data

    return { kind: 'ok', subProfile }
  }

  async postSubProfile (): Promise<{ kind: 'ok'; newSubProfileID: string } | GeneralApiProblem> {
    const response: AuthDataResponse<EntityID> = await this.apisauce.post(
      '/dashboard'
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const newSubProfileID = response.data.data.value

    return { kind: 'ok', newSubProfileID }
  }

  async deleteSubProfile (id: string): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    const response: AuthDataResponse<BoolValue> = await this.apisauce.get(
      `/dashboard/${encodeURIComponent(id)}/delete`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return { kind: 'ok' }
  }

  async toggleSubProfileActive (id: string): Promise<{ kind: 'ok'; newActiveValue: boolean } | GeneralApiProblem> {
    const response: AuthDataResponse<BoolValue> = await this.apisauce.get(
      `/dashboard/${encodeURIComponent(id)}/toggle-active`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const newActiveValue = response.data.data.value

    return { kind: 'ok', newActiveValue }
  }

  async getQrDownload (id: string, type: string): Promise<any> {
    const response = await axios(
      { method: 'get', url: DEFAULT_API_CONFIG.url + `/dashboard/qr-file/${type}/${id}`, withCredentials: false, responseType: 'blob', headers: { Authorization: `bearer ${this.token}` } }
    )

    if (response.status !== 200) {
      console.log(response)
      return
    }

    return response
  }

  /**
   * EDITOR
   */
  async postQrSection (subProfileID: string, qrSectionType: number): Promise<{ kind: 'ok'; newQrSection: QrSectionType } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}`,
      { type: qrSectionType }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const newQrSection = response.data.data

    return { kind: 'ok', newQrSection }
  }

  async postQrSectionItem (subProfileID: string, qrSectionID: number, items: any[] = []): Promise<{ kind: 'ok'; newQrSectionItemsIterable: QrSectionItemIterable } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionItemIterable> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/item?section=${encodeURIComponent(qrSectionID)}`,
      { items }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const newQrSectionItems = response.data.data

    return { kind: 'ok', newQrSectionItemsIterable: newQrSectionItems }
  }

  async patchQrSection (subProfileID: string, qrSectionID: number, target: string, property: string, value: any): Promise<{ kind: 'ok'; updatedValues } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionContentType | QrSectionStyleType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/edit?section=${encodeURIComponent(qrSectionID)}&target=${encodeURIComponent(target)}`,
      { [property]: value }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const updatedValues = response.data.data

    return { kind: 'ok', updatedValues }
  }

  async duplicateQrSection (subProfileID: string, qrSectionID: number): Promise<{ kind: 'ok', newQrSection: QrSectionType } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/duplicate?section=${encodeURIComponent(qrSectionID)}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const newQrSection = response.data.data

    return { kind: 'ok', newQrSection }
  }

  async patchQrSectionItem (subProfileID: string, qrSectionID: number, qrSectionItemID: number, property: string, value: any): Promise<{ kind: 'ok'; updatedValues } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionItemContentType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/edit-item?section=${encodeURIComponent(qrSectionID)}&item=${encodeURIComponent(qrSectionItemID)}`,
      { [property]: value }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const updatedValues = response.data.data

    return { kind: 'ok', updatedValues }
  }

  async putQrSectionItemOrder (subProfileID: string, qrSectionID: number, order: number[]): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    const response: AuthDataResponse<null> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/item-order?section=${encodeURIComponent(qrSectionID)}`,
      { value: order }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return { kind: 'ok' }
  }

  async putQrSectionOrder (subProfileID: string, order: number[]): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    const response: AuthDataResponse<null> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/order`,
      { value: order }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return { kind: 'ok' }
  }

  async deleteQrSectionItem (subProfileID: string, qrSectionID: number, qrSectionItemID: number): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    const response: AuthDataResponse<BoolValue> = await this.apisauce.get(
      `/dashboard/${encodeURIComponent(subProfileID)}/delete-item?section=${encodeURIComponent(qrSectionID)}&item=${encodeURIComponent(qrSectionItemID)}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return { kind: 'ok' }
  }

  async deleteQrSection (subProfileID: string, qrSectionID): Promise<{ kind: 'ok' } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionContentType | QrSectionStyleType> = await this.apisauce.get(
      `/dashboard/${encodeURIComponent(subProfileID)}/delete-section?section=${encodeURIComponent(qrSectionID)}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    return { kind: 'ok' }
  }

  async postQrSectionImage (subProfileID: string, qrSectionID: number, target: string, property: string, file: File): Promise<{ kind: 'ok'; updatedValues } | GeneralApiProblem> {
    const formData = new FormData()
    formData.append('file', file)

    const response: AuthDataResponse<QrSectionContentType | QrSectionStyleType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/edit-image?section=${qrSectionID}&target=${target}&property=${property}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }

    const updatedValues = response.data.data

    return { kind: 'ok', updatedValues }
  }

  async postQrSectionItemImage (subProfileID: string, qrSectionID: number, itemID: number, property: string, file: File): Promise<{ kind: 'ok'; updatedValues } | GeneralApiProblem> {
    const formData = new FormData()
    formData.append('file', file)

    const response: AuthDataResponse<QrSectionContentType | QrSectionStyleType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/edit-item-image?section=${qrSectionID}&item=${itemID}&property=${property}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }

    const updatedValues = response.data.data

    return { kind: 'ok', updatedValues }
  }

  async resetQrSectionImage (subProfileID: string, qrSectionID: number, target: string, property: string): Promise<{ kind: 'ok'; updatedValues } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionContentType | QrSectionStyleType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/edit-image?section=${qrSectionID}&target=${target}&property=${property}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }

    const updatedValues = response.data.data

    return { kind: 'ok', updatedValues }
  }

  async resetQrSectionItemImage (subProfileID: string, qrSectionID: number, itemID: number, property: string): Promise<{ kind: 'ok'; updatedValues } | GeneralApiProblem> {
    const response: AuthDataResponse<QrSectionContentType | QrSectionStyleType> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/edit-item-image?section=${qrSectionID}&item=${itemID}&property=${property}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }

    const updatedValues = response.data.data

    return { kind: 'ok', updatedValues }
  }

  /**
   * EDITOR REDIRECT
   */
  async patchRedirectURL (subProfileID: string, url: string): Promise<{ kind: 'ok'; updatedValue: string } | GeneralApiProblem> {
    const response: AuthDataResponse<URL> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/redirect/edit-url`,
      { value: url }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }

    const updatedValue = response.data.data.value

    return { kind: 'ok', updatedValue }
  }

  async resetRedirectUrl (subProfileID: string): Promise<{ kind: 'ok'; updatedValue: string } | GeneralApiProblem> {
    const response: AuthDataResponse<URL> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/redirect/reset`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }

    const updatedValue = response.data.data.value

    return { kind: 'ok', updatedValue }
  }

  async patchRedirectStatus (subProfileID: string, status: boolean): Promise<{ kind: 'ok'; updatedValue: boolean } | GeneralApiProblem> {
    const response: AuthDataResponse<BoolValue> = await this.apisauce.post(
      `/dashboard/${encodeURIComponent(subProfileID)}/redirect/edit-status`,
      { value: status }
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }

    const updatedValue = response.data.data.value

    return { kind: 'ok', updatedValue }
  }

  /*
  * SUBPROFILE
  */
  async fetchSubProfileFromQr (id: string): Promise<{ kind: 'ok'; qrScanId: number, accessType: string, subProfile: SubProfile } | GeneralApiProblem> {
    const response: DataResponse<SubProfileQrResponse> = await this.apisauce.get(
      `/qr/${encodeURIComponent(id)}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const { qrScanId, accessType, subProfile } = response.data.data

    return { kind: 'ok', qrScanId, accessType, subProfile }
  }

  async fetchSubProfileFromUrl (name: string, suffix: string): Promise<{ kind: 'ok'; qrScanId: number, accessType: string, subProfile: SubProfile } | GeneralApiProblem> {
    const response: DataResponse<SubProfileQrResponse> = await this.apisauce.get(
      `/link/${encodeURIComponent(name)}/${encodeURIComponent(suffix)}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const { qrScanId, accessType, subProfile } = response.data.data

    return { kind: 'ok', qrScanId, accessType, subProfile }
  }

  async fetchRootProfileFromUrl (name: string): Promise<{ kind: 'ok'; qrScanId: number, accessType: string, subProfile: SubProfile } | GeneralApiProblem> {
    const response: DataResponse<SubProfileQrResponse> = await this.apisauce.get(
      `/link/${encodeURIComponent(name)}}`
    )

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    if (response.data === undefined) return { kind: 'bad-data' }
    const { qrScanId, accessType, subProfile } = response.data.data

    return { kind: 'ok', qrScanId, accessType, subProfile }
  }

  /**
   * IMAGE
   */
  getImageUrl (uri: string): string {
    return this.imageUrl + '?id=' + uri
  }

  /**
   * QR URL
  */
  getQrUrl (id: string): string {
    return this.qrBaseUrl + `/${id}`
  }
}

export const api = new Api()
