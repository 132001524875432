<script setup lang="ts">

import { computed, defineProps } from 'vue'

const { backgroundColor, color, style } = defineProps({
  backgroundColor: {
    type: String,
    default: 'white'
  },
  color: {
    type: String,
    default: 'black'
  },
  style: {
    type: Object,
    default: null
  }
})

const cssVars = computed(() => {
  return {
    '--background-color': backgroundColor,
    '--color': color,
    ...style
  }
})

</script>

<template>
  <div class="tag-container" :style="cssVars">
    <slot/>
  </div>
</template>

<style scoped lang="scss">
  div.tag-container {
    background-color: var(--background-color);
    color: var(--color);
    position: absolute;
    width: 76px;
    height: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px; /* 100% */
  }

  </style>
