import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "view-container" }
const _hoisted_2 = { class: "view-content container-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "row top-bar-scroll"
}
const _hoisted_4 = { class: "col-xl-3 col-lg-4 d-sm-flex d-none align-items-center" }
const _hoisted_5 = { class: "col-xl-2 col-lg-4 col-sm-6 col-12 d-flex align-items-center" }
const _hoisted_6 = { class: "col-xl-2 col-lg-4 col-sm-6 col-12 d-flex align-items-center" }
const _hoisted_7 = { class: "d-lg-flex d-none col-2" }
const _hoisted_8 = {
  key: 1,
  class: "d-flex flex-row"
}
const _hoisted_9 = { style: {"width":"50%","max-width":"200px","justify-self":"end"} }
const _hoisted_10 = { class: "row sub-nav-container" }
const _hoisted_11 = { class: "row search-select-container" }
const _hoisted_12 = { class: "search-container col-xl-6 col-lg-4 d-sm-block d-none" }
const _hoisted_13 = { class: "col-xl-3 col-lg-4 col-sm-6 col-12" }
const _hoisted_14 = { class: "col-xl-3 col-lg-4 col-sm-6 col-12" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "qr-list-container" }

import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import ManageCreateQrButton from '@/components/button/ManageCreateQrButton.vue'
import SubNav from '@/components/nav/SubNav.vue'
import QRGrid from '@/components/dashboard/QRGrid.vue'
import QRList from '@/components/dashboard/QRList.vue'
import SearchInput from '@/components/input/SearchInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import ViewSelect from '@/components/select/ViewSelect.vue'

import { ListSubProfile } from '@/api/types'

import { useDashboardStore, useEditorStore } from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'ManageView',
  setup(__props) {

const router = useRouter()

const dashboardStore = useDashboardStore()
const { subProfiles, qrData } = storeToRefs(dashboardStore)
const { fetchListSubProfiles, createSubProfile, deleteSubProfile, getQrDownload } = dashboardStore

const editorStore = useEditorStore()
const { subProfileID } = storeToRefs(editorStore)

const tabs = ref(['All QRs', 'Published', 'Unpublished', 'Archived'])
const currentTab = ref(0)
const scrolling = ref(false)

const searchText = ref('')
const sortBy = ref('Newest')
const sortByOptions = ref([
  'Newest',
  'A-Z'
])
const isGrid = ref(true)

function selectTab (value: number) {
  currentTab.value = value
}

function updateScroll () {
  scrolling.value = window.scrollY > 150
}

function getVisibleSubProfiles () {
  let visibleSubProfiles = subProfiles.value
  if (currentTab.value === 1) visibleSubProfiles = subProfiles.value.filter((sp: ListSubProfile) => sp.active)
  else if (currentTab.value === 2) visibleSubProfiles = subProfiles.value.filter((sp: ListSubProfile) => !sp.active)

  if (searchText.value.length > 0) visibleSubProfiles = visibleSubProfiles.filter((sp: ListSubProfile) => sp.label.toLowerCase().includes(searchText.value.toLowerCase()))
  if (sortBy.value === 'Newest') return visibleSubProfiles.sort(function (a: ListSubProfile, b: ListSubProfile) { return b.id.localeCompare(a.id) })
  else if (sortBy.value === 'A-Z') return visibleSubProfiles.sort(function (a: ListSubProfile, b: ListSubProfile) { return a.label.localeCompare(b.label) })
  return visibleSubProfiles
}

async function onCreateSubProfile () {
  const status = await createSubProfile()
  if (status !== 'ok') return
  router.push(`/dashboard/${subProfileID.value}`)
}

function onSelectSubProfile (id: string) {
  router.push(`/dashboard/${encodeURIComponent(id)}`)
}

async function onDeleteSubProfile (id: string) {
  await deleteSubProfile(id)
}

async function onDownloadQR (type: string, subProfileID: string) {
  const status = await getQrDownload(subProfileID, type)
  if (status !== 200) return

  const href = URL.createObjectURL(qrData.value)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', subProfileID + '.' + type)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

onMounted(async () => {
  window.addEventListener('scroll', updateScroll)
  const status = await fetchListSubProfiles()
  if (status === 'unauthorized') router.push('/login')
})

onUnmounted(() => window.removeEventListener('scroll', updateScroll))


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (scrolling.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-xl-2 col-6 d-flex align-items-center p-0" }, [
              _createElementVNode("h1", { class: "page-title page-title-scrolling" }, "Keypins")
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(SearchInput, {
                modelValue: searchText.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
                placeholder: "Search your QR Codes...",
                "wrapper-style": {width: '100%'},
                "container-style": {width: '100%'}
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(SelectInput, {
                options: sortByOptions.value,
                modelValue: sortBy.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sortBy).value = $event)),
                label: "Sort by",
                layout: "row"
              }, null, 8, ["options", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(ViewSelect, {
                layout: "row",
                modelValue: isGrid.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isGrid).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(ManageCreateQrButton, { onClick: onCreateSubProfile })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"flex":"1"} }, [
              _createElementVNode("h1", { class: "page-title page-title-fixed" }, "Keypins")
            ], -1)),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(ManageCreateQrButton, { onClick: onCreateSubProfile })
            ])
          ])),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(SubNav, {
          tabs: tabs.value,
          "current-tab": currentTab.value,
          onSelectTab: selectTab
        }, null, 8, ["tabs", "current-tab"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(SearchInput, {
            modelValue: searchText.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchText).value = $event)),
            placeholder: "Search your QR Codes..."
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(SelectInput, {
            options: sortByOptions.value,
            modelValue: sortBy.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((sortBy).value = $event)),
            label: "Sort by:",
            layout: "row"
          }, null, 8, ["options", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(ViewSelect, {
            layout: "row",
            modelValue: isGrid.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isGrid).value = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          (isGrid.value)
            ? (_openBlock(), _createBlock(QRGrid, {
                key: 0,
                onOnSelect: onSelectSubProfile,
                onOnDelete: onDeleteSubProfile,
                onOnDownload: onDownloadQR,
                subProfiles: getVisibleSubProfiles()
              }, null, 8, ["subProfiles"]))
            : (_openBlock(), _createBlock(QRList, {
                key: 1,
                onOnSelect: onSelectSubProfile,
                onOnDelete: onDeleteSubProfile,
                onOnDownload: onDownloadQR,
                subProfiles: getVisibleSubProfiles()
              }, null, 8, ["subProfiles"]))
        ])
      ])
    ])
  ]))
}
}

})