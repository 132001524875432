<script setup lang="ts">

import { computed, PropType } from 'vue'
import { ListSubProfile } from '@/api/types'
import { useDashboardStore, useDropdownStore } from '@/store'

import { Palette } from '@/theme'

import { getTruncatedLabel } from '@/utils'

import QrPublishedTag from '@/components/tag/QrPublishedTag.vue'
import QrUnpublishedTag from '@/components/tag/QrUnpublishedTag.vue'
import QrRootTag from '@/components/tag/QrRootTag.vue'
import DropdownQrDownload from '@/components/dropdown/DropdownQrDownload.vue'
import DropdownQrEditDelete from '@/components/dropdown/DropdownQrEditDelete.vue'
import { storeToRefs } from 'pinia'

const dashboardStore = useDashboardStore()
const { getImageUrl } = dashboardStore

const dropdownStore = useDropdownStore()
const { activeDropdown } = storeToRefs(dropdownStore)

const emit = defineEmits(['onDownload', 'onSelect', 'onDelete'])

const { subProfile } = defineProps({
  subProfile: {
    type: Object as PropType<ListSubProfile>,
    required: true
  }
})

const cssVars = computed(() => {
  return {
    '--background-color': Palette.white,
    '--border-color': subProfile.isRootProfile ? Palette.primary : Palette.white,
    '--qr-type-color': subProfile.isRootProfile ? Palette.primary : Palette.white
  }
})

const truncatedLabel = computed(() => {
  return getTruncatedLabel(subProfile.label)
})

const imageUrl = computed(() => {
  return getImageUrl(subProfile.imageUri)
})

function onDownloadPng () {
  emit('onDownload', 'png', subProfile.id)
}

function onDownloadSvg () {
  emit('onDownload', 'svg', subProfile.id)
}

function onSelect () {
  emit('onSelect', subProfile.id)
}

function onDelete () {
  emit('onDelete', subProfile.id)
}

</script>

<template>
  <div class="qr-list-item-container" :style="cssVars" @click="onSelect">
    <!-- Image -->
    <div class="qr-list-item-image-container">
      <img class="qr-list-item-image" :src=imageUrl crossorigin="anonymous">
    </div>
     <!-- Label -->
    <div class="qr-list-item-label-container">
      <p class="qr-list-item-label">{{ truncatedLabel }}</p>
    </div>
    <div class="qr-list-item-tag-container">
      <QrRootTag v-if="subProfile.isRootProfile"/>
    </div>
    <div class="qr-list-item-tag-container">
      <QrPublishedTag v-if="subProfile.active"/>
      <QrUnpublishedTag v-else/>
    </div>
    <div class="qr-grid-item-code-container">
        <img class="qr-grid-item-code" :src="subProfile.qrUri" />
    </div>
      <div class="qr-grid-item-buttons-container">
          <DropdownQrDownload
          :id="'dropdown-qr-download-'+subProfile.id"
            @onClickPng="onDownloadPng"
            @onClickSvg="onDownloadSvg"
          />
          <DropdownQrEditDelete
          :id="'dropdown-qr-edit-delete-'+subProfile.id"
            @onClickEdit="onSelect"
            @onClickDelete="onDelete"
          />
    </div>
  </div>
</template>

<style scoped lang="scss">

  div.qr-list-item-container {
    background-color: var(--background-color);
    border-radius: 16px;
    margin: 16px 0 16px 0;
    padding: 10px;
    height: 100px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  div.qr-list-item-image-container {
    width: 160px;
    height: 100%;
    overflow: hidden;
    display: flex;
  }

  img.qr-list-item-image {
    width: 100%;
    aspect-ratio: 1 / 1;
    align-self: center;
  }

  div.qr-list-item-label-container {
    height: 20px;
    margin: 0 0 0 30px;
    display: flex;
    flex-grow: 1;
  }

  p.qr-list-item-label {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }

  div.qr-list-item-tag-container {
    width: 76px;
    height: 20px;
    margin: 0 18px 0 18px;
  }

  div.qr-grid-item-code-container{
      background-color: black;
      color: white;
      width: 40px;
      height: 40px;
  }
  img.qr-grid-item-code{
    width: 100%;
    height: 100%;
    vertical-align: top;
    line-height: 0;
  }
  div.qr-grid-item-buttons-container {
    display: flex;
    flex-direction: row;
    height: 46px;
    width: 86px;
    margin: 0 30px 0 30px;
  }

</style>
