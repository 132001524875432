<script setup lang="ts">
import { computed, ref, PropType } from 'vue'
import { UserSubProfile } from '@/api/types'

import SubProfileContainerModular from '@/components/profile/SubProfileContainerModular.vue'
import ViewportButton from '@/components/button/ViewportButton.vue'

const emit = defineEmits(['onSelectViewport'])

const { subProfile, viewport, containerStyle } = defineProps({
  subProfile: {
    type: Object as PropType<UserSubProfile | null>,
    required: true
  },
  viewport: {
    type: String,
    required: true
  },
  containerStyle: {
    type: Object,
    default: null
  }
})

const contentActive = ref(false)

const cssVarsContainer = computed(() => {
  if (viewport === 'desktop') {
    return {
      width: 'calc(100vw - 340px)',
      height: '67vh',
      '--left': 'inherit',
      '--top': 'inherit',
      '--bottom': 'inherit',
      ...containerStyle
    }
  } else {
    return {
      height: '67vh',
      aspectRatio: viewport === 'mobile' ? '329 / 709' : '720 / 709',
      '--left': 'inherit',
      '--top': 'inherit',
      '--bottom': 'inherit',
      ...containerStyle
    }
  }
})

function setContentActive (active: boolean) {
  contentActive.value = active
}

function onSelectViewportMobile () {
  emit('onSelectViewport', 'mobile')
}

function onSelectViewportTablet () {
  emit('onSelectViewport', 'tablet')
}

function onSelectViewportDesktop () {
  emit('onSelectViewport', 'desktop')
}
</script>

<template>
    <div class="preview-panel-container" :style="cssVarsContainer">
        <div class="d-flex flex-row align-items-center preview-panel-header-container">
            <img class="preview-icon" :src="require('@/assets/icons/svg/eye.svg')"/>
            <p class="m-0 preview-text">Keypin Preview</p>
            <div class="d-flex flex-fill justify-content-end">
              <ViewportButton :selected="viewport === 'desktop'" icon="desktop" @click="onSelectViewportDesktop"/>
              <ViewportButton :selected="viewport === 'tablet'" icon="tablet" @click="onSelectViewportTablet"/>
              <ViewportButton :selected="viewport === 'mobile'" icon="mobile" @click="onSelectViewportMobile"/>
            </div>
        </div>
        <SubProfileContainerModular
          :subProfile="subProfile"
          :contentActive="contentActive"
          @onDetailClick="(id: string, contentActive: boolean) => setContentActive(contentActive)"
        />
    </div>
</template>

<style scoped>

div.preview-panel-container {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 12px 50px 0px rgba(0, 0, 0, 0.15), 0px -1px 10px 0px rgba(0, 0, 0, 0.10);
  position: fixed;
  right:0;
  left: var(--left);
  top: var(--top);
  bottom: var(--bottom);
}

div.preview-panel-header-container {
  padding: 10px 10px 10px 24px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #FFF;
}

img.preview-icon {
    height: 0.58rem;
    aspect-ratio: 1;
    margin-right: 0.26rem;
}

p.preview-text {
  color: var(--color-nearly-black);
  font-family: Inter;
  font-size: var(--font-size-13);
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  opacity: 0.5;
}

</style>
