<script setup lang="ts">
import { computed } from 'vue'
import DropdownQrListItem from './DropdownQrListItem.vue'

const emit = defineEmits(['onClickPng', 'onClickSvg'])

const { id } = defineProps({
  id: {
    type: String,
    required: true
  }
})

const items = [
  {
    icon: '',
    text: 'PNG',
    subItems: []
  },
  {
    icon: '',
    text: 'SVG',
    subItems: []
  }
]

const cssButton = computed(() => {
  return {
    marginRight: '0.096rem'
  }
})

function onClick (id: number) {
  switch (id) {
    case 0:
      emit('onClickPng')
      break
    case 1:
      emit('onClickSvg')
      break
  }
}
</script>

<template>
    <DropdownQrListItem
      :id="id"
      btn-icon="download"
      :items="items"
      :btn-style="cssButton"
      @onClick="onClick"/>
</template>

<style scoped>
</style>
