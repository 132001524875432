import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_2 = { class: "my-2 align-self-start" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex flex-column features-style-panel-content"
}
const _hoisted_4 = { class: "d-flex features-style-panel-row align-items-center" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "d-flex features-style-panel-row align-items-center" }
const _hoisted_7 = { class: "d-flex" }

import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, FeaturesSectionContent, FeaturesSectionStyle, QrSectionItemType } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorAddLink from '@/components/button/editor-panel/EditorAddLink.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleTextIconButton from '../button/style-panel/StyleTextIconButton.vue'

import { SlickItem, SlickList } from 'vue-slicksort'


export default /*@__PURE__*/_defineComponent({
  __name: 'FeaturesPanel',
  props: {
  featuresSection: {
    type: Object as PropType<QrSection<FeaturesSectionContent, FeaturesSectionStyle>>,
    required: true
  }
},
  emits: ['onToggleMode'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { patchQrSection, postQrSectionItem, patchQrSectionItem, putQrSectionItemOrder, deleteQrSectionItem } = editorStore

const emit = __emit



const styleMode = ref(false)
const drag = ref(false)

const { id, label, active, persistent, section, style } = __props.featuresSection

const styleList = computed(() => {
  return style.layout === 'list'
})

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onCreateItem () {
  postQrSectionItem(id)
}

function onEditItemValue (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'value', value)
}

function onChangeItemOrder (items: QrSectionItemType[]) {
  drag.value = false
  putQrSectionItemOrder(id, items)
}

function onDeleteItem (itemID: number) {
  deleteQrSectionItem(id, itemID)
}

function onEditLayout (value: string) {
  if (value === style.layout) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'layout', value)
}

function onEditFillColor (value: string) {
  if (value === style.fillColor) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'fillColor', value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    onOnToggleMode: onToggleMode
  }, {
    default: _withCtx(() => [
      (!styleMode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_unref(SlickList), {
              axis: "y",
              list: _unref(section).items,
              "onUpdate:list": [
                _cache[0] || (_cache[0] = ($event: any) => ((_unref(section).items) = $event)),
                onChangeItemOrder
              ],
              useDragHandle: true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).items, (item, i) => {
                  return (_openBlock(), _createBlock(_unref(SlickItem), {
                    key: i,
                    index: i
                  }, {
                    default: _withCtx(() => [
                      _createVNode(EditorInputComponent, {
                        deleteable: "",
                        sortable: "",
                        value: item.content.value,
                        onOnChangeValue: (value) => onEditItemValue(item.id, value),
                        onOnDelete: () => onDeleteItem(item.id)
                      }, null, 8, ["value", "onOnChangeValue", "onOnDelete"])
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["list"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(EditorAddLink, { onClick: onCreateItem }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Add item")
                ])),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Layout", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(StyleTextIconButton, {
                  icon: 'style-layout-list',
                  selected: styleList.value,
                  onClick: _cache[1] || (_cache[1] = () => onEditLayout('list'))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("List")
                  ])),
                  _: 1
                }, 8, ["selected"]),
                _createVNode(StyleTextIconButton, {
                  icon: 'style-layout-grid',
                  selected: !styleList.value,
                  onClick: _cache[2] || (_cache[2] = () => onEditLayout('grid'))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Grid")
                  ])),
                  _: 1
                }, 8, ["selected"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Color", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(ColorInput, {
                  label: 'Fill',
                  value: _unref(style).fillColor,
                  onOnChangeColor: onEditFillColor
                }, null, 8, ["value"])
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})