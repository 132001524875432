<script setup lang="ts">
import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, SocialsSectionContent, SocialsSectionStyle, QrSectionItemType } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorAddLink from '@/components/button/editor-panel/EditorAddLink.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'
import EditorNumInputComponent from '@/components/input/EditorNumInputComponent.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import StyleIconButton from '@/components/button/style-panel/StyleIconButton.vue'
import StyleTextButton from '@/components/button/style-panel/StyleTextButton.vue'

import { SOCIAL_TYPE_TO_ICON, SOCIALS_LOGO_THEME_TO_OPTIONS, SOCIALS_LOGO_THEME_FROM_OPTIONS } from '@/constants'

import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'

const editorStore = useEditorStore()
const { patchQrSection, setSelectedQrSectionID, patchQrSectionItem, putQrSectionItemOrder, deleteQrSectionItem } = editorStore

const emit = defineEmits(['onToggleMode', 'onAddSocials'])

const { socialsSection } = defineProps({
  socialsSection: {
    type: Object as PropType<QrSection<SocialsSectionContent, SocialsSectionStyle>>,
    required: true
  }
})

const { id, label, active, persistent, section, style } = socialsSection

const vHandle = HandleDirective

const styleMode = ref(false)
const drag = ref(false)
const logoTheme = ref(SOCIALS_LOGO_THEME_TO_OPTIONS[style.logoTheme].value)
const logoThemeOptions = [
  'Brand Colors',
  'Custom'
]

const cssItemIcon = computed(() => {
  return {
    width: '0.64rem',
    height: '0.64rem'
  }
})

const cssItemIconContainer = computed(() => {
  return {
    backgroundColor: 'var(--color-white)',
    borderRadius: '0.26rem',
    border: '1px solid rgba(33, 37, 41, 0.15)',
    width: '1.28rem',
    height: '1.28rem',
    alignItems: 'center',
    padding: '0.32rem',
    display: 'flex',
    marginRight: '0.32rem'
  }
})

const styleList = computed(() => {
  return style.layout === 'list'
})

function getIcon (type: string): string {
  if (socialsSection) return SOCIAL_TYPE_TO_ICON[type].value
  return SOCIAL_TYPE_TO_ICON.default.value
}

function getLabel (type: string): string {
  const parts = type.split('-')
  return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
}

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onAddSocials () {
  setSelectedQrSectionID(id)
  emit('onAddSocials')
}

function onEditItemValue (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'value', value)
}

function onChangeItemOrder (items: QrSectionItemType[]) {
  drag.value = false
  putQrSectionItemOrder(id, items)
}

function onDeleteItem (itemID: number) {
  deleteQrSectionItem(id, itemID)
}

function onEditLayout (value: string) {
  if (value === style.layout) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'layout', value)
}

function onEditStyle (value: string) {
  if (value === style.style) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'style', value)
}

function onEditCorners (corners: string) {
  if (corners === style.corners) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'corners', corners)
}

function onEditSize (size: string) {
  if (size === style.size) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', size)
}

function onEditAlign (align: string) {
  if (align === style.align) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', align)
}

function onEditLogoTheme (value: string) {
  value = SOCIALS_LOGO_THEME_FROM_OPTIONS[value].value
  if (value === style.logoTheme) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'logoTheme', value)
}

function onEditLogoColor (value: string) {
  if (value === style.logoColor) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'logoColor', value)
}

function onEditBackgroundColor (value: string) {
  if (value === style.backgroundColor) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'backgroundColor', value)
}

function onEditSpacingLeft (value: number) {
  if (value === style.spacingLeft) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingLeft', value)
}

function onEditSpacingTop (value: number) {
  if (value === style.spacingTop) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingTop', value)
}

function onEditSpacingRight (value: number) {
  if (value === style.spacingRight) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingRight', value)
}

function onEditSpacingBottom (value: number) {
  if (value === style.spacingBottom) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingBottom', value)
}

</script>

<template>
    <EditorPanel
      :id="id"
      :title="socialsSection.label"
      :active="socialsSection.active"
      :persistent="socialsSection.persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!styleMode" class="d-flex flex-column">
      <SlickList axis="y" v-model:list="section.items" @update:list="onChangeItemOrder" :useDragHandle="true">
        <SlickItem v-for="(item, i) in section.items" :key="i" :index="i">
          <EditorInputComponent
            deleteable
            sortable
            :label="getLabel(item.content.type)"
            :icon-src="require(`@/assets/${getIcon(item.content.type)}`)"
            :icon-style="cssItemIcon"
            :icon-container-style="cssItemIconContainer"
            :value="item.content.value"
            @onChangeValue="(value: string) => onEditItemValue(item.id, value)"
            @onDelete="() => onDeleteItem(item.id)"
            />
        </SlickItem>
      </SlickList>
        <div class="my-2 align-self-start">
          <EditorAddLink @click="onAddSocials">Add item</EditorAddLink>
        </div>
        </div>
        <div v-else class="d-flex flex-column socials-style-panel-content">
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Layout</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'style-layout-list'" :selected="styleList" @click="() => onEditLayout('list')">List</StyleTextIconButton>
              <StyleTextIconButton :icon="'style-layout-row'" :selected="!styleList" @click="() => onEditLayout('row')">Row</StyleTextIconButton>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Style</p>
            <div class="d-flex align-items-center socials-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="socialsSection.style.style == 'solid'"
                @click="() => onEditStyle('solid')"
                style="width: 94px; border-radius: 0">
                Solid
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.style == 'outline'"
                @click="() => onEditStyle('outline')"
                style="width: 94px; border-radius: 0; background-color: #FFFFFF; box-shadow: 0px 0px 0px 2px rgba(33, 37, 41, 0.20) inset">
                Outline
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.style == 'pill'"
                @click="() => onEditStyle('pill')"
                style="width: 94px; border-radius: 18px">
                Pill
              </StyleTextButton>
            </div>
            <div class="d-flex" style="padding: 0 0 0 2px">
              <StyleTextButton
                :selected="socialsSection.style.corners == 'sharp'"
                @click="() => onEditCorners('sharp')"
                style="width: 108px; border-radius: 0">
                Sharp Corners
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.corners == 'rounded'"
                @click="() => onEditCorners('rounded')"
                style="width: 135px">
                Rounded Corners
              </StyleTextButton>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Size & Align</p>
            <div class="d-flex align-items-center socials-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <StyleTextButton
                :selected="socialsSection.style.size == 'sm'"
                @click="() => onEditSize('sm')"
                style="width: 30px; height: 30px">
                S
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.size == 'md'"
                @click="() => onEditSize('md')"
                style="width: 36px; height: 36px;">
                M
              </StyleTextButton>
              <StyleTextButton
                :selected="socialsSection.style.size == 'lg'"
                @click="() => onEditSize('lg')"
                style="width: 40px;">
                L
              </StyleTextButton>
            </div>
            <div class="d-flex align-items-center" style="padding: 0 10px 0 2px">
              <StyleIconButton :icon="'style-align-left'" :selected="socialsSection.style.align == 'left'" @click="() => onEditAlign('left')"/>
              <StyleIconButton :icon="'style-align-center'" :selected="socialsSection.style.align == 'center'" @click="() => onEditAlign('center')"/>
              <StyleIconButton :icon="'style-align-right'" :selected="socialsSection.style.align == 'right'" @click="() => onEditAlign('right')"/>
              <StyleIconButton :icon="'style-align-fill'" :selected="socialsSection.style.align == 'fill'" @click="() => onEditAlign('fill')"/>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Logo Theme</p>
            <div class="d-flex align-items-center socials-style-panel-row-group-br" style="padding: 0 10px 0 2px">
              <SelectInput
                :options="logoThemeOptions"
                v-model="logoTheme"
                @update:value="onEditLogoTheme"
                />
            </div>
            <div class="d-flex align-items-center" style="padding: 0 0 0 10px">
              <ColorInput :label="'Custom Color'" :value="socialsSection.style.logoColor" @onChangeColor="onEditLogoColor" :disabled="socialsSection.style.logoTheme === 'brand'"/>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Block Colors</p>
            <div class="d-flex">
              <ColorInput :label="'Background'" :value="socialsSection.style.backgroundColor" @onChangeColor="onEditBackgroundColor"/>
            </div>
          </div>
          <div class="d-flex socials-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Block Spacing</p>
            <div class="d-flex ">
              <EditorNumInputComponent label="Left" :value="socialsSection.style.spacingLeft" @onChange="onEditSpacingLeft"/>
              <EditorNumInputComponent label="Top" :value="socialsSection.style.spacingTop" @onChange="onEditSpacingTop" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Right" :value="socialsSection.style.spacingRight" @onChange="onEditSpacingRight" style="margin-left: 10px"/>
              <EditorNumInputComponent label="Bottom" :value="socialsSection.style.spacingBottom" @onChange="onEditSpacingBottom" style="margin-left: 10px"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>

div.socials-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.socials-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.socials-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
