import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "feature-item-text" }

import { computed, ref, PropType } from 'vue'

import { QrSection, FeaturesSectionContent, FeaturesSectionStyle } from '@/api/types'
import { hexToRGBA } from '@/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'FeaturesSection',
  props: {
  featuresSection: {
    type: Object as PropType<QrSection<FeaturesSectionContent, FeaturesSectionStyle>>,
    required: true
  }
},
  setup(__props) {



const { section, style } = __props.featuresSection

const activeContentID = ref('')

const classBody = computed(() => {
  return {
    'features-body': true,
    'features-body-list': style.layout === 'list',
    'features-body-grid': style.layout === 'grid'
  }
})

const classItemContainer = computed(() => {
  return {
    'feature-item-container': true,
    'feature-item-list': style.layout === 'list',
    'feature-item-grid': style.layout === 'grid'
  }
})

const cssVars = computed(() => {
  return {
    '--background-color': hexToRGBA(style.fillColor, 0.15)
  }
})

const cssImg = computed(() => {
  return {
    marginBottom: style.layout === 'list' ? '0' : '10px'
  }
})


return (_ctx: any,_cache: any) => {
  return (__props.featuresSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(classBody.value)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).items, (feature) => {
          return (_openBlock(), _createElementBlock("div", {
            key: feature.id,
            class: _normalizeClass(classItemContainer.value),
            style: _normalizeStyle(cssVars.value)
          }, [
            _createElementVNode("img", {
              class: "tick-img",
              src: require('@/assets/icons/svg/tick-wg.svg'),
              alt: "tick",
              style: _normalizeStyle(cssImg.value)
            }, null, 12, _hoisted_1),
            _createElementVNode("p", _hoisted_2, _toDisplayString(feature.content.value), 1)
          ], 6))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}
}

})