<script setup lang="ts">
import { computed } from 'vue'

import { storeToRefs } from 'pinia'

import { useEditorStore, useOnboardingStore } from '@/store'

import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginButtonSkip from '@/components/button/LoginButtonSkip.vue'
import SelectSocialButton from '@/components/button/SelectSocialButton.vue'

import { SOCIAL_LINKS } from '@/constants'

const editorStore = useEditorStore()
const onboardingStore = useOnboardingStore()

const { selectedSocials } = storeToRefs(editorStore)
const { selectSocial, clearSocials, fetchUserSubProfile } = editorStore

const { setMode } = onboardingStore

const socialTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'social')
})

const streamingTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'streaming')
})

function onSelect (type: string) {
  selectSocial(type)
}

function onContinue () {
  console.log(selectedSocials)
  setMode('add-socials')
}

async function onSkip () {
  clearSocials()
  const status = await fetchUserSubProfile()
  setMode('add-details')
}

</script>

<template>
  <div class="select-socials-container">
    <div class="select-container">
      <SelectSocialButton
        v-for="item in socialTypes"
        :key="item.type"
        :type="item.type"
        :label="item.label"
        :isSelected="selectedSocials.map(i => i.type).includes(item.type)"
        @onSelect="onSelect"
        />
      <SelectSocialButton
        v-for="item in streamingTypes"
        :key="item.type"
        :type="item.type"
        :label="item.label"
        :isSelected="selectedSocials.map(i => i.type).includes(item.type)"
        @onSelect="onSelect"
      />
    </div>
    <div class=form-container>
      <LoginButtonGrad text="Continue" @click="onContinue"/>
      <LoginButtonSkip text="Skip" @click="onSkip"/>
    </div>
  </div>
</template>

<style scoped>

div.select-socials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
div.select-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.38rem;
}

div.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.96rem;
  margin-top: 2.94rem;
  width: 60%;
}
</style>
