import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { computed, PropType } from 'vue'

import { QrSection, TextSectionContent } from '@/api/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'TextSection',
  props: {
  textSection: {
    type: Object as PropType<QrSection<TextSectionContent, null>>,
    required: true
  }
},
  setup(__props) {



const { section } = __props.textSection

const formattedContent = computed(() => section.content.replace(/\n/g, '<br>'))

const cssVars = computed(() => {
  return {
    '--text-color': '#000000'
  }
})

return (_ctx: any,_cache: any) => {
  return (__props.textSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "text-container d-flex flex-row justify-content-center px-3",
        style: _normalizeStyle(cssVars.value)
      }, [
        _createElementVNode("p", {
          class: "content-text",
          innerHTML: formattedContent.value
        }, null, 8, _hoisted_1)
      ], 4))
    : _createCommentVNode("", true)
}
}

})