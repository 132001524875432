<script setup lang="ts">
import { computed, ref, PropType, useTemplateRef, ShallowRef } from 'vue'

import { QrSection, QrSectionTargetEnum, TableSectionContent, TableSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import StyleIconButton from '@/components/button/style-panel/StyleIconButton.vue'
import StyleTextIconButton from '@/components/button/style-panel/StyleTextIconButton.vue'

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = defineEmits(['onToggleMode'])

const { tableSection } = defineProps({
  tableSection: {
    type: Object as PropType<QrSection<TableSectionContent, TableSectionStyle>>,
    required: true
  }
})

const styleMode = ref(false)

const { id, label, active, persistent, section, style } = tableSection

const tableRef = section.value.map(
  (row, ridx) => row.map(
    (cell, cidx) => useTemplateRef('cell-' + ridx.toString() + '-' + cidx.toString())
  )
)

const cssInputContainer = computed(() => ({
  height: '100%'
}))

const cssInputWrapper = computed(() => ({
  'border-radius': '0',
  border: '1px solid rgba(33, 37, 41, 0.15)',
  background: 'transparent',
  focusedBorder: '1px solid var(--bp---Gradient, #2E75FF)',
  focusedBrackground: '#fff',
  height: '100%'
}))

const cssInput = computed(() => ({}))

const styleRow = computed(() => tableSection.style.header === 'row')

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

/* function focusCell (r: number, c: number) {
  tableRef[r][c].value.focus()
} */

function onAddCells (type: string) {
  const newValue = [...section.value]
  if (type === 'row') {
    const newRow: string[] = []
    for (let i = 0; i < section.value[0].length; i++) {
      newRow.push('New Cell')
    }
    newValue.push(newRow)
  } else {
    newValue.forEach((row) => {
      row.push('New Cell')
    })
  }
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'value', newValue)
}

function onRemoveCells (type: string) {
  const newValue = [...section.value]
  if (type === 'row') {
    newValue.pop()
  } else {
    newValue.forEach((row) => {
      row.pop()
    })
  }
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'value', newValue)
}

function onEditCell (value: string, r: number, c: number) {
  const newValue = [...section.value]
  newValue[r][c] = value
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'value', newValue)
  // NOW KEEP TRACK OF R AND C
}

function onEditHeader (value: string) {
  if (value === tableSection.style.header) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'header', value)
}

function onEditBorder (value: number) {
  if (value === tableSection.style.border) return
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'border', value)
}

</script>

<template>
    <EditorPanel
      :id="id"
      :title="label"
      :active="active"
      :persistent="persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
        <div v-if="!styleMode" class="d-flex table-panel-content">
          <div class="table-container">
            <table>
              <tbody>
                <tr v-for="(row, rowIndex) in section.value" :key="rowIndex">
                  <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                    <DebouncedTextInput
                      :ref="'cell-' + rowIndex.toString() + '-' + cellIndex.toString()"
                      :value="cell"
                      type="text"
                      placeholder="title"
                      @update:value="(value: string) => onEditCell(value, rowIndex, cellIndex)"
                      :containerStyle="cssInputContainer"
                      :wrapperStyle="cssInputWrapper"
                      :inputStyle="cssInput"
                      :border-on-focus="true"
                      />
                    </td>
                </tr>
              </tbody>
            </table>
            <div class="add-col-container d-flex">
              <div v-if="section.value.length < 8" class="add-row-col-button" @click="() => onAddCells('row')">
                <img :src="require('@/assets/icons/svg/plus-table.svg')">
              </div>
              <div v-if="section.value.length > 2" class="rem-row-col-button mx-2" @click="() => onRemoveCells('row')">
                <img :src="require('@/assets/icons/svg/minus-table.svg')">
              </div>
          </div>
          </div>
          <div class="add-col-container">
            <div v-if="section.value[0].length < 8" class="add-row-col-button" @click="() => onAddCells('col')">
              <img :src="require('@/assets/icons/svg/plus-table.svg')">
            </div>
            <div v-if="section.value[0].length > 2" class="rem-row-col-button" @click="() => onRemoveCells('col')">
              <img :src="require('@/assets/icons/svg/minus-table.svg')">
            </div>
          </div>
        </div>
        <div v-else class="d-flex flex-column table-style-panel-content">
          <div class="d-flex table-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Header</p>
            <div class="d-flex">
              <StyleTextIconButton :icon="'table-style-row'" :selected="styleRow" @click="() => onEditHeader('row')">First Row</StyleTextIconButton>
              <StyleTextIconButton :icon="'table-style-col'" :selected="!styleRow" @click="() => onEditHeader('col')">First Column</StyleTextIconButton>
            </div>
          </div>
          <div class="d-flex table-style-panel-row align-items-center">
            <p class="style-header d-flex flex-fill">Border</p>
            <div class="d-flex">
              <StyleIconButton v-for="i in 6" :key="(i-1)" :icon="'table-style-border-'+(i-1).toString()" :selected="tableSection.style.border === (i-1)" @click="() => onEditBorder(i-1)"/>
            </div>
          </div>
        </div>
      </EditorPanel>
</template>

<style scoped>

  div.table-panel-content {
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--color-light-grey);
    padding: 10px;
  }

  div.table-container {
    flex: 1;
  }
  div.add-col-container {
    width: 32px;
    padding: 10px;
  }

  div.add-row-col-button, div.rem-row-col-button {
    cursor: pointer;
  }

  table {
      border-collapse: collapse;
      width: 100%;
    }

  div.table-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.table-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
