<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue'

import { QrSection, ButtonSectionContent, ButtonSectionStyle } from '@/api/types'

const { buttonSection } = defineProps({
  buttonSection: {
    type: Object as PropType<QrSection<ButtonSectionContent, ButtonSectionStyle>>,
    required: true
  }
})

const emit = defineEmits(['onClick'])

const { id, label, active, persistent, section, style } = buttonSection

const btnStyleWidth = computed(() => {
  if (style.align === 'fill') {
    return '100%'
  } else if (style.size === 'sm') {
    return '100px'
  } else if (style.size === 'md') {
    return '200px'
  } else {
    return '300px'
  }
})

const btnStyleHeight = computed(() => {
  if (style.size === 'sm') {
    return '30px'
  } else if (style.size === 'md') {
    return '40px'
  } else {
    return '50px'
  }
})

const btnStyleBorderRadius = computed(() => {
  if (style.style === 'pill') {
    return '18px'
  } else if (style.corners === 'rounded') {
    return '12px'
  } else {
    return '0px'
  }
})

const btnStyleContainerMargin = computed(() => {
  if (style.align === 'left') {
    return '0 auto 0 0'
  } else if (style.align === 'right') {
    return '0 0 0 auto'
  } else {
    return '0 0 0 0'
  }
})

const btnStyleBackground = computed(() => {
  if (style.style === 'outline') {
    return 'transparent'
  } else {
    return style.fillColor
  }
})

const btnTextFontSize = computed(() => {
  if (style.size === 'sm') {
    return 'var(--font-size-11)'
  } else if (style.size === 'md') {
    return 'var(--font-size-14)'
  } else {
    return 'var(--font-size-16)'
  }
})

const cssVarsContent = computed(() => {
  return {
    '--height': btnStyleHeight.value,
    '--background': btnStyleBackground.value,
    '--border-radius': btnStyleBorderRadius.value,
    '--border': '2px solid ' + style.fillColor
  }
})

const cssVarsContainer = computed(() => {
  return {
    '--width': btnStyleWidth.value,
    '--container-margin': btnStyleContainerMargin.value
  }
})

const cssVarsText = computed(() => {
  return {
    '--color': style.textColor,
    '--font-size': btnTextFontSize.value
  }
})

function onClick () {
  window.open(section.url, '_blank')
  emit('onClick')
}
</script>

<template>
    <a class="button-container" :style="cssVarsContainer">
      <div v-if="buttonSection" class="button-body" :style="cssVarsContent" @click="onClick">
          <p
              class="button-text"
              :style="cssVarsText"
              >
              {{ section.text }}
          </p>
        </div>
    </a>
</template>

<style scoped lang="scss">

  a.button-container{
    text-decoration: none;
    margin: var(--container-margin);
    width: var(--width);
    cursor: pointer;
    padding: 0.48rem 0;
  }
  div.button-body{
    width: 100%;
    min-height: var(--height);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background);
    border-radius: var(--border-radius);
    border: var(--border);
    padding: 0.18rem;
  }
  p.button-text{
    text-align: center;
    font-family: Inter;
    font-size: var(--font-size);
    font-weight: 500;
    color: var(--color);
    margin: 0;
  }
</style>
