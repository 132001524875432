import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewportButton',
  props: {
  selected: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    required: true
  }
},
  setup(__props) {



const cssVars = computed(() => {
  return {
    '--background': __props.selected ? 'linear-gradient(116deg, rgba(63, 128, 255, 0.1) 16.27%, rgba(123, 61, 255, 0.1) 83.73%)' : '#FFFFFF'
  }
})

const imgSrc = computed(() => {
  return require('@/assets/icons/svg/viewport-' + (__props.selected ? __props.icon + '-grad' : __props.icon) + '.svg')
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "viewport-button-wrapper",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("img", {
      src: imgSrc.value,
      alt: "icon"
    }, null, 8, _hoisted_1)
  ], 4))
}
}

})