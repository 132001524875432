<template>
    <div class="editor-input-container" :style="cssVarsContainer">
        <div v-if="this.iconSrc" :style="iconContainerStyle">
          <img class="editor-input-icon" :src="this.iconSrc" :style="iconStyle"/>
        </div>
        <p v-if="this.label" class="editor-input-label" :style="cssLabel">{{ this.label }}</p>
        <DebouncedTextInput
            :value="this.internalValue"
            type="text"
            :multiline="this.multiline"
            :input-style="cssInput"
            @update:value="onChangeValue"
            :container-style="{marginRight: '0.32rem'}"
        />
        <img v-if="this.deleteable" class="delete-button-image" src="@/assets/icons/svg/editor-xmark.svg" @click="onDelete"/>
        <SwitchComponent v-if="this.toggleable" :initialValue="isActive" :v-model="isActive" @update:modelValue="onSetActive"/>
        <span v-if="this.sortable" v-handle class="handle grip-handle"><a class="grip-icon" href="#"><img class="grip-icon-image" :src="require('@/assets/icons/icon-grip-1.png')"></a></span>
        <slot/>
    </div>
  </template>

<script lang="ts">

import { defineComponent } from 'vue'
import DebouncedTextInput from './DebouncedTextInput.vue'
import { HandleDirective } from 'vue-slicksort'
import SwitchComponent from '@/components/switch/Switch.vue'

export default defineComponent({
  name: 'EditorInputComponent',
  components: { DebouncedTextInput, SwitchComponent },
  props: {
    iconSrc: {
      type: String,
      default: ''
    },
    iconStyle: {
      type: Object,
      default: null
    },
    iconContainerStyle: {
      type: Object,
      default: null
    },
    boldLabel: {
      type: Boolean,
      default: false
    },
    deleteable: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false
    },
    toggleable: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: false
    },
    inputComponentStyle: {
      type: Object,
      default: null
    },
    style: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: 'some text'
    }
  },
  data () {
    return {
      internalValue: this.value,
      valid: true,
      isActive: this.active
    }
  },
  directives: { handle: HandleDirective },
  computed: {
    cssVarsContainer () {
      return {
        ...this.style
      }
    },
    cssLabel () {
      return {
        opacity: this.boldLabel ? 1 : 0.5
      }
    },
    cssInput () {
      return {
        ...this.inputComponentStyle
      }
    }
  },
  watch: {
    value (value) {
      this.internalValue = value
    }
  },
  methods: {
    onChangeValue (value: string) {
      this.valid = value.length > 0
      if (this.valid) {
        this.$emit('onChangeValue', value)
      }
    },
    onDelete () {
      this.$emit('onDelete')
    },
    onSetActive (value: boolean) {
      this.$emit('onSetActive', value)
    }
  }
})

</script>

<style scoped>
  .editor-input-container {
    width: 100%;
    min-height: 60px;
    border-radius: 8px;
    background: var(--color-light-grey);
    margin: 10px 0 0 0;
    padding: 0 10px 0 20px;
    display: flex;
    align-items: center;
  }
  .editor-input-label {
    text-align: left;
    width: 5.44rem;
    margin: 0;
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  img.delete-button-image {
    width: 0.78rem;
    height: 0.78rem;
    cursor: pointer;
    margin-left: 0.32rem;
  }
  a.grip-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img.grip-icon-image {
    height: 0.54rem;
    width: 0.32rem;
  }
  span.grip-handle {
    height: 0.54rem;
    width: 0.32rem;
    margin: 0 0.64rem 0 0.576rem;
  }
</style>
