import { ref } from 'vue'
import { defineStore } from 'pinia'

import { api, QrSectionItemType, QrSectionTargetEnum, QrSectionType, QrSectionTypeEnum, UserSubProfile } from '@/api'
import { getSortedSubProfile } from '@/utils'

export const useEditorStore = defineStore('editor', () => {
  const selectedSocials = ref<{type: string, value: string}[]>([])
  const selectedQrSectionID = ref(-1)

  const subProfileID = ref('')
  const subProfile = ref<UserSubProfile>(null as unknown as UserSubProfile)
  const tempSections = ref<QrSectionType[]>([])

  function setSelectedQrSectionID (value: number) {
    selectedQrSectionID.value = value
  }

  function setSubProfileID (value: string) {
    subProfileID.value = value
  }

  function onChangeSectionOrderStart (event: MouseEvent, node: HTMLElement, index: number) {
    tempSections.value = subProfile.value.sections
  }

  function resetSubProfile () {
    subProfileID.value = ''
    subProfile.value = null as unknown as UserSubProfile
  }

  /**
   * SOCIAL LINKS
   */

  function selectSocial (type: string) {
    if (selectedSocials.value.find(s => s.type === type)) {
      selectedSocials.value = selectedSocials.value.filter(social => social.type !== type)
    } else {
      selectedSocials.value.push({ type, value: '' })
    }
  }

  function editSelectedSocialByIndex (index: number, value: string) {
    selectedSocials.value[index].value = value
  }

  function clearSocials () {
    selectedSocials.value = []
  }

  async function submitSocials (): Promise<string> {
    if (selectedQrSectionID.value === -1) {
      let status = await this.postQrSection(QrSectionTypeEnum.SOCIALS)
      status = await this.fetchUserSubProfile()
      selectedQrSectionID.value = subProfile.value.sections[subProfile.value.sections.length - 1].id
    }
    const status = await this.postQrSectionItem(selectedQrSectionID.value, selectedSocials.value)
    clearSocials()
    selectedQrSectionID.value = -1
    return status
  }

  /**
   * API - DASHBOARD
   */
  async function fetchUserSubProfile (id = subProfileID.value): Promise<string> {
    const response = await api.fetchUserSubProfile(id)
    if (response.kind !== 'ok') {
      console.log(`Error fetching dashboard: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    subProfile.value = getSortedSubProfile(response.subProfile)
    subProfileID.value = id

    return response.kind
  }

  async function toggleSubProfileActive (): Promise<string> {
    if (subProfile.value === null) return 'error'

    const response = await api.toggleSubProfileActive(subProfileID.value)
    if (response.kind !== 'ok') {
      console.log(`Error toggling subprofile active: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { newActiveValue } = response

    subProfile.value.active = newActiveValue
    return response.kind
  }

  /**
   * API - EDITOR
   */
  async function postQrSection (qrSectionType: number): Promise<string> {
    const response = await api.postQrSection(subProfileID.value, qrSectionType)

    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    if (!subProfile.value) return response.kind

    const { newQrSection } = response
    subProfile?.value.sections.push(newQrSection)
    return response.kind
  }

  async function postQrSectionItem (qrSectionID: number, items = []): Promise<string> {
    const response = await api.postQrSectionItem(subProfileID.value, qrSectionID, items)

    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { newQrSectionItemsIterable } = response
    const newQrSectionItems = newQrSectionItemsIterable.items

    if (!subProfile.value) return response.kind

    const qrSection = subProfile.value.sections.find(section => section.id === qrSectionID)!
    if ('items' in qrSection?.section) {
      const items = qrSection?.section.items as typeof newQrSectionItems
      items.push(...newQrSectionItems)
    }

    return response.kind
  }

  async function patchQrSection (qrSectionID: number, target: string, property: string, value: any): Promise<string> {
    const response = await api.patchQrSection(subProfileID.value, qrSectionID, target, property, value)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValues } = response

    let qrSectionTarget
    if (target === QrSectionTargetEnum.CONTENT) {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.section
    } else if (target === QrSectionTargetEnum.STYLE) {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.style
    } else {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!
    }

    for (const key in updatedValues) {
      if (updatedValues[key] !== null) qrSectionTarget[key] = updatedValues[key]
    }

    return response.kind
  }

  async function duplicateQrSection (qrSectionID: number): Promise<string> {
    const response = await api.duplicateQrSection(subProfileID.value, qrSectionID)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { newQrSection } = response
    const prevListOrder = subProfile.value.sections.find(section => section.id === qrSectionID)!.listOrder
    subProfile.value.sections = [
      ...subProfile.value.sections.slice(0, prevListOrder),
      newQrSection,
      ...subProfile.value.sections.slice(prevListOrder)
    ]

    return response.kind
  }

  async function patchQrSectionItem (qrSectionID: number, qrSectionItemID: number, property: string, value: any): Promise<string> {
    const response = await api.patchQrSectionItem(subProfileID.value, qrSectionID, qrSectionItemID, property, value)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValues } = response

    const qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.section
    if (!('items' in qrSectionTarget)) return response.kind
    const qrSectionItemTarget = (qrSectionTarget.items as QrSectionItemType[]).find(item => item.id === qrSectionItemID)!.content

    for (const key in updatedValues) {
      if (updatedValues[key] !== null) qrSectionItemTarget[key] = updatedValues[key]
    }

    return response.kind
  }

  async function putQrSectionItemOrder (qrSectionID: number, items: QrSectionItemType[]): Promise<string> {
    const itemsIndexes = items.map(item => item.id)
    const response = await api.putQrSectionItemOrder(subProfileID.value, qrSectionID, itemsIndexes)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    return response.kind
  }

  async function putQrSectionOrder (sections: QrSectionType[]) {
    const sectionsIndexes = sections.map(section => section.id)
    const response = await api.putQrSectionOrder(subProfileID.value, sectionsIndexes)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    subProfile.value.sections = sectionsIndexes.map(index => sections.find(section => section.id === index)!)

    return response.kind
  }

  async function deleteQrSectionItem (qrSectionID, qrSectionItemID): Promise<string> {
    const response = await api.deleteQrSectionItem(subProfileID.value, qrSectionID, qrSectionItemID)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const qrSection = subProfile.value.sections.find(section => section.id === qrSectionID)!
    if ('items' in qrSection.section) {
      const items = qrSection.section.items
      const index = items.findIndex(item => item.id === qrSectionItemID)
      items.splice(index, 1)
    }

    return response.kind
  }

  async function deleteQrSection (qrSectionID: number): Promise<string> {
    const response = await api.deleteQrSection(subProfileID.value, qrSectionID)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const index = subProfile.value.sections.findIndex(section => section.id === qrSectionID)
    subProfile.value.sections.splice(index, 1)

    return response.kind
  }

  async function postQrSectionImage (qrSectionID: number, target: string, property: string, file: File): Promise<string> {
    const response = await api.postQrSectionImage(subProfileID.value, qrSectionID, target, property, file)

    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValues } = response

    let qrSectionTarget
    if (target === QrSectionTargetEnum.CONTENT) {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.section
    } else if (target === QrSectionTargetEnum.STYLE) {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.style
    } else {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!
    }

    for (const key in updatedValues) {
      if (updatedValues[key] !== null) qrSectionTarget[key] = updatedValues[key]
    }

    return response.kind
  }

  async function postQrSectionItemImage (qrSectionID: number, qrSectionItemID: number, property: string, file: File): Promise<string> {
    const response = await api.postQrSectionItemImage(subProfileID.value, qrSectionID, qrSectionItemID, property, file)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValues } = response

    const qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.section
    if (!('items' in qrSectionTarget)) return response.kind
    const qrSectionItemTarget = (qrSectionTarget.items as QrSectionItemType[]).find(item => item.id === qrSectionItemID)!.content

    for (const key in updatedValues) {
      if (updatedValues[key] !== null) qrSectionItemTarget[key] = updatedValues[key]
    }

    return response.kind
  }

  async function resetQrSectionImage (qrSectionID: number, target: string, property: string): Promise<string> {
    const response = await api.resetQrSectionImage(subProfileID.value, qrSectionID, target, property)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValues } = response

    let qrSectionTarget
    if (target === QrSectionTargetEnum.CONTENT) {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.section
    } else if (target === QrSectionTargetEnum.STYLE) {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.style
    } else {
      qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!
    }

    for (const key in updatedValues) {
      if (updatedValues[key] !== null) qrSectionTarget[key] = updatedValues[key]
    }

    return response.kind
  }

  async function resetQrSectionItemImage (qrSectionID: number, qrSectionItemID: number, property: string): Promise<string> {
    const response = await api.resetQrSectionItemImage(subProfileID.value, qrSectionID, qrSectionItemID, property)
    if (response.kind !== 'ok') {
      console.log(`Error editing qr section: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValues } = response

    const qrSectionTarget = subProfile.value!.sections.find(section => section.id === qrSectionID)!.section
    if (!('items' in qrSectionTarget)) return response.kind
    const qrSectionItemTarget = (qrSectionTarget.items as QrSectionItemType[]).find(item => item.id === qrSectionItemID)!.content

    for (const key in updatedValues) {
      if (updatedValues[key] !== null) qrSectionItemTarget[key] = updatedValues[key]
    }

    return response.kind
  }

  /**
   * EDITOR REDIRECT
   */
  async function editRedirectUrl (url: string): Promise<string> {
    const response = await api.patchRedirectURL(subProfileID.value, url)
    if (response.kind !== 'ok') {
      console.log(`Error editing redirect url: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValue } = response

    if (subProfile.value) subProfile.value.qrRedirect.url = updatedValue
    return response.kind
  }

  async function editRedirectActive (active: boolean): Promise<string> {
    const response = await api.patchRedirectStatus(subProfileID.value, active)
    if (response.kind !== 'ok') {
      console.log(`Error editing redirect active: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValue } = response

    if (subProfile.value) subProfile.value.qrRedirect.active = updatedValue
    return response.kind
  }

  async function resetRedirectUrl (): Promise<string> {
    const response = await api.resetRedirectUrl(subProfileID.value)
    if (response.kind !== 'ok') {
      console.log(`Error resetting redirect url: ${JSON.stringify(response)}`, [])
      return response.kind
    }

    const { updatedValue } = response

    if (subProfile.value) subProfile.value.qrRedirect.url = updatedValue
    return response.kind
  }

  /**
   * API - IMAGE
   */
  function getImageUrl (uri: string) : string {
    return api.getImageUrl(uri)
  }

  /**
   * API - QR URL
   */
  function getQrUrl () : string {
    return api.getQrUrl(subProfileID.value)
  }

  return {
    selectedQrSectionID,
    subProfileID,
    subProfile,
    setSelectedQrSectionID,
    setSubProfileID,
    resetSubProfile,
    onChangeSectionOrderStart,
    selectedSocials,
    selectSocial,
    editSelectedSocialByIndex,
    clearSocials,
    submitSocials,
    fetchUserSubProfile,
    toggleSubProfileActive,
    postQrSection,
    postQrSectionItem,
    patchQrSection,
    duplicateQrSection,
    patchQrSectionItem,
    putQrSectionItemOrder,
    putQrSectionOrder,
    deleteQrSectionItem,
    deleteQrSection,
    postQrSectionImage,
    postQrSectionItemImage,
    resetQrSectionImage,
    resetQrSectionItemImage,
    editRedirectUrl,
    editRedirectActive,
    resetRedirectUrl,
    getImageUrl,
    getQrUrl
  }
})
