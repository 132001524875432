<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useSubProfileStore } from '@/store'

const route = useRoute()
const router = useRouter()

const subProfileStore = useSubProfileStore()
const { subProfile } = storeToRefs(subProfileStore)
const { fetchFromQr } = subProfileStore

onMounted(async () => {
  const status = await fetchFromQr(String(route.params.id))
  if (status !== 'ok') {
    return
  }
  if (subProfile.value?.qrRedirect.active) window.location.href = subProfile.value.qrRedirect.url
  router.push({
    name: 'SubProfile',
    params: {
      name: subProfile.value?.name,
      suffix: subProfile.value?.id.slice(-2)
    }
  })
})
</script>

<template>
  <head>
</head>

</template>
