import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-form-container" }

import { useEditorStore, useOnboardingStore } from '@/store'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginInput from '@/components/input/LoginInput.vue'
import PACombo from './PACombo.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterForm',
  emits: ['onNavigateLogin'],
  setup(__props, { emit: __emit }) {

const router = useRouter()

const onboardingStore = useOnboardingStore()

const {
  email,
  password,
  confirmPassword,
  regCode
} = storeToRefs(onboardingStore)

const {
  setEmail,
  setPassword,
  setConfirmPassword,
  setRegCode,
  emailValidationError,
  passwordValidationError,
  confirmPasswordValidationError,
  regCodeValidationError,
  register
} = onboardingStore

const emit = __emit

const cssInputContainer = {
  width: '100%',
  margin: '0.48rem 0'
}

async function onRegister () {
  const status = await register()
  router.push('/register')
}

function onNavigateLogin () {
  emit('onNavigateLogin')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LoginInput, {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Email",
      "model-value": _unref(email),
      "onUpdate:modelValue": _unref(setEmail),
      onKeydown: _withKeys(onRegister, ["enter"]),
      "container-style": cssInputContainer
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(LoginInput, {
      name: "code",
      label: "Beta-testing Code",
      type: "text",
      placeholder: "Beta-testing Code",
      "model-value": _unref(regCode),
      "onUpdate:modelValue": _unref(setRegCode),
      onKeydown: _withKeys(onRegister, ["enter"]),
      "container-style": cssInputContainer
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(LoginInput, {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Password",
      "model-value": _unref(password),
      "onUpdate:modelValue": _unref(setPassword),
      onKeydown: _withKeys(onRegister, ["enter"]),
      "container-style": cssInputContainer
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(LoginInput, {
      name: "confirmPassword",
      label: "Confirm password",
      type: "password",
      placeholder: "Confirm password",
      "model-value": _unref(confirmPassword),
      "onUpdate:modelValue": _unref(setConfirmPassword),
      onKeydown: _withKeys(onRegister, ["enter"]),
      "container-style": cssInputContainer
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(LoginButtonGrad, {
      text: "Continue",
      onClick: onRegister
    }),
    _createVNode(PACombo, {
      onOnClick: onNavigateLogin,
      text: "Already signed up?",
      "link-text": "Login instead"
    })
  ]))
}
}

})