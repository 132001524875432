import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo-grad-text.png'


const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "form-container" }
const _hoisted_3 = { class: "header-text" }
const _hoisted_4 = { class: "form-content" }

import { computed } from 'vue'

import { useOnboardingStore } from '@/store'

import { storeToRefs } from 'pinia'

import AddDetailsForm from '@/components/form/AddDetailsForm.vue'
import AddSocialsForm from '@/components/form/AddSocialsForm.vue'
import SelectSocialsForm from '@/components/form/SelectSocialsForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterDetailsView',
  setup(__props) {

const onboardingStore = useOnboardingStore()
const { mode } = storeToRefs(onboardingStore)

const headingText = computed(() => {
  switch (mode.value) {
    case 'select-socials': return 'Which platforms are you on?'
    case 'add-socials': return 'Add your links'
    case 'add-details': return 'Add Details'
  }
  return ''
})

const subHeadingText = computed(() => {
  switch (mode.value) {
    case 'select-socials': return 'Get started with your links'
    case 'add-socials': return 'Complete the fields below to quick add your links on pages.'
    case 'add-details': return 'Add page Title and Description / Bio'
  }
  return ''
})

const cssSubHeading = computed(() => {
  return {
    margin: mode.value === 'add-details' ? '0.38rem 0 0.48rem 0' : '5.2rem 0 0.48rem 0'
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "header-image",
        src: _imports_0
      }, null, -1)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(headingText.value), 1),
      _createElementVNode("p", {
        class: "header-sub-text",
        style: _normalizeStyle(cssSubHeading.value)
      }, _toDisplayString(subHeadingText.value), 5),
      _createElementVNode("div", _hoisted_4, [
        (_unref(mode) === 'select-socials')
          ? (_openBlock(), _createBlock(SelectSocialsForm, { key: 0 }))
          : (_unref(mode) === 'add-socials')
            ? (_openBlock(), _createBlock(AddSocialsForm, { key: 1 }))
            : (_unref(mode) === 'add-details')
              ? (_openBlock(), _createBlock(AddDetailsForm, { key: 2 }))
              : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})