export const SOCIAL_TYPE_TO_ICON: {[type: string] : { value: string }} = {
  'amazon-music': { value: 'icons/socials/icon-amazon-music.png' },
  'apple-music': { value: 'icons/socials/icon-apple-music.png' },
  'apple-podcasts': { value: 'icons/socials/icon-apple-podcasts.png' },
  deezer: { value: 'icons/socials/icon-deezer.png' },
  'google-podcasts': { value: 'icons/socials/icon-google-podcasts.png' },
  overcast: { value: 'icons/socials/icon-overcast.png' },
  rss: { value: 'icons/socials/icon-rss.png' },
  soundcloud: { value: 'icons/socials/icon-soundcloud.png' },
  spotify: { value: 'icons/socials/icon-spotify.png' },
  twitch: { value: 'icons/socials/icon-twitch.png' },
  'youtube-music': { value: 'icons/socials/icon-youtube-music.png' },
  youtube: { value: 'icons/socials/icon-youtube.png' },
  email: { value: 'icons/socials/icon-email.png' },
  discord: { value: 'icons/socials/icon-discord.png' },
  facebook: { value: 'icons/socials/icon-facebook.png' },
  instagram: { value: 'icons/socials/icon-instagram.png' },
  linkedin: { value: 'icons/socials/icon-facebook.png' },
  medium: { value: 'icons/socials/icon-medium.png' },
  pinterest: { value: 'icons/socials/icon-pinterest.png' },
  reddit: { value: 'icons/socials/icon-reddit.png' },
  snapchat: { value: 'icons/socials/icon-snapchat.png' },
  tiktok: { value: 'icons/socials/icon-tiktok.png' },
  twitter: { value: 'icons/socials/icon-twitter.png' },
  website: { value: 'icons/socials/icon-website.png' },
  default: { value: 'icons/main/icon.png' }
}
export const SOCIALS_LOGO_THEME_TO_OPTIONS: {[theme: string] : { value: string }} = {
  brand: { value: 'Brand Colors' },
  custom: { value: 'Custom' }
}
export const SOCIALS_LOGO_THEME_FROM_OPTIONS: {[theme: string] : { value: string }} = {
  'Brand Colors': { value: 'brand' },
  Custom: { value: 'custom' }
}

export const SOCIAL_LINKS = [
  {
    type: 'website',
    label: 'Website',
    category: 'social'
  },
  {
    type: 'email',
    label: 'Email',
    category: 'social'
  },
  {
    type: 'facebook',
    label: 'Facebook',
    category: 'social'
  },
  {
    type: 'instagram',
    label: 'Instagram',
    category: 'social'
  },
  {
    type: 'linkedin',
    label: 'LinkedIn',
    category: 'social'
  },
  {
    type: 'medium',
    label: 'Medium',
    category: 'social'
  },
  {
    type: 'pinterest',
    label: 'Pinterest',
    category: 'social'
  },
  {
    type: 'reddit',
    label: 'Reddit',
    category: 'social'
  },
  {
    type: 'twitter',
    label: 'Twitter',
    category: 'social'
  },
  {
    type: 'discord',
    label: 'Discord',
    category: 'social'
  },
  {
    type: 'tiktok',
    label: 'TikTok',
    category: 'social'
  },
  {
    type: 'snapchat',
    label: 'Snapchat',
    category: 'social'
  },
  {
    type: 'amazon-music',
    label: 'Amazon Music',
    category: 'streaming'
  },
  {
    type: 'apple-music',
    label: 'Apple Music',
    category: 'streaming'
  },
  {
    type: 'deezer',
    label: 'Deezer',
    category: 'streaming'
  },
  {
    type: 'soundcloud',
    label: 'Soundcloud',
    category: 'streaming'
  },
  {
    type: 'spotify',
    label: 'Spotify',
    category: 'streaming'
  },
  {
    type: 'youtube-music',
    label: 'YouTube Music',
    category: 'streaming'
  },
  {
    type: 'youtube',
    label: 'Youtube',
    category: 'streaming'
  },
  {
    type: 'apple-podcasts',
    label: 'Apple Podcasts',
    category: 'streaming'
  },
  {
    type: 'google-podcasts',
    label: 'Google Podcasts',
    category: 'streaming'
  },
  {
    type: 'twitch',
    label: 'Twitch',
    category: 'streaming'
  },
  {
    type: 'overcast',
    label: 'Overcast',
    category: 'streaming'
  },
  {
    type: 'rss',
    label: 'RSS',
    category: 'streaming'
  }
]
