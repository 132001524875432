import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icons/svg/editor-xmark.svg'


const _hoisted_1 = { class: "news-input-container" }
const _hoisted_2 = { class: "news-input-content" }
const _hoisted_3 = { class: "news-input-row" }
const _hoisted_4 = { class: "news-input-row" }
const _hoisted_5 = { class: "news-input-row" }
const _hoisted_6 = { class: "handle grip-handle" }
const _hoisted_7 = {
  class: "grip-icon",
  href: "#"
}
const _hoisted_8 = ["src"]

import { computed } from 'vue'
import DebouncedTextInput from './DebouncedTextInput.vue'
import { HandleDirective } from 'vue-slicksort'
import ImageInput from './ImageInput.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsInputComponent',
  props: {
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  url: {
    type: String,
    default: ''
  },
  imageUri: {
    type: String,
    default: ''
  }
},
  emits: ['update:title', 'update:subtitle', 'update:url', 'update:image', 'delete'],
  setup(__props, { emit: __emit }) {


const emit = __emit



const vHandle = HandleDirective

const cssImageInput = computed(() => {
  return {
    width: '4.096rem',
    height: '4.096rem'
  }
})

function onSetTitle (value: string) {
  emit('update:title', value)
}

function onSetSubtitle (value: string) {
  emit('update:subtitle', value)
}

function onSetUrl (value: string) {
  emit('update:url', value)
}

function onSelectImage (file: File) {
  emit('update:image', file)
}

function onDelete () {
  emit('delete')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ImageInput, {
      uri: __props.imageUri,
      style: _normalizeStyle(cssImageInput.value),
      onOnSelect: onSelectImage
    }, null, 8, ["uri", "style"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "editor-input-label" }, "Item title", -1)),
        _createVNode(DebouncedTextInput, {
          value: __props.title,
          type: "text",
          "onUpdate:value": onSetTitle
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "editor-input-label" }, "Sub-title", -1)),
        _createVNode(DebouncedTextInput, {
          value: __props.subTitle,
          type: "text",
          "onUpdate:value": onSetSubtitle
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "editor-input-label" }, "URL", -1)),
        _createVNode(DebouncedTextInput, {
          value: __props.url,
          type: "text",
          "onUpdate:value": onSetUrl
        }, null, 8, ["value"])
      ])
    ]),
    _createElementVNode("img", {
      class: "delete-button-image",
      src: _imports_0,
      onClick: onDelete
    }),
    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_6, [
      _createElementVNode("a", _hoisted_7, [
        _createElementVNode("img", {
          class: "grip-icon-image",
          src: require('@/assets/icons/icon-grip-1.png')
        }, null, 8, _hoisted_8)
      ])
    ])), [
      [_unref(vHandle)]
    ])
  ]))
}
}

})