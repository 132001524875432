import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'PACombo',
  props: {
  text: {
    type: String,
    required: true
  },
  linkText: {
    type: String,
    required: true
  }
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {




const emit = __emit

function onClick () {
  emit('onClick')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", null, _toDisplayString(__props.text), 1),
    _createElementVNode("a", {
      href: "#",
      onClick: onClick
    }, _toDisplayString(__props.linkText), 1)
  ]))
}
}

})