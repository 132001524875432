import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-form-container" }

import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useEditorStore, useOnboardingStore } from '@/store'
import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginInput from '@/components/input/LoginInput.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterRootForm',
  setup(__props) {

const router = useRouter()

const onboardingStore = useOnboardingStore()
const editorStore = useEditorStore()

const { rootName } = storeToRefs(onboardingStore)
const { setMode, setRootName, registerRoot } = onboardingStore

const { subProfileID } = storeToRefs(editorStore)

const cssInputContainer = {
  width: '100%',
  margin: '0.48rem 0'
}

async function onRegisterRoot () {
  const status = await registerRoot()
  setMode('select-socials')
  router.push('/welcome')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LoginInput, {
      name: "rootName",
      label: "Root URL",
      type: "rootName",
      placeholder: "RootName",
      "model-value": _unref(rootName),
      "onUpdate:modelValue": _unref(setRootName),
      onKeydown: _withKeys(onRegisterRoot, ["enter"]),
      "container-style": cssInputContainer
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(LoginButtonGrad, {
      text: "Continue",
      onClick: onRegisterRoot
    })
  ]))
}
}

})