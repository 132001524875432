import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-column link-style-panel-content"
}
const _hoisted_3 = { class: "d-flex link-style-panel-row align-items-center" }
const _hoisted_4 = {
  class: "d-flex align-items-center link-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_5 = {
  class: "d-flex align-items-center link-style-panel-row-group-br",
  style: {"padding":"0 10px 0 2px"}
}
const _hoisted_6 = {
  class: "d-flex",
  style: {"padding":"0 0 0 10px"}
}
const _hoisted_7 = { class: "d-flex link-style-panel-row align-items-center" }
const _hoisted_8 = {
  class: "d-flex align-items-center link-style-panel-row-group-br",
  style: {"padding":"0 10px 0 0"}
}
const _hoisted_9 = {
  class: "d-flex",
  style: {"padding":"0 0 0 10px"}
}
const _hoisted_10 = { class: "d-flex link-style-panel-row align-items-center" }
const _hoisted_11 = { class: "d-flex" }

import { ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, LinkSectionContent, LinkSectionStyle } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorNumInputComponent from '@/components/input/EditorNumInputComponent.vue'
import EditorNumSelectComponent from '@/components/input/EditorNumSelectComponent.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LinkPanel',
  props: {
  linkSection: {
    type: Object as PropType<QrSection<LinkSectionContent, LinkSectionStyle>>,
    required: true
  }
},
  emits: ['onToggleMode'],
  setup(__props, { emit: __emit }) {

const editorStore = useEditorStore()
const { patchQrSection } = editorStore

const emit = __emit



const styleMode = ref(false)

const { id, label, active, persistent, section, style } = __props.linkSection

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onEditText (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'text', value)
}

function onEditUrl (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'url', value)
}

function onEditAlign (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', value)
}

function onEditBold (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'bold', value)
}

function onEditItalic (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'italic', value)
}

function onEditUnderline (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'underline', value)
}

function onEditSize (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', value)
}

function onEditTextColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', value)
}

function onEditBackgroundColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'backgroundColor', value)
}

function onEditSpacingLeft (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingLeft', value)
}

function onEditSpacingTop (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingTop', value)
}

function onEditSpacingRight (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingRight', value)
}

function onEditSpacingBottom (value: number) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'spacingBottom', value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    onOnToggleMode: onToggleMode
  }, {
    default: _withCtx(() => [
      (!styleMode.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(EditorInputComponent, {
              label: "Link Text",
              value: _unref(section).text,
              onOnChangeValue: onEditText
            }, null, 8, ["value"]),
            _createVNode(EditorInputComponent, {
              label: "URL",
              value: _unref(section).url,
              onOnChangeValue: onEditUrl
            }, null, 8, ["value"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Format", -1)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(StyleIconButton, {
                  icon: 'style-align-left',
                  selected: _unref(style).align === 'left',
                  onClick: _cache[0] || (_cache[0] = () => onEditAlign('left'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-center',
                  selected: _unref(style).align === 'center',
                  onClick: _cache[1] || (_cache[1] = () => onEditAlign('center'))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'style-align-right',
                  selected: _unref(style).align === 'right',
                  onClick: _cache[2] || (_cache[2] = () => onEditAlign('right'))
                }, null, 8, ["selected"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(StyleIconButton, {
                  icon: 'text-bold',
                  selected: _unref(style).bold,
                  onClick: _cache[3] || (_cache[3] = () => onEditBold(!_unref(style).bold))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'text-italic',
                  selected: _unref(style).italic,
                  onClick: _cache[4] || (_cache[4] = () => onEditItalic(!_unref(style).italic))
                }, null, 8, ["selected"]),
                _createVNode(StyleIconButton, {
                  icon: 'text-underline',
                  selected: _unref(style).underline,
                  onClick: _cache[5] || (_cache[5] = () => onEditUnderline(!_unref(style).underline))
                }, null, 8, ["selected"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(EditorNumSelectComponent, {
                  label: "Size",
                  value: _unref(style).size,
                  min: 8,
                  max: 24,
                  onOnChange: onEditSize
                }, null, 8, ["value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Color", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(ColorInput, {
                  label: 'Text',
                  value: _unref(style).textColor,
                  onOnChangeColor: onEditTextColor
                }, null, 8, ["value"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(ColorInput, {
                  label: 'Background',
                  value: _unref(style).backgroundColor,
                  onOnChangeColor: onEditBackgroundColor
                }, null, 8, ["value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "style-header d-flex flex-fill" }, "Spacing", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(EditorNumInputComponent, {
                  label: "Left",
                  value: _unref(style).spacingLeft,
                  onOnChange: onEditSpacingLeft
                }, null, 8, ["value"]),
                _createVNode(EditorNumInputComponent, {
                  label: "Top",
                  value: _unref(style).spacingTop,
                  onOnChange: onEditSpacingTop,
                  style: {"margin-left":"10px"}
                }, null, 8, ["value"]),
                _createVNode(EditorNumInputComponent, {
                  label: "Right",
                  value: _unref(style).spacingRight,
                  onOnChange: onEditSpacingRight,
                  style: {"margin-left":"10px"}
                }, null, 8, ["value"]),
                _createVNode(EditorNumInputComponent, {
                  label: "Bottom",
                  value: _unref(style).spacingBottom,
                  onOnChange: onEditSpacingBottom,
                  style: {"margin-left":"10px"}
                }, null, 8, ["value"])
              ])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})