import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'
import DropdownQrListItem from './DropdownQrListItem.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownQrEditDelete',
  props: {
  id: {
    type: String,
    required: true
  }
},
  emits: ['onClickEdit', 'onClickDelete'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const items = [
  {
    icon: '',
    text: 'Edit',
    subItems: []
  },
  {
    icon: '',
    text: 'Delete',
    subItems: []
  }
]

const cssButton = computed(() => {
  return {
    marginLeft: '0.096rem'
  }
})

function onClick (id: number) {
  switch (id) {
    case 0:
      emit('onClickEdit')
      break
    case 1:
      emit('onClickDelete')
      break
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownQrListItem, {
    id: __props.id,
    "btn-icon": "dots-vertical",
    items: items,
    "btn-style": cssButton.value,
    onOnClick: onClick
  }, null, 8, ["id", "btn-style"]))
}
}

})