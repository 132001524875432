<script setup lang="ts">
import { defineProps } from 'vue'
import QRGridItem from '@/components/dashboard/QRGridItem.vue'

import { ListSubProfile } from '@/api/types'

const { subProfiles } = defineProps({
  subProfiles: Array
})

const emit = defineEmits(['onDownload', 'onSelect', 'onDelete'])

function onSelect (id: string) {
  emit('onSelect', id)
}

function onDelete (id: string) {
  emit('onDelete', id)
}

function onDownload (type: string, id: string) {
  emit('onDownload', type, id)
}

</script>

<template>
    <div class="qr-grid-container">
        <div
          v-for="subProfile in (subProfiles as ListSubProfile[])"
          :key="subProfile.id"
          class="qr-grid-item-container"
          >
            <QRGridItem
              :subProfile="subProfile"
              :interactive="true"
              @onSelect=onSelect
              @onDelete=onDelete
              @onDownload=onDownload
              />
          </div>
      </div>
</template>

<style scoped lang="scss">
  div.qr-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 270px );
    gap: 30px;
  }
  div.qr-grid-item-container {
      width: 100%;
      height: 410px; /* Adjust the height as needed */
  }
</style>
