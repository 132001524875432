import { QrSectionType, QrSectionItemType, SubProfile, UserSubProfile } from '@/api'

export function hexToRGBA (hex: string, alpha: number): string {
  // Check if the hex string is valid
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
  const result = hexRegex.exec(hex)
  if (!result) {
    return '#000000' // Invalid hex color
  }

  // Parse the RGB values
  const [, r, g, b] = result

  // Convert hex to decimal
  const red = parseInt(r, 16)
  const green = parseInt(g, 16)
  const blue = parseInt(b, 16)

  // Validate alpha value
  alpha = Math.min(1, Math.max(0, alpha))

  // Generate the RGBA string
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export function isHexColorAboveLumaThreshold (hex: string) : boolean {
  // Check if the hex string is valid
  const hexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
  const result = hexRegex.exec(hex)
  if (!result) {
    return false
  }

  // Parse the RGB values
  const [, r, g, b] = result

  // Convert hex to decimal
  const red = parseInt(r, 16)
  const green = parseInt(g, 16)
  const blue = parseInt(b, 16)

  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
    return true
  }
  return false
}

export function youtubeUrlToEmbed (url) {
  const res = url.split('=')
  const embeddedUrl = 'https://www.youtube.com/embed/' + res[1]
  return embeddedUrl
}

export function getTruncatedLabel (text: string, maxLength = 50): string {
  if (text.length <= maxLength) {
    return text
  }
  const lastSpaceIndex = text.lastIndexOf(' ', maxLength)
  if (lastSpaceIndex !== -1) {
    return text.substring(0, lastSpaceIndex) + '...'
  }
  return text.substring(0, maxLength) + '...'
}

export function getSortedSection (qrSection: QrSectionType): QrSectionType {
  if ('items' in qrSection.section) {
    qrSection.section.items = qrSection.section.items.sort((a: QrSectionItemType, b: QrSectionItemType) => a.listOrder - b.listOrder)
  }
  return qrSection
}

export function getSortedSubProfile<T extends SubProfile | UserSubProfile> (subProfile: T): T {
  const sortedSubProfile = {
    ...subProfile,
    sections: subProfile.sections
      .sort((a: QrSectionType, b: QrSectionType) => a.listOrder - b.listOrder)
      .map((section: QrSectionType) => getSortedSection(section))
  }
  return sortedSubProfile
}
