<script setup lang="ts">
import { computed, PropType, ref, useTemplateRef } from 'vue'
import { QrSection, ImageSectionContent, QrSectionTargetEnum } from '@/api/types'
import { useEditorStore } from '@/store'

import EditorPanel from '@/components/editor/EditorPanel.vue'
import EditorTextIconButton from '@/components/button/editor-panel/EditorTextIconButton.vue'

const editorStore = useEditorStore()
const { getImageUrl, postQrSectionImage, resetQrSectionImage } = editorStore

const { imageSection } = defineProps({
  imageSection: {
    type: Object as PropType<QrSection<ImageSectionContent, null>>,
    required: true
  }
})

const { id, label, active, persistent, section, style } = imageSection

const styleMode = ref(false)

const imageSelect = useTemplateRef('imageSelect')

const imageUrl = computed(() => getImageUrl(section.uri))

const imageExists = computed(() => section.uri !== 'default')

const cssButton = computed(() => {
  return {
    width: '4.8rem',
    height: '1.28rem',
    borderRadius: '0.26rem',
    backgroundColor: '#fff',
    lineHeight: '0'
  }
})

function onSelectImageStart () {
  if (imageSelect.value) {
    imageSelect.value.click()
  }
}

async function onSelectImageEnd (event : Event) {
  if (!imageSelect.value) return
  const files: ReadonlyArray<File> = [...(imageSelect.value.files ? imageSelect.value.files : [])]
  if (files) {
    const status = await postQrSectionImage(id, QrSectionTargetEnum.CONTENT, 'uri', files[0])
    imageSelect.value.value = ''
  }
}

async function onResetImage () {
  const status = await resetQrSectionImage(id, QrSectionTargetEnum.CONTENT, 'uri')
}

</script>

<template>
    <EditorPanel :id="id" :title="label" :active="active" :persistent="persistent" :styleMode="styleMode" :hasStyle="false">
        <div class="d-flex">
            <div class="panel-section panel-section-image-container">
              <a v-if="imageExists" class="delete-button"  href="#" @click="onResetImage">
                <img class="delete-button-image" :src="require('@/assets/icons/button/btn-close.png')">
              </a>
              <img class="header-image" :src=imageUrl crossorigin="anonymous">
            </div>
            <div class="panel-section panel-section-image-select">
              <p class="image-select-header-text">Replace Header image</p>
              <p class="image-select-info-text">Use a square image with a resolution that's at least 600 pixels.</p>
              <div class="d-flex">
                <div class="select-button-container">
                  <input type="file" id="image-select" ref="imageSelect" hidden @change=" onSelectImageEnd"/>
                  <label for="image-select" style="width: 100%">
                    <EditorTextIconButton icon="editor-image" @click="onSelectImageStart" :style="cssButton">
                        Upload Image
                    </EditorTextIconButton>
                  </label>
                </div>
            </div>
        </div>
      </div>
    </EditorPanel>
</template>

<style scoped lang="scss">
    div.panel-section {
        background-color: #F1F1F1;
        border-radius: 0.26rem;
        height: 5.2rem;
    }

    div.panel-section-image-container {
      aspect-ratio: 1;
      margin-right: 1.28rem;
      padding: 0.52rem;
      position: relative
    }

    div.panel-section-image-select {
      width: 100%;
      padding: 0.74rem 1.92rem 0.74rem 1.92rem;
    }
    img.header-image {
      width: 100%;
      height: 100%;
    }

    a.delete-button {
      position: absolute;
      top: -0.72rem;
      right: -0.28rem;
      width: 0.64rem;
      align-self: flex-end;
    }

    img.delete-button-image {
      width: 100%;
      aspect-ratio: 1;
      background-color: var(--color-black);
      border-radius: 50%;
    }

    p {
        text-align: left;
        font-family: inter;
    }
    p.image-select-header-text {
      color: var(--color-black);
      font-size: var(--font-size-14);
      font-weight: 600;
      line-height: 14px; /* 100% */
    }

    p.image-select-info-text {
      color: var(--color-nearly-black);
      font-size: var(--font-size-13);
      font-weight: 400;
      line-height: 13px; /* 100% */
      opacity: 0.5;
      margin-bottom: 0.52rem;
    }

    img.button-image {
      width: 0.64rem;
      height: 0.64rem;
    }
</style>
