<script setup lang="ts">

import { defineProps, ref, computed, onMounted, onBeforeUnmount, useTemplateRef, watch } from 'vue'

import { useDropdownStore } from '@/store'

import SvgButton from '../button/SvgButton.vue'
import SvgLink from '../button/SvgLink.vue'

import { Palette } from '@/theme'
import { storeToRefs } from 'pinia'

const { id, items, btnIcon, btnStyle, btnText, btnTextStyle, containerStyle, contentStyle, linkStyle, linkIconStyle, linkTextStyle } = defineProps({
  id: {
    type: String,
    required: true
  },
  items: Array,
  btnIcon: {
    type: String,
    required: true
  },
  btnStyle: Object,
  btnText: String,
  btnTextStyle: Object,
  containerStyle: Object,
  iconStyle: Object,
  contentStyle: Object,
  linkStyle: Object,
  linkIconStyle: Object,
  linkTextStyle: Object
})

const content = useTemplateRef('content')

const dropdownStore = useDropdownStore()
const { activeDropdown } = storeToRefs(dropdownStore)
const { toggleActiveDropdown } = dropdownStore

const visible = computed(() => activeDropdown.value === id)

const emit = defineEmits(['onClick'])

const cssContainer = computed(() => {
  return {
    ...containerStyle
  }
})

const cssContent = computed(() => {
  return {
    '--color': Palette.neutral800,
    ...contentStyle
  }
})

const cssLink = computed(() => {
  return {
    ...linkStyle
  }
})

const cssLinkIcon = computed(() => {
  return {
    ...linkIconStyle
  }
})

const cssLinkText = computed(() => {
  return {
    ...linkTextStyle
  }
})

const cssLinkComp = computed(() => {
  return {
    ...linkStyle,
    ...linkTextStyle
  }
})

function onToggle (event) {
  event.stopPropagation()
  toggleActiveDropdown(id)
}

function onClickItem (event, index) {
  event.stopPropagation()
  toggleActiveDropdown('')
  emit('onClick', index)
}

function handleClickOutside (event) {
  if (
    content.value === event.target ||
    event.composedPath().includes(content.value)
  ) {
    return
  }
  toggleActiveDropdown('')
}

onMounted(() => document.addEventListener('click', handleClickOutside))
onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))
</script>

<template>
    <div ref="content" :style="cssContainer">
        <SvgLink
          v-if="btnText"
          @click="onToggle"
          :icon="btnIcon"
          :style="btnStyle"
          :textStyle="btnTextStyle"
          >
          {{ btnText }}
        </SvgLink>
        <SvgButton v-else @click="onToggle" :icon="btnIcon" :style="btnStyle"/>
        <div v-if="visible" class="dropdown-content" :style="cssContent">
          <div v-for="(item, i) in items" :key="i" class="dropdown-link-container" @click="(e) => onClickItem(e, i)">
            <SvgLink
              v-if="item.icon"
              :icon="item.icon"
              :style="cssLink"
              :textStyle="cssLinkText"
              :iconStyle="cssLinkIcon"
              >
                {{ item.text }}
              </SvgLink>
              <p v-else class="dropdown-link-text" :style="cssLinkComp"> {{ item.text }}</p>
          </div>
      </div>
    </div>
</template>

<style scoped>
.dropdown-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
    padding: 0.44rem;
    background-color: var(--color-white);
    color: var(--color);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.53rem;
    z-index: 1;
}
div.dropdown-link-container {
    display: flex;
    cursor: pointer;
    margin: 0;
}
p.dropdown-link-text {
  margin: 0;
}
</style>
