import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "12",
  viewBox: "0 0 8 12",
  fill: "none"
}
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("path", {
        d: "M1 1L6 6L1 11",
        stroke: _ctx.color,
        "stroke-width": "2"
      }, null, 8, _hoisted_2)
    ]))
  ], 4))
}