import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "sub-profile-content" }
const _hoisted_2 = ["index"]
const _hoisted_3 = { class: "sub-profile-footer" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "sub-profile-footer-text" }

import { PropType } from 'vue'
import { QrSectionTypeEnum, SubProfile, UserSubProfile } from '@/api/types'

import ImageSection from '@/components/qr-section/ImageSection.vue'
import HeaderSection from '@/components/qr-section/HeaderSection.vue'
import TextSection from '@/components/qr-section/TextSection.vue'
import SocialsSection from '@/components/qr-section/SocialsSection.vue'
import TableSection from '@/components/qr-section/TableSection.vue'
import FeaturesSection from '@/components/qr-section/FeaturesSection.vue'
import AmazonReviewCTASection from '@/components/qr-section/AmazonReviewCTASection.vue'
import LinkSection from '@/components/qr-section/LinkSection.vue'
import ButtonSection from '@/components/qr-section/ButtonSection.vue'
import EmbeddedVideoSection from '@/components/qr-section/EmbeddedVideoSection.vue'
import NewsSection from '@/components/qr-section/NewsSection.vue'

import { poweredByText } from '@/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'SubProfileContainerModular',
  props: {
  subProfile: Object as PropType<SubProfile | UserSubProfile | null>,
  contentActive: Boolean
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (__props.subProfile)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "sub-profile-container",
        style: _normalizeStyle(_ctx.cssVars)
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.subProfile.sections, (section, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "section-container d-flex flex-row justify-content-center",
              key: i,
              index: i
            }, [
              (section.type === _unref(QrSectionTypeEnum).AMAZAON_REVIEW_CTA && section.active)
                ? (_openBlock(), _createBlock(AmazonReviewCTASection, {
                    key: 0,
                    amazonReviewCTASection: section
                  }, null, 8, ["amazonReviewCTASection"]))
                : (section.type === _unref(QrSectionTypeEnum).BUTTON && section.active)
                  ? (_openBlock(), _createBlock(ButtonSection, {
                      key: 1,
                      buttonSection: section
                    }, null, 8, ["buttonSection"]))
                  : (section.type === _unref(QrSectionTypeEnum).EMBEDDED_VIDEO && section.active)
                    ? (_openBlock(), _createBlock(EmbeddedVideoSection, {
                        key: 2,
                        embeddedVideoSection: section
                      }, null, 8, ["embeddedVideoSection"]))
                    : (section.type === _unref(QrSectionTypeEnum).FEATURES && section.active)
                      ? (_openBlock(), _createBlock(FeaturesSection, {
                          key: 3,
                          featuresSection: section
                        }, null, 8, ["featuresSection"]))
                      : (section.type === _unref(QrSectionTypeEnum).HEADER && section.active)
                        ? (_openBlock(), _createBlock(HeaderSection, {
                            key: 4,
                            headerSection: section
                          }, null, 8, ["headerSection"]))
                        : (section.type === _unref(QrSectionTypeEnum).IMAGE && section.active)
                          ? (_openBlock(), _createBlock(ImageSection, {
                              key: 5,
                              imageSection: section
                            }, null, 8, ["imageSection"]))
                          : (section.type === _unref(QrSectionTypeEnum).LINK && section.active)
                            ? (_openBlock(), _createBlock(LinkSection, {
                                key: 6,
                                linkSection: section
                              }, null, 8, ["linkSection"]))
                            : (section.type === _unref(QrSectionTypeEnum).NEWS && section.active)
                              ? (_openBlock(), _createBlock(NewsSection, {
                                  key: 7,
                                  newsSection: section
                                }, null, 8, ["newsSection"]))
                              : (section.type === _unref(QrSectionTypeEnum).SOCIALS && section.active)
                                ? (_openBlock(), _createBlock(SocialsSection, {
                                    key: 8,
                                    socialsSection: section
                                  }, null, 8, ["socialsSection"]))
                                : (section.type === _unref(QrSectionTypeEnum).TABLE && section.active)
                                  ? (_openBlock(), _createBlock(TableSection, {
                                      key: 9,
                                      tableSection: section
                                    }, null, 8, ["tableSection"]))
                                  : (section.type === _unref(QrSectionTypeEnum).TEXT && section.active)
                                    ? (_openBlock(), _createBlock(TextSection, {
                                        key: 10,
                                        textSection: section
                                      }, null, 8, ["textSection"]))
                                    : _createCommentVNode("", true)
            ], 8, _hoisted_2))
          }), 128)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "sub-profile-footer-image",
              src: require('@/assets/icons/main/icon-blue.png')
            }, null, 8, _hoisted_4),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(poweredByText)), 1)
          ])
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})