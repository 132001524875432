import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row align-items-center preview-panel-header-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "d-flex flex-fill justify-content-end" }

import { computed, ref, PropType } from 'vue'
import { UserSubProfile } from '@/api/types'

import SubProfileContainerModular from '@/components/profile/SubProfileContainerModular.vue'
import ViewportButton from '@/components/button/ViewportButton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PreviewPanel',
  props: {
  subProfile: {
    type: Object as PropType<UserSubProfile | null>,
    required: true
  },
  viewport: {
    type: String,
    required: true
  },
  containerStyle: {
    type: Object,
    default: null
  }
},
  emits: ['onSelectViewport'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const contentActive = ref(false)

const cssVarsContainer = computed(() => {
  if (__props.viewport === 'desktop') {
    return {
      width: 'calc(100vw - 340px)',
      height: '67vh',
      '--left': 'inherit',
      '--top': 'inherit',
      '--bottom': 'inherit',
      ...__props.containerStyle
    }
  } else {
    return {
      height: '67vh',
      aspectRatio: __props.viewport === 'mobile' ? '329 / 709' : '720 / 709',
      '--left': 'inherit',
      '--top': 'inherit',
      '--bottom': 'inherit',
      ...__props.containerStyle
    }
  }
})

function setContentActive (active: boolean) {
  contentActive.value = active
}

function onSelectViewportMobile () {
  emit('onSelectViewport', 'mobile')
}

function onSelectViewportTablet () {
  emit('onSelectViewport', 'tablet')
}

function onSelectViewportDesktop () {
  emit('onSelectViewport', 'desktop')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "preview-panel-container",
    style: _normalizeStyle(cssVarsContainer.value)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "preview-icon",
        src: require('@/assets/icons/svg/eye.svg')
      }, null, 8, _hoisted_2),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "m-0 preview-text" }, "Keypin Preview", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ViewportButton, {
          selected: __props.viewport === 'desktop',
          icon: "desktop",
          onClick: onSelectViewportDesktop
        }, null, 8, ["selected"]),
        _createVNode(ViewportButton, {
          selected: __props.viewport === 'tablet',
          icon: "tablet",
          onClick: onSelectViewportTablet
        }, null, 8, ["selected"]),
        _createVNode(ViewportButton, {
          selected: __props.viewport === 'mobile',
          icon: "mobile",
          onClick: onSelectViewportMobile
        }, null, 8, ["selected"])
      ])
    ]),
    _createVNode(SubProfileContainerModular, {
      subProfile: __props.subProfile,
      contentActive: contentActive.value,
      onOnDetailClick: _cache[0] || (_cache[0] = (id, contentActive) => setContentActive(contentActive))
    }, null, 8, ["subProfile", "contentActive"])
  ], 4))
}
}

})