import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "link-body"
}

import { computed, PropType } from 'vue'

import { QrSection, LinkSectionContent, LinkSectionStyle } from '@/api/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'LinkSection',
  props: {
  linkSection: {
    type: Object as PropType<QrSection<LinkSectionContent, LinkSectionStyle>>,
    required: true
  }
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const { id, label, active, persistent, section, style } = __props.linkSection

const cssVarsText = computed(() => {
  return {
    '--text-align': style.align,
    '--font-size': style.size + 'px',
    '--line-height': Math.round(style.size * 1.57143) + 'px',
    '--font-weight': style.bold ? 'bold' : 'normal',
    '--font-style': style.italic ? 'italic' : 'normal',
    '--text-decoration': style.underline ? 'underline' : 'none',
    '--color': style.textColor,
    '--background-color': style.backgroundColor,
    '--margin': `${style.spacingTop}px ${style.spacingRight}px ${style.spacingBottom}px ${style.spacingLeft}px`
  }
})

function onClick () {
  window.open(section.url, '_blank')
  emit('onClick')
}


return (_ctx: any,_cache: any) => {
  return (__props.linkSection)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", {
          class: "link-text",
          style: _normalizeStyle(cssVarsText.value),
          onClick: onClick
        }, _toDisplayString(_unref(section).text), 5)
      ]))
    : _createCommentVNode("", true)
}
}

})