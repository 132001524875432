import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "view-container"
}
const _hoisted_2 = { class: "view-content container-fluid" }
const _hoisted_3 = { class: "top-bar-scroll" }
const _hoisted_4 = { class: "back-button-container" }
const _hoisted_5 = { class: "sub=-profile-label-container" }
const _hoisted_6 = { class: "sub-profile-label-text" }
const _hoisted_7 = { class: "qr-switch-download-container" }
const _hoisted_8 = { class: "d-flex flex-row align-items-center" }
const _hoisted_9 = { class: "sub-profile-url-container" }
const _hoisted_10 = { class: "sub-profile-qr-view-download-container" }
const _hoisted_11 = { class: "sub-profile-status-toggle-container" }
const _hoisted_12 = {
  key: 0,
  class: "d-flex flex-row align-items-end mt-3",
  style: {"background-color":"lightgray","padding":"8px","border-radius":"8px"}
}
const _hoisted_13 = {
  class: "d-flex flex-column align-items-start",
  style: {"flex":"1","margin":"0 8px 0 0"}
}
const _hoisted_14 = { class: "sub-profile-status-toggle-container" }
const _hoisted_15 = {
  class: "d-flex mt-3 justify-content-end",
  style: {"width":"100%"}
}
const _hoisted_16 = {
  class: "d-flex flex-column pb-5 panel-list-container",
  style: {"flex-grow":"1","width":"40vw"}
}
const _hoisted_17 = { class: "create-section-button-container mt-4" }
const _hoisted_18 = { class: "preview-panel-container" }

import { computed, ref, useTemplateRef, onMounted, onBeforeUnmount } from 'vue'

import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import {
  AmazonReviewCTASectionContent,
  AmazonReviewCTASectionStyle,
  ButtonSectionContent,
  ButtonSectionStyle,
  EmbeddedVideoSectionContent,
  EmbeddedVideoSectionStyle,
  FeaturesSectionContent,
  FeaturesSectionStyle,
  HeaderSectionContent,
  ImageSectionContent,
  LinkSectionContent,
  LinkSectionStyle,
  NewsSectionContent,
  NewsSectionStyle,
  QrSection,
  QrSectionTypeEnum,
  SocialsSectionContent,
  SocialsSectionStyle,
  TableSectionContent,
  TableSectionStyle,
  TextSectionContent
} from '@/api/types'
import { useDashboardStore, useEditorStore } from '@/store'

import AddSocialsPopup from '@/components/editor/AddSocialsPopup.vue'
import AmazonReviewCTAPanel from '@/components/editor/AmazonReviewCTAPanel.vue'
import ButtonPanel from '@/components/editor/ButtonPanel.vue'
import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import DropdownQrAddSection from '@/components/dropdown/DropdownQrAddSection.vue'
import DropdownQrViewDownload from '@/components/dropdown/DropdownQrViewDownload.vue'
import DropToggle from '@/components/switch/DropToggle.vue'
import EmbeddedVideoPanel from '@/components/editor/EmbeddedVideoPanel.vue'
import FeaturesPanel from '@/components/editor/FeaturesPanel.vue'
import ImagePanel from '@/components/editor/ImagePanel.vue'
import HeaderPanel from '@/components/editor/HeaderPanel.vue'
import LinkPanel from '@/components/editor/LinkPanel.vue'
import NewsPanel from '@/components/editor/NewsPanel.vue'
import SocialsPanel from '@/components/editor/SocialsPanel.vue'
import TablePanel from '@/components/editor/TablePanel.vue'
import TextPanel from '@/components/editor/TextPanel.vue'

import PreviewPanel from '@/components/editor/PreviewPanel.vue'
import SvgButton from '@/components/button/SvgButton.vue'
import TextClipboard from '@/components/text/TextClipboard.vue'

import { Palette } from '@/theme'

import { SlickList, SlickItem } from 'vue-slicksort'


export default /*@__PURE__*/_defineComponent({
  __name: 'ManageQrView',
  setup(__props) {

const route = useRoute()
const router = useRouter()

const dashboardStore = useDashboardStore()
const { qrData } = storeToRefs(dashboardStore)
const { getQrDownload } = dashboardStore

const editorStore = useEditorStore()
const {
  subProfile
} = storeToRefs(editorStore)
const {
  resetSubProfile,
  onChangeSectionOrderStart,
  fetchUserSubProfile,
  toggleSubProfileActive,
  postQrSection,
  putQrSectionOrder,
  editRedirectUrl,
  editRedirectActive,
  resetRedirectUrl,
  getQrUrl
} = editorStore

const downloadContent = useTemplateRef('content')

const addSocialsPopupVisible = ref(false)
const contentActive = ref(false)
const downloadDropdownVisible = ref(false)
const previewViewport = ref('mobile')

const dropdownCssVars = computed(() => {
  return {
    '--background-color': Palette.light_grey,
    '--color': Palette.white
  }
})

const cssBackButton = computed(() => {
  return {
    backgroundColor: 'transparent',
    color: Palette.neutral800
  }
})

const qrUrl = computed(getQrUrl)

function toggleDownloadDropdown () {
  contentActive.value = !contentActive.value
}

function handleClickOutside (event) {
  if (
    downloadContent.value &&
    !event.composedPath().includes(downloadContent.value)
  ) {
    downloadDropdownVisible.value = false
  }
}

function onSelectViewport (value: string) {
  previewViewport.value = value
}

function onAddSocials () {
  addSocialsPopupVisible.value = true
}

function onDismissSocialsPopup () {
  addSocialsPopupVisible.value = false
}

/**
 * API CALLS
 */
async function onFetchUserSubProfile () {
  resetSubProfile()
  const status = await fetchUserSubProfile(String(route.params.id))
  if (status !== 'ok') {
    router.push('/not-found')
  }
  console.log('ne' + JSON.stringify(subProfile.value))
}

async function onToggleActive () {
  const status = await toggleSubProfileActive()
  if (status !== 'ok') {
    // return
  }
}

async function onCreateQrSection (type: number) {
  const status = await postQrSection(type)
  if (status !== 'ok') {
    // return
  }
}

async function onEditQrRedirectUrl (value: string) {
  const status = await editRedirectUrl(value)
  if (status !== 'ok') {
    // return
  }
}

async function onEditQrRedirectActive (value: boolean) {
  const status = await editRedirectActive(value)
  if (status !== 'ok') {
    // return
  }
}

async function onResetQrRedirectUrl () {
  const status = await resetRedirectUrl()
  if (status !== 'ok') {
    // return
  }
}

async function onDownloadQR (type: string) {
  const { id } = subProfile.value
  const status = await getQrDownload(id, type)
  if (status !== 200) return

  const href = URL.createObjectURL(qrData.value)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', id + '.' + type)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

router.beforeEach((to, from, next) => {
  if (contentActive.value) {
    contentActive.value = false
    next(false)
  } else {
    next()
  }
})

onMounted(onFetchUserSubProfile)
onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))
/*
    onToggleMode (id: number, mode: boolean) {
      if (mode) this.styleModeActive.push(id)
      else {
        this.styleModeActive.forEach((item, index) => {
          if (item === id) this.styleModeActive.splice(index, 1)
        })
      }
    },
}) */


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(subProfile))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(SvgButton, {
                  onClick: _cache[0] || (_cache[0] = () => _unref(router).replace('/dashboard')),
                  icon: "chevron-left",
                  style: _normalizeStyle(cssBackButton.value)
                }, null, 8, ["style"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", _hoisted_6, _toDisplayString(_unref(subProfile).label), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(TextClipboard, { text: qrUrl.value }, null, 8, ["text"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(DropdownQrViewDownload, {
                      qrUri: _unref(subProfile).qrUri,
                      onOnDownload: onDownloadQR
                    }, null, 8, ["qrUri"])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (!_unref(subProfile).isRootProfile)
                      ? (_openBlock(), _createBlock(DropToggle, {
                          key: 0,
                          "on-text": "Online",
                          "off-text": "Offline",
                          "initial-value": _unref(subProfile).active,
                          "onUpdate:modelValue": onToggleActive
                        }, null, 8, ["initial-value"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (false && !_unref(subProfile).isRootProfile)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[2] || (_cache[2] = _createElementVNode("p", { style: {"margin":"0 0 0 2px"} }, "Redirect to:", -1)),
                        _createVNode(DebouncedTextInput, {
                          value: _unref(subProfile).qrRedirect.url,
                          type: "text",
                          placeholder: "title",
                          "onUpdate:value": onEditQrRedirectUrl
                        }, null, 8, ["value"])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(DropToggle, {
                          "on-text": "On",
                          "off-text": "Off",
                          "initial-value": _unref(subProfile).qrRedirect.active,
                          "onUpdate:modelValue": onEditQrRedirectActive
                        }, null, 8, ["initial-value"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_unref(SlickList), {
                  axis: "y",
                  list: _unref(subProfile).sections,
                  "onUpdate:list": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_unref(subProfile).sections) = $event)),
                    _unref(putQrSectionOrder)
                  ],
                  onSortStart: _unref(onChangeSectionOrderStart),
                  useDragHandle: true
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(subProfile).sections, (section, i) => {
                      return (_openBlock(), _createBlock(_unref(SlickItem), {
                        key: section,
                        index: i
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            (section.type === _unref(QrSectionTypeEnum).AMAZAON_REVIEW_CTA)
                              ? (_openBlock(), _createBlock(AmazonReviewCTAPanel, {
                                  key: 0,
                                  amazonReviewCTASection: (section as unknown as QrSection<AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle>)
                                }, null, 8, ["amazonReviewCTASection"]))
                              : (section.type === _unref(QrSectionTypeEnum).BUTTON)
                                ? (_openBlock(), _createBlock(ButtonPanel, {
                                    key: 1,
                                    buttonSection: (section as unknown as QrSection<ButtonSectionContent, ButtonSectionStyle>)
                                  }, null, 8, ["buttonSection"]))
                                : (section.type === _unref(QrSectionTypeEnum).EMBEDDED_VIDEO)
                                  ? (_openBlock(), _createBlock(EmbeddedVideoPanel, {
                                      key: 2,
                                      embeddedVideoSection: (section as unknown as QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>)
                                    }, null, 8, ["embeddedVideoSection"]))
                                  : (section.type === _unref(QrSectionTypeEnum).FEATURES)
                                    ? (_openBlock(), _createBlock(FeaturesPanel, {
                                        key: 3,
                                        featuresSection: (section as unknown as QrSection<FeaturesSectionContent, FeaturesSectionStyle>)
                                      }, null, 8, ["featuresSection"]))
                                    : (section.type === _unref(QrSectionTypeEnum).HEADER)
                                      ? (_openBlock(), _createBlock(HeaderPanel, {
                                          key: 4,
                                          headerSection: (section as unknown as QrSection<HeaderSectionContent, null>)
                                        }, null, 8, ["headerSection"]))
                                      : _createCommentVNode("", true),
                            (section.type === _unref(QrSectionTypeEnum).IMAGE)
                              ? (_openBlock(), _createBlock(ImagePanel, {
                                  key: 5,
                                  imageSection: (section as unknown as QrSection<ImageSectionContent, null>)
                                }, null, 8, ["imageSection"]))
                              : (section.type === _unref(QrSectionTypeEnum).LINK)
                                ? (_openBlock(), _createBlock(LinkPanel, {
                                    key: 6,
                                    linkSection: (section as unknown as QrSection<LinkSectionContent, LinkSectionStyle>)
                                  }, null, 8, ["linkSection"]))
                                : (section.type === _unref(QrSectionTypeEnum).NEWS)
                                  ? (_openBlock(), _createBlock(NewsPanel, {
                                      key: 7,
                                      newsSection: (section as unknown as QrSection<NewsSectionContent, NewsSectionStyle>)
                                    }, null, 8, ["newsSection"]))
                                  : (section.type === _unref(QrSectionTypeEnum).SOCIALS)
                                    ? (_openBlock(), _createBlock(SocialsPanel, {
                                        key: 8,
                                        onOnAddSocials: onAddSocials,
                                        socialsSection: (section as unknown as QrSection<SocialsSectionContent, SocialsSectionStyle>)
                                      }, null, 8, ["socialsSection"]))
                                    : (section.type === _unref(QrSectionTypeEnum).TABLE)
                                      ? (_openBlock(), _createBlock(TablePanel, {
                                          key: 9,
                                          tableSection: (section as unknown as QrSection<TableSectionContent, TableSectionStyle>)
                                        }, null, 8, ["tableSection"]))
                                      : (section.type === _unref(QrSectionTypeEnum).TEXT)
                                        ? (_openBlock(), _createBlock(TextPanel, {
                                            key: 10,
                                            ref_for: true,
                                            ref: 'textPanel' + section.id.toString(),
                                            textSection: (section as unknown as QrSection<TextSectionContent, null>)
                                          }, null, 8, ["textSection"]))
                                        : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["index"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["list", "onUpdate:list", "onSortStart"]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(DropdownQrAddSection, {
                    onOnClickLink: onCreateQrSection,
                    onOnAddSocials: onAddSocials
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(PreviewPanel, {
                  subProfile: _unref(subProfile),
                  viewport: previewViewport.value,
                  onOnSelectViewport: onSelectViewport
                }, null, 8, ["subProfile", "viewport"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(AddSocialsPopup, {
      visible: addSocialsPopupVisible.value,
      onOnDismiss: onDismissSocialsPopup
    }, null, 8, ["visible"])
  ], 64))
}
}

})