import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue'
import DropdownButton from './DropdownButton.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownQrSectionEditDelete',
  props: {
  id: {
    type: String,
    required: true
  }
},
  emits: ['onClickDuplicate', 'onClickDelete'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const items = [
  {
    icon: '',
    text: 'Duplicate',
    subItems: []
  },
  {
    icon: '',
    text: 'Delete',
    subItems: []
  }
]

const cssButton = computed(() => {
  return {
    backgroundColor: 'var(--color-white)',
    width: '1.02rem',
    height: '1.02rem',
    padding: '0.18rem',
    marginRight: '0.32rem'
  }
})

const cssLink = computed(() => {
  return {
    padding: '0.38rem 0.44rem'
  }
})

const cssLinkText = computed(() => {
  return {
    color: 'var(--color-nearly-black)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-14)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px'
  }
})

function onClick (id: number) {
  switch (id) {
    case 0:
      emit('onClickDuplicate')
      break
    case 1:
      emit('onClickDelete')
      break
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownButton, {
    id: __props.id,
    "btn-icon": "dots-vertical",
    "btn-style": cssButton.value,
    "link-style": cssLink.value,
    "link-text-style": cssLinkText.value,
    items: items,
    onOnClick: onClick
  }, null, 8, ["id", "btn-style", "link-style", "link-text-style"]))
}
}

})