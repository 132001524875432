<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue'

import { QrSection, QrSectionItem, SocialsSectionContent, SocialsSectionStyle, SocialsSectionItemContent } from '@/api/types'

import IconFacebookSvg from '@/components/svg/socials/IconFacebookSvg.vue'
import IconInstagramSvg from '@/components/svg/socials/IconInstagramSvg.vue'
import IconTwitterSvg from '@/components/svg/socials/IconTwitterSvg.vue'

import { SOCIAL_TYPE_TO_ICON } from '@/constants/constants'
import { isHexColorAboveLumaThreshold } from '@/utils'

const { socialsSection } = defineProps({
  socialsSection: {
    type: Object as PropType<QrSection<SocialsSectionContent, SocialsSectionStyle>>,
    required: true
  }
})

const emit = defineEmits(['onClick'])

const { section, style } = socialsSection

const cssVars = computed(() => {
  return {
    '--background-color': style.backgroundColor,
    '--justify-content': style.align === 'left' ? 'flex-start' : style.align === 'center' ? 'center' : 'flex-end',
    '--align-items': style.align === 'left' ? 'flex-start' : style.align === 'center' ? 'center' : 'flex-end',
    '--content-margin': style.spacingTop.toString() + 'px ' + style.spacingRight.toString() + 'px ' + style.spacingBottom.toString() + 'px ' + style.spacingLeft.toString() + 'px'
  }
})

const cssVarsTitle = computed(() => {
  return {
    '--title-margin': style.align === 'left' ? '0 0 0 5px' : style.align === 'center' ? '0 0 0 0' : '0 5px 0 0',
    '--title-color': isHexColorAboveLumaThreshold(style.backgroundColor) ? '#000' : '#FFF'
  }
})

const listBtnStyleWidth = () => {
  if (style.size === 'sm') {
    return '80%'
  } else if (style.size === 'md') {
    return '90%'
  } else {
    return '100%'
  }
}

const listStyleHeight = () => {
  if (style.size === 'sm') {
    return '30px'
  } else if (style.size === 'md') {
    return '50px'
  } else {
    return '60px'
  }
}

const rowBtnStyleWidthHeight = () => {
  if (style.size === 'sm') {
    return '40px'
  } else if (style.size === 'md') {
    return '50px'
  } else {
    return '60px'
  }
}

const btnStyleBorderRadius = () => {
  if (style.style === 'pill') {
    return '15px'
  } else if (style.corners === 'rounded') {
    return '10px'
  } else {
    return '0px'
  }
}

const cssVarsRowBtn = computed(() => {
  return {
    '--width': rowBtnStyleWidthHeight(),
    '--height': rowBtnStyleWidthHeight(),
    '--border-radius': btnStyleBorderRadius()
  }
})

const cssVarsListBtn = computed(() => {
  return {
    '--width': listBtnStyleWidth(),
    '--height': listStyleHeight(),
    '--border-radius': btnStyleBorderRadius()
  }
})

function getIcon (type: string): string {
  if (socialsSection) return SOCIAL_TYPE_TO_ICON[type].value
  return SOCIAL_TYPE_TO_ICON.default.value
}

function getLabel (type: string): string {
  const parts = type.split('-')
  return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
}

function onClick (id: number, url: string) {
  window.open(url, '_blank')
  emit('onClick', id)
}
</script>

<template>
    <div v-if="socialsSection" class="socials-container" :style="cssVars">
      <div class="socials-content">
        <p class="socials-title" :style="cssVarsTitle">Social Links</p>
        <div v-if="style.layout === 'row'" class="socials-buttons-row-container" :style="cssVars">
          <div v-for="item in section.items" :key="item.id" class="social-row-button" :style="cssVarsRowBtn" @click="() => onClick(item.id, item.content.value)">
              <img v-if="style.logoTheme === 'brand'" class="social-row-button-icon" :src="require(`@/assets/${getIcon(item.content.type)}`)" >
              <IconFacebookSvg v-else-if="item.content.type === 'facebook'" :color="style.logoColor"/>
              <IconInstagramSvg v-else-if="item.content.type === 'instagram'" :color="style.logoColor"/>
              <IconTwitterSvg v-else-if="item.content.type === 'twitter'" :color="style.logoColor"/>
              <img v-else class="social-row-button-icon" :src="require(`@/assets/${getIcon(item.content.type)}`)" >
          </div>
        </div>
        <div v-else class="socials-buttons-list-container" :style="cssVars">
          <div v-for="item in section.items" :key="item.id" class="social-list-button" :style="cssVarsListBtn" @click="() => onClick(item.id, item.content.value)">
              <img v-if="style.logoTheme === 'brand'" class="social-list-button-icon" :src="require(`@/assets/${getIcon(item.content.type)}`)" />
              <IconFacebookSvg v-else-if="item.content.type === 'facebook'" :color="style.logoColor" style="margin-right: 15px"/>
              <IconInstagramSvg v-else-if="item.content.type === 'instagram'" :color="style.logoColor" style="margin-right: 15px"/>
              <IconTwitterSvg v-else-if="item.content.type === 'twitter'" :color="style.logoColor" style="margin-right: 15px"/>
              <img v-else class="social-list-button-icon" :src="require(`@/assets/${getIcon(item.content.type)}`)" />
              <p class="social-list-button-text">{{ getLabel(item.content.type) }}</p>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">
  div.socials-container{
    min-width: 111%;
    margin-top: 20px;
    background-color: var(--background-color);
  }
  div.socials-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: var(--align-items);
    margin: var(--content-margin);
    background-color: var(--background-color);
  }
  p.socials-title{
    color: var(--title-color);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    margin: var(--title-margin);
  }
  div.socials-buttons-row-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: var(--justify-content);
    align-items: center;
    margin: 10px 0 0 0;
  }
  div.socials-buttons-list-container {
    display: flex;
    flex-direction: column;
    align-items: var(--align-items);
    margin: 20px 0 0 0;
    width: 100%;
  }
  div.social-row-button{
    margin: 10px 5px 0 5px;
    width: var(--width);
    height: var(--height);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    background: var(--White, #FFF);
    cursor: pointer;
  }
  div.social-list-button{
    margin: 5px 0 5px 0;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: var(--White, #FFF);
    display: flex;
    align-items: center;
    padding: 0 13px 0 13px;
    cursor: pointer;
  }
  img.social-row-button-icon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  img.social-list-button-icon{
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 15px;
  }

  p.social-list-button-text{
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-13);
    font-weight: 400;
    margin: 0;
    text-decoration: none;
  }
</style>
