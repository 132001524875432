import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]

import { ref, computed, onMounted, onBeforeUnmount, useTemplateRef, watch } from 'vue'

import { useDropdownStore } from '@/store'

import SvgButton from '../button/SvgButton.vue'
import SvgLink from '../button/SvgLink.vue'

import { Palette } from '@/theme'
import { storeToRefs } from 'pinia'


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownButton',
  props: {
  id: {
    type: String,
    required: true
  },
  items: Array,
  btnIcon: {
    type: String,
    required: true
  },
  btnStyle: Object,
  btnText: String,
  btnTextStyle: Object,
  containerStyle: Object,
  iconStyle: Object,
  contentStyle: Object,
  linkStyle: Object,
  linkIconStyle: Object,
  linkTextStyle: Object
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {




const content = useTemplateRef('content')

const dropdownStore = useDropdownStore()
const { activeDropdown } = storeToRefs(dropdownStore)
const { toggleActiveDropdown } = dropdownStore

const visible = computed(() => activeDropdown.value === __props.id)

const emit = __emit

const cssContainer = computed(() => {
  return {
    ...__props.containerStyle
  }
})

const cssContent = computed(() => {
  return {
    '--color': Palette.neutral800,
    ...__props.contentStyle
  }
})

const cssLink = computed(() => {
  return {
    ...__props.linkStyle
  }
})

const cssLinkIcon = computed(() => {
  return {
    ...__props.linkIconStyle
  }
})

const cssLinkText = computed(() => {
  return {
    ...__props.linkTextStyle
  }
})

const cssLinkComp = computed(() => {
  return {
    ...__props.linkStyle,
    ...__props.linkTextStyle
  }
})

function onToggle (event) {
  event.stopPropagation()
  toggleActiveDropdown(__props.id)
}

function onClickItem (event, index) {
  event.stopPropagation()
  toggleActiveDropdown('')
  emit('onClick', index)
}

function handleClickOutside (event) {
  if (
    content.value === event.target ||
    event.composedPath().includes(content.value)
  ) {
    return
  }
  toggleActiveDropdown('')
}

onMounted(() => document.addEventListener('click', handleClickOutside))
onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "content",
    ref: content,
    style: _normalizeStyle(cssContainer.value)
  }, [
    (__props.btnText)
      ? (_openBlock(), _createBlock(SvgLink, {
          key: 0,
          onClick: onToggle,
          icon: __props.btnIcon,
          style: _normalizeStyle(__props.btnStyle),
          textStyle: __props.btnTextStyle
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.btnText), 1)
          ]),
          _: 1
        }, 8, ["icon", "style", "textStyle"]))
      : (_openBlock(), _createBlock(SvgButton, {
          key: 1,
          onClick: onToggle,
          icon: __props.btnIcon,
          style: _normalizeStyle(__props.btnStyle)
        }, null, 8, ["icon", "style"])),
    (visible.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "dropdown-content",
          style: _normalizeStyle(cssContent.value)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "dropdown-link-container",
              onClick: (e) => onClickItem(e, i)
            }, [
              (item.icon)
                ? (_openBlock(), _createBlock(SvgLink, {
                    key: 0,
                    icon: item.icon,
                    style: _normalizeStyle(cssLink.value),
                    textStyle: cssLinkText.value,
                    iconStyle: cssLinkIcon.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["icon", "style", "textStyle", "iconStyle"]))
                : (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    class: "dropdown-link-text",
                    style: _normalizeStyle(cssLinkComp.value)
                  }, _toDisplayString(item.text), 5))
            ], 8, _hoisted_1))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ], 4))
}
}

})