<script setup lang="ts">
import { computed } from 'vue'

const { selected, icon } = defineProps({
  selected: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    required: true
  }
})

const cssVars = computed(() => {
  return {
    '--background': selected ? 'linear-gradient(116deg, rgba(63, 128, 255, 0.1) 16.27%, rgba(123, 61, 255, 0.1) 83.73%)' : '#FFFFFF'
  }
})

const imgSrc = computed(() => {
  return require('@/assets/icons/svg/viewport-' + (selected ? icon + '-grad' : icon) + '.svg')
})

</script>

<template>
    <div class="viewport-button-wrapper" :style="cssVars">
        <img :src="imgSrc" alt="icon">
    </div>
</template>

<style scoped lang="scss">
    div.viewport-button-wrapper {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin: 0 4px 0 4px;
      background: var(--background);
      cursor: pointer;
    }

    img {
        height: 24px;
    }
</style>
