import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "editor-num-input-label" }
const _hoisted_2 = { class: "input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DebouncedTextInput = _resolveComponent("DebouncedTextInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editor-num-input-container",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(this.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DebouncedTextInput, {
        value: String(_ctx.value),
        type: "text",
        "onUpdate:value": _ctx.onChange,
        "container-style": _ctx.cssInputContainer,
        "wrapper-style": _ctx.cssInputWrapper,
        "input-style": _ctx.cssInput
      }, null, 8, ["value", "onUpdate:value", "container-style", "wrapper-style", "input-style"])
    ])
  ], 4))
}