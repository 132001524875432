<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue'

import { QrSection, TextSectionContent } from '@/api/types'

const { textSection } = defineProps({
  textSection: {
    type: Object as PropType<QrSection<TextSectionContent, null>>,
    required: true
  }
})

const { section } = textSection

const formattedContent = computed(() => section.content.replace(/\n/g, '<br>'))

const cssVars = computed(() => {
  return {
    '--text-color': '#000000'
  }
})
</script>

<template >
    <div v-if="textSection" class="text-container d-flex flex-row justify-content-center px-3" :style="cssVars">
      <p class="content-text" v-html="formattedContent"></p>
    </div>
</template>

<style scoped lang="scss">
    div.text-container {
        width: 90%;
        background-color: transparent
    }

    p.content-text {
      font-size: small;
      font-family: inter;
      overflow-wrap: break-word;
      width: 100%;
      color: var(--text-color);
      text-align: center;
      margin: 0;
    }
</style>
