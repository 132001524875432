<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount, useTemplateRef } from 'vue'

import SvgButton from '@/components/button/SvgButton.vue'
import SvgLink from '@/components/button/SvgLink.vue'

const emit = defineEmits(['onDownload'])

const { qrUri } = defineProps({
  qrUri: {
    type: String,
    required: true
  }
})

const content = useTemplateRef('content')

const visible = ref(false)
const srcBtnImg = ref('qr-grad')
const btnBackground = ref('#FFFFFF')

const cssBtn = computed(() => {
  return {
    background: btnBackground.value,
    width: '1.024rem',
    height: '1.024rem',
    padding: '0.224rem',
    borderRadius: '0.256rem'
  }
})

const cssLink = computed(() => {
  return {
    display: 'flex',
    width: '100%',
    padding: '0.32rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.256rem',
    borderRadius: '0.256rem',
    background: 'var(--color-nearly-black)'
  }
})

const cssLinkIcon = computed(() => {
  return {
    width: '0.64rem',
    height: '0.64rem'
  }
})

const cssLinkText = computed(() => {
  return {
    color: 'var(--color-white)',
    fontFamily: 'Inter',
    fontSize: 'var(--font-size-13)',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px'
  }
})

function toggle () {
  visible.value = !visible.value
  btnBackground.value = visible.value ? 'linear-gradient(135deg, #2E75FF 0%, #7A3DFF 99.99%, #7B3DFF 100%)' : '#FFFFFF'
  srcBtnImg.value = visible.value ? 'qr-white' : 'qr-grad'
}

function onClickPng () {
  emit('onDownload', 'png')
}

function onClickSvg () {
  emit('onDownload', 'svg')
}

function handleClickOutside (event) {
  if (
    content.value === event.target ||
    event.composedPath().includes(content.value)
  ) {
    return
  }
  visible.value = false
  btnBackground.value = '#FFFFFF'
  srcBtnImg.value = 'qr-grad'
}

onMounted(() => document.addEventListener('click', handleClickOutside))
onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))
</script>

<template>
  <div v-if="visible" class="mask"/>
  <div ref="content" class="dropdown-qr-view-download-container">
      <SvgButton @click="toggle" v-bind:icon="srcBtnImg" v-bind:style="cssBtn"/>
      <div v-if="visible" class="dropdown-content">
          <div class="qr-container">
             <img class="qr-code" :src="qrUri" />
          </div>
          <SvgLink @click="onClickPng" icon="download-white" :style="{...cssLink, margin: '20px 0 10px 0'}" :icon-style="cssLinkIcon" :text-style="cssLinkText">Download PNG</SvgLink>
          <SvgLink @click="onClickSvg" icon="download-white" :style="cssLink" :icon-style="cssLinkIcon" :text-style="cssLinkText">Download SVG</SvgLink>
      </div>
  </div>
</template>

<style scoped>
  div.dropdown-qr-view-download-container {
    position: relative;
  }
  div.dropdown-content {
    position: absolute;
    top: 42px;
    left: -228px;
    min-width: 260px;
    flex-shrink: 0;
    padding: 20px;
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    z-index: inherit;
  }

  div.qr-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    padding: 0 45px 20px 45px;
  }
  img.qr-code {
    width: 100%;
    aspect-ratio: 1/1;
  }

  div.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: grey;
    opacity: 0.5;
  }
</style>
