import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  key: 2,
  class: "add-button-container align-self-start"
}
const _hoisted_3 = {
  key: 3,
  class: "add-button-container align-self-start"
}

import { computed, ref, PropType, onRenderTracked, onRenderTriggered } from 'vue'
import { QrSection, QrSectionTargetEnum, HeaderSectionContent } from '@/api/types'
import { useEditorStore } from '@/store'
import { Palette } from '@/theme'

import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import EditorAddLink from '../button/editor-panel/EditorAddLink.vue'
import EditorInputComponent from '@/components/input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderPanel',
  props: {
  headerSection: {
    type: Object as PropType<QrSection<HeaderSectionContent, null>>,
    required: true
  }
},
  setup(__props) {

const editorStore = useEditorStore()
const { patchQrSection } = editorStore



const { id, label, active, persistent, section } = __props.headerSection

const styleMode = ref(false)
const editingPreTitle = ref(false)
const editingSubTitle = ref(false)

const cssAddIcon = computed(() => {
  return {
    backgroundImage: Palette.gradientBP,
    width: '1.95em',
    borderRadius: '0.975em',
    padding: '0.5em',
    color: 'white'
  }
})

const cssAddText = computed(() => {
  return {
    backgroundImage: Palette.gradientBP,
    backgroundClip: 'text',
    backgroundSize: '100%',
    textFillColor: 'transparent'
  }
})

const cssDeleteButton = computed(() => {
  return {
    color: Palette.neutral800,
    backgroundColor: 'transparent'
  }
})

function onSelectPreTitle () {
  editingPreTitle.value = true
}

function onSelectSubTitle () {
  editingSubTitle.value = true
}

function onEditTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'title', value)
}

function onEditPreTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'preTitle', value)
}

function onEditSubTitle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'subTitle', value)
}

function onResetPreTitle () {
  editingPreTitle.value = false
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'preTitle', '')
}

function onResetSubTitle () {
  editingSubTitle.value = false
  patchQrSection(id, QrSectionTargetEnum.CONTENT, 'subTitle', '')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditorPanel, {
    id: _unref(id),
    title: _unref(label),
    active: _unref(active),
    persistent: _unref(persistent),
    styleMode: styleMode.value,
    hasStyle: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(DebouncedTextInput, {
          value: _unref(section).title,
          type: "text",
          placeholder: "title",
          "onUpdate:value": onEditTitle
        }, null, 8, ["value"]),
        (editingPreTitle.value || _unref(section).preTitle.length > 0)
          ? (_openBlock(), _createBlock(EditorInputComponent, {
              key: 0,
              label: "Pre-title",
              "bold-label": "",
              deleteable: "",
              value: _unref(section).preTitle,
              onOnChangeValue: onEditPreTitle,
              onOnDelete: onResetPreTitle
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (editingSubTitle.value || _unref(section).subTitle.length > 0)
          ? (_openBlock(), _createBlock(EditorInputComponent, {
              key: 1,
              label: "Sub-title",
              "bold-label": "",
              deleteable: "",
              value: _unref(section).subTitle,
              onOnChangeValue: onEditSubTitle,
              onOnDelete: onResetSubTitle
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (!(editingPreTitle.value || _unref(section).preTitle.length > 0))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(EditorAddLink, { onClick: onSelectPreTitle }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Add Pre-title")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!(editingSubTitle.value || _unref(section).subTitle.length > 0))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(EditorAddLink, { onClick: onSelectSubTitle }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Add Sub-title")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["id", "title", "active", "persistent", "styleMode"]))
}
}

})