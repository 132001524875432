<script setup lang="ts">
import { computed, ref } from 'vue'

import { storeToRefs } from 'pinia'

import { useEditorStore } from '@/store'

import PopupOverlay from '@/components/overlay/PopupOverlay.vue'
import SelectSocialButton from '@/components/button/SelectSocialButton.vue'

import 'vue-loading-overlay/dist/css/index.css'
import { SOCIAL_LINKS } from '@/constants'
import { Palette } from '@/theme'

const { visible } = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onDismiss', 'onAddSocials'])

const editorStore = useEditorStore()

const { selectedSocials } = storeToRefs(editorStore)
const { setSelectedQrSectionID, selectSocial, clearSocials, submitSocials } = editorStore

const socialTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'social')
})

const streamingTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'streaming')
})

const addText = computed(() => {
  return `Add Selected (${selectedSocials.value.length})`
})

function onSelect (type: string) {
  selectSocial(type)
}

function onDismiss () {
  clearSocials()
  setSelectedQrSectionID(-1)
  emit('onDismiss')
}

async function onSubmitSocials () {
  const status = await submitSocials()
  onDismiss()
}

</script>

<template>
    <PopupOverlay
        :visible="visible"
        title="Add Social Links"
        @onDismiss="onDismiss"
    >
    <div class="overlay-wrapper">
      <div class="overlay-content">
        <p class="pre-header-text">Select social profiles from the below to add to your page.</p>
        <p class="type-header-text">Social Media</p>
        <div class="overlay-select">
          <SelectSocialButton
            v-for="item in socialTypes"
            :key="item.type"
            :type="item.type"
            :label="item.label"
            :isSelected="selectedSocials.map(i => i.type).includes(item.type)"
            @onSelect="onSelect"
            />
            <SelectSocialButton
            v-for="item in streamingTypes"
            :key="item.type"
            :type="item.type"
            :label="item.label"
            :isSelected="selectedSocials.map(i => i.type).includes(item.type)"
            @onSelect="onSelect"
            />
        </div>
      </div>
      <div v-if="selectedSocials.length > 0" class="overlay-footer">
          <div class="button-unselect" @click="clearSocials">
            <p class="button-unselect-text">Unselect All</p>
          </div>
          <div class="button-add" @click="onSubmitSocials">
            <p class="button-add-text"> {{ addText }}</p>
          </div>
      </div>
    </div>
    </PopupOverlay>
</template>

<style scoped>

div.overlay-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
div.overlay-content {
  display: flex;
  flex-direction: column;
  padding: 0.64rem;
  flex: 1;
}

p.pre-header-text, p.type-header-text {
  color: var(--color-nearly-black);
  font-family: Inter;
  font-weight: 600;
}
p.pre-header-text {
  font-size: var(--font-size-13);
  opacity: 0.5;
}

p.type-header-text {
  font-size: var(--font-size-14);
}

div.overlay-select {
  display: flex;
  flex-wrap: wrap;
  gap: 0.38rem;
}

div.overlay-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.64rem;
  border-top: 1px solid rgba(33, 37, 41, 0.15);
}

div.button-unselect, div.button-add {
  padding: 0.42rem 0.64rem;
  border-radius: 0.25rem;;
  background-color: var(--color-light-grey);
  cursor: pointer;
}

div.button-unselect {
  background-color: var(--color-light-grey);
}
p.button-unselect-text, p.button-add-text {
  font-family: Inter;
  font-size: var(--font-size-14);
  font-weight: 500;
  margin: 0;
}

p.button-unselect-text {
  color: var(--color-nearly-black);
}
div.button-add {
  background: var(--color-grad-bp);
}
p.button-add-text {
  color: var(--color-white);
}
</style>
