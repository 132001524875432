export interface QrSectionItem<C> {
    id: number,
    listOrder: number,
    content: C
}

export interface AmazonReviewCTASectionContent {
    title: string
    subtitle: string
    btnText: string
    amazonUrl: string
}

export interface AmazonReviewCTASectionStyle {
    theme: string
}

export interface ButtonSectionContent {
    text: string
    url: string
}

export interface ButtonSectionStyle {
    textColor: string
    fillColor: string
    style: string
    corners: string
    size: string
    align: string
}

export interface EmbeddedVideoSectionContent {
    url: string
}

export interface EmbeddedVideoSectionStyle {
    controls: string,
    thumbnail: string,
    thumbnailUri: string
}

export interface FeaturesSectionItemContent {
    value: string
}

export interface FeaturesSectionContent {
    items: QrSectionItem<FeaturesSectionItemContent>[]
}

export interface FeaturesSectionStyle {
    layout: string
    fillColor: string
}

export interface HeaderSectionContent {
    title: string
    preTitle: string
    subTitle: string
}

export interface ImageSectionContent {
    uri: string
}

export interface LinkSectionContent {
    text: string
    url: string
}

export interface LinkSectionStyle {
    align: string
    bold: boolean
    italic: boolean
    underline: boolean
    size: number
    textColor: string
    backgroundColor: string
    spacingLeft: number
    spacingTop: number
    spacingRight: number
    spacingBottom: number
}

export interface NewsSectionItemContent {
    imageUri: string
    title: string
    subtitle: string
    url: string
}

export interface NewsSectionContent {
    items: QrSectionItem<NewsSectionItemContent>[]
}

export interface NewsSectionStyle {
    textColor: string
    backgroundColor: string
    size: string
    align: string
    imageStyle: string
}

export interface SocialsSectionItemContent {
    type: string
    value: string
}

export interface SocialsSectionContent {
    items: QrSectionItem<SocialsSectionItemContent>[]
}

export interface SocialsSectionStyle {
    layout: string
    style: string
    corners: string
    size: string
    align: string
    logoTheme: string
    logoColor: string
    backgroundColor: string
    spacingLeft: number
    spacingTop: number
    spacingRight: number
    spacingBottom: number
}

export interface TableSectionContent {
    value: string[][]
}

export interface TableSectionStyle {
    header: string
    border: number
}

export interface TextSectionContent {
    content: string
}

export interface QrSection<C, S> {
    id: number
    type: number
    label: string
    listOrder: number
    active: boolean
    persistent: boolean
    labelVisible: boolean
    section: C
    style: S
}

export type QrSectionItemContentType =
    FeaturesSectionItemContent |
    NewsSectionItemContent |
    SocialsSectionItemContent

export type QrSectionContentType =
    AmazonReviewCTASectionContent |
    ButtonSectionContent |
    EmbeddedVideoSectionContent |
    FeaturesSectionContent |
    HeaderSectionContent |
    ImageSectionContent |
    LinkSectionContent |
    NewsSectionContent |
    SocialsSectionContent |
    TableSectionContent |
    TextSectionContent

export type QrSectionStyleType =
    AmazonReviewCTASectionStyle |
    ButtonSectionStyle |
    EmbeddedVideoSectionStyle |
    FeaturesSectionStyle |
    LinkSectionStyle |
    NewsSectionStyle |
    SocialsSectionStyle |
    TableSectionStyle

export type QrSectionItemType = QrSectionItem<QrSectionItemContentType>
export type QrSectionType = QrSection<QrSectionContentType, QrSectionStyleType>

export interface QrSectionItemIterable {
    items: QrSectionItemType[]
}

export enum QrSectionTypeEnum {
    IMAGE = 0,
    HEADER = 1,
    TEXT = 2,
    SOCIALS = 4,
    TABLE = 5,
    FEATURES = 6,
    AMAZAON_REVIEW_CTA = 7,
    LINK = 8,
    BUTTON = 9,
    EMBEDDED_VIDEO = 10,
    NEWS = 11
}

export enum QrSectionTargetEnum {
    CONTENT = 'content',
    HEADER = 'header',
    STYLE = 'style'
}

export enum QrDownloadTypeEnum {
    PNG = 'png',
    SVG = 'svg'
}

export interface ListSubProfile {
    id: string
    label: string
    imageUri: string
    qrUri: string
    active: boolean
    isRootProfile: boolean
}

export interface ListSubProfileResponse {
    subProfiles: ListSubProfile[]
}

export interface QrRedirect {
    url: string
    active: boolean
}

export interface SubProfile {
    id: string
    keypin: string
    name: string
    label: string
    qrUri: string
    qrRedirect: QrRedirect
    sections: QrSectionType[]
}

export interface UserSubProfile extends SubProfile {
    active: boolean,
    isRootProfile: boolean
}

export interface SubProfileQrResponse {
    subProfile: SubProfile
    qrScanId: number,
    accessType: string
}
