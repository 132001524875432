import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, PropType } from 'vue'

import { QrSection, TableSectionContent, TableSectionStyle } from '@/api/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'TableSection',
  props: {
  tableSection: {
    type: Object as PropType<QrSection<TableSectionContent, TableSectionStyle>>,
    required: true
  }
},
  setup(__props) {



const { section, style } = __props.tableSection

const cssVarsTL = computed(() => {
  return {
    '--opacity': '1.0',
    '--border-bottom': (style.header === 'row' || style.border === 0) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-top': style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-left': (style.border === 0 || style.border === 1) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsTC = computed(() => {
  return {
    '--opacity': style.header === 'row' ? '1.0' : '0.5',
    '--border-bottom': style.header === 'row' ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-top': style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsTR = computed(() => {
  return {
    '--opacity': style.header === 'row' ? '1.0' : '0.5',
    '--border-bottom': (style.header === 'row' || style.border === 0) ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-top': style.border in [0, 1, 3] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsLC = computed(() => {
  return {
    '--opacity': style.header === 'col' ? '1.0' : '0.5',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-bottom': style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-left': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsCC = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsRC = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border === 0 ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsBL = computed(() => {
  return {
    '--opacity': style.header === 'col' ? '1.0' : '0.5',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-bottom': style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-left': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsBC = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 4] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

const cssVarsBR = computed(() => {
  return {
    '--opacity': '0.5',
    '--border-bottom': style.border in [0, 1, 2] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none',
    '--border-right': style.border in [0, 1] ? '1px solid rgba(0, 0, 0, 0.20)' : 'none'
  }
})

return (_ctx: any,_cache: any) => {
  return (__props.tableSection)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "table-container d-flex flex-row justify-content-center",
        style: _normalizeStyle(_ctx.cssVars)
      }, [
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                style: _normalizeStyle(cssVarsTL.value)
              }, _toDisplayString(_unref(section).value[0][0]), 5),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).value[0].slice(1, -1), (header, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: index,
                  style: _normalizeStyle(cssVarsTC.value)
                }, _toDisplayString(header), 5))
              }), 128)),
              _createElementVNode("th", {
                style: _normalizeStyle(cssVarsTR.value)
              }, _toDisplayString(_unref(section).value[0].slice(-1)[0]), 5)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).value.slice(1, -1), (row, rowIndex) => {
              return (_openBlock(), _createElementBlock("tr", { key: rowIndex }, [
                _createElementVNode("td", {
                  style: _normalizeStyle(cssVarsLC.value)
                }, _toDisplayString(row[0]), 5),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.slice(1, -1), (cell, cellIndex) => {
                  return (_openBlock(), _createElementBlock("td", {
                    style: _normalizeStyle(cssVarsCC.value),
                    key: cellIndex
                  }, _toDisplayString(cell), 5))
                }), 128)),
                _createElementVNode("td", {
                  style: _normalizeStyle(cssVarsRC.value)
                }, _toDisplayString(row.slice(-1)[0]), 5)
              ]))
            }), 128)),
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                style: _normalizeStyle(cssVarsBL.value)
              }, _toDisplayString(_unref(section).value[_unref(section).value.length - 1][0]), 5),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(section).value[_unref(section).value.length - 1].slice(1, -1), (cell, cellIndex) => {
                return (_openBlock(), _createElementBlock("td", {
                  style: _normalizeStyle(cssVarsBC.value),
                  key: cellIndex
                }, _toDisplayString(cell), 5))
              }), 128)),
              _createElementVNode("td", {
                style: _normalizeStyle(cssVarsBR.value)
              }, _toDisplayString(_unref(section).value[_unref(section).value.length - 1].slice(-1)[0]), 5)
            ])
          ])
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})