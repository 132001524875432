<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import ManageCreateQrButton from '@/components/button/ManageCreateQrButton.vue'
import SubNav from '@/components/nav/SubNav.vue'
import QRGrid from '@/components/dashboard/QRGrid.vue'
import QRList from '@/components/dashboard/QRList.vue'
import SearchInput from '@/components/input/SearchInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import ViewSelect from '@/components/select/ViewSelect.vue'

import { ListSubProfile } from '@/api/types'

import { useDashboardStore, useEditorStore } from '@/store'

const router = useRouter()

const dashboardStore = useDashboardStore()
const { subProfiles, qrData } = storeToRefs(dashboardStore)
const { fetchListSubProfiles, createSubProfile, deleteSubProfile, getQrDownload } = dashboardStore

const editorStore = useEditorStore()
const { subProfileID } = storeToRefs(editorStore)

const tabs = ref(['All QRs', 'Published', 'Unpublished', 'Archived'])
const currentTab = ref(0)
const scrolling = ref(false)

const searchText = ref('')
const sortBy = ref('Newest')
const sortByOptions = ref([
  'Newest',
  'A-Z'
])
const isGrid = ref(true)

function selectTab (value: number) {
  currentTab.value = value
}

function updateScroll () {
  scrolling.value = window.scrollY > 150
}

function getVisibleSubProfiles () {
  let visibleSubProfiles = subProfiles.value
  if (currentTab.value === 1) visibleSubProfiles = subProfiles.value.filter((sp: ListSubProfile) => sp.active)
  else if (currentTab.value === 2) visibleSubProfiles = subProfiles.value.filter((sp: ListSubProfile) => !sp.active)

  if (searchText.value.length > 0) visibleSubProfiles = visibleSubProfiles.filter((sp: ListSubProfile) => sp.label.toLowerCase().includes(searchText.value.toLowerCase()))
  if (sortBy.value === 'Newest') return visibleSubProfiles.sort(function (a: ListSubProfile, b: ListSubProfile) { return b.id.localeCompare(a.id) })
  else if (sortBy.value === 'A-Z') return visibleSubProfiles.sort(function (a: ListSubProfile, b: ListSubProfile) { return a.label.localeCompare(b.label) })
  return visibleSubProfiles
}

async function onCreateSubProfile () {
  const status = await createSubProfile()
  if (status !== 'ok') return
  router.push(`/dashboard/${subProfileID.value}`)
}

function onSelectSubProfile (id: string) {
  router.push(`/dashboard/${encodeURIComponent(id)}`)
}

async function onDeleteSubProfile (id: string) {
  await deleteSubProfile(id)
}

async function onDownloadQR (type: string, subProfileID: string) {
  const status = await getQrDownload(subProfileID, type)
  if (status !== 200) return

  const href = URL.createObjectURL(qrData.value)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', subProfileID + '.' + type)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

onMounted(async () => {
  window.addEventListener('scroll', updateScroll)
  const status = await fetchListSubProfiles()
  if (status === 'unauthorized') router.push('/login')
})

onUnmounted(() => window.removeEventListener('scroll', updateScroll))

</script>

<template>
    <div class="view-container">
      <div class="view-content container-fluid">
        <div v-if="scrolling" class="row top-bar-scroll">
          <div class="col-xl-2 col-6 d-flex align-items-center p-0">
            <h1 class="page-title page-title-scrolling">Keypins</h1>
          </div>
          <div class="col-xl-3 col-lg-4 d-sm-flex d-none align-items-center">
            <SearchInput
              v-model="searchText"
              placeholder="Search your QR Codes..."
              :wrapper-style="{width: '100%'}"
              :container-style="{width: '100%'}"/>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex align-items-center">
            <SelectInput :options="sortByOptions" v-model="sortBy" label="Sort by" layout="row"/>
          </div>
          <div class="col-xl-2 col-lg-4 col-sm-6 col-12 d-flex align-items-center">
            <ViewSelect layout="row" v-model="isGrid"/>
          </div>
          <div class="d-lg-flex d-none col-2">
            <ManageCreateQrButton @click="onCreateSubProfile"/>
          </div>
        </div>
        <div v-else class="d-flex flex-row">
          <div style="flex: 1">
            <h1 class="page-title page-title-fixed">Keypins</h1>
          </div>
          <div style="width: 50%; max-width: 200px; justify-self: end;">
            <ManageCreateQrButton @click="onCreateSubProfile"/>
          </div>
        </div>
        <div class="row sub-nav-container">
            <SubNav :tabs="tabs" :current-tab="currentTab" @selectTab="selectTab"/>
        </div>
        <div  class="row search-select-container">
          <div class="search-container col-xl-6 col-lg-4 d-sm-block d-none">
            <SearchInput
              v-model="searchText"
              placeholder="Search your QR Codes..."/>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-6 col-12">
              <SelectInput :options="sortByOptions" v-model="sortBy" label="Sort by:" layout="row"/>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-6 col-12">
            <ViewSelect layout="row" v-model="isGrid"/>
          </div>
        </div>
        <div class="row">
          <div class="qr-list-container">
            <QRGrid v-if="isGrid"
              @onSelect="onSelectSubProfile"
              @onDelete="onDeleteSubProfile"
              @onDownload="onDownloadQR"
              :subProfiles="getVisibleSubProfiles()"
            />
            <QRList v-else
             @onSelect="onSelectSubProfile"
             @onDelete="onDeleteSubProfile"
             @onDownload="onDownloadQR"
              :subProfiles="getVisibleSubProfiles()"
              />
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">

h1.page-title {
  color: var(--color-nearly-black);
  font-family: Inter;
  font-weight: 800;
  width: 100%;
  text-align: left;
  margin: 0;
}

h1.page-title-fixed{
  font-size: 30px;
  line-height: 38px; /* 126.667% */
}

h1.page-title-scrolling{
  font-size: 20px;
  line-height: 10px; /* 100% */
  margin: 0;
}

div.top-bar-scroll {
  position: sticky;
  top: 0;
  z-index: 1;
  width: calc(100% + 2 * var(--main-padding));
  margin: 0 0 0 calc(-1 * var(--main-padding));
  padding: 10px var(--main-padding) 10px var(--main-padding);
  background-color: #FFF;
  box-shadow: 0px 10px 10px -15px #111;
}

div.sub-nav-container {
  margin: 1.34rem 0 0 0;
  width: 100%;
}

div.search-select-container {
  margin: 0.96rem 0 0 0;
}

div.search-container {
  padding: 0;
}

.create-qr-button-icon {
  margin-right: 0.5rem;
}
div.qr-list-container {
  width: 100%;
  margin-top: 0.96rem;
}
</style>
