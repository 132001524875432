import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "overlay-wrapper" }
const _hoisted_2 = { class: "overlay-content" }
const _hoisted_3 = { class: "overlay-select" }
const _hoisted_4 = {
  key: 0,
  class: "overlay-footer"
}
const _hoisted_5 = { class: "button-add-text" }

import { computed, ref } from 'vue'

import { storeToRefs } from 'pinia'

import { useEditorStore } from '@/store'

import PopupOverlay from '@/components/overlay/PopupOverlay.vue'
import SelectSocialButton from '@/components/button/SelectSocialButton.vue'

import 'vue-loading-overlay/dist/css/index.css'
import { SOCIAL_LINKS } from '@/constants'
import { Palette } from '@/theme'


export default /*@__PURE__*/_defineComponent({
  __name: 'AddSocialsPopup',
  props: {
  visible: {
    type: Boolean,
    default: false
  }
},
  emits: ['onDismiss', 'onAddSocials'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const editorStore = useEditorStore()

const { selectedSocials } = storeToRefs(editorStore)
const { setSelectedQrSectionID, selectSocial, clearSocials, submitSocials } = editorStore

const socialTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'social')
})

const streamingTypes = computed(() => {
  return SOCIAL_LINKS.filter(item => item.category === 'streaming')
})

const addText = computed(() => {
  return `Add Selected (${selectedSocials.value.length})`
})

function onSelect (type: string) {
  selectSocial(type)
}

function onDismiss () {
  clearSocials()
  setSelectedQrSectionID(-1)
  emit('onDismiss')
}

async function onSubmitSocials () {
  const status = await submitSocials()
  onDismiss()
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PopupOverlay, {
    visible: __props.visible,
    title: "Add Social Links",
    onOnDismiss: onDismiss
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "pre-header-text" }, "Select social profiles from the below to add to your page.", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "type-header-text" }, "Social Media", -1)),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(socialTypes.value, (item) => {
              return (_openBlock(), _createBlock(SelectSocialButton, {
                key: item.type,
                type: item.type,
                label: item.label,
                isSelected: _unref(selectedSocials).map(i => i.type).includes(item.type),
                onOnSelect: onSelect
              }, null, 8, ["type", "label", "isSelected"]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(streamingTypes.value, (item) => {
              return (_openBlock(), _createBlock(SelectSocialButton, {
                key: item.type,
                type: item.type,
                label: item.label,
                isSelected: _unref(selectedSocials).map(i => i.type).includes(item.type),
                onOnSelect: onSelect
              }, null, 8, ["type", "label", "isSelected"]))
            }), 128))
          ])
        ]),
        (_unref(selectedSocials).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "button-unselect",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(clearSocials) && _unref(clearSocials)(...args)))
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("p", { class: "button-unselect-text" }, "Unselect All", -1)
              ])),
              _createElementVNode("div", {
                class: "button-add",
                onClick: onSubmitSocials
              }, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(addText.value), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})