<script setup lang="ts">
import { computed, ref, useTemplateRef, onMounted, onBeforeUnmount } from 'vue'

import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import {
  AmazonReviewCTASectionContent,
  AmazonReviewCTASectionStyle,
  ButtonSectionContent,
  ButtonSectionStyle,
  EmbeddedVideoSectionContent,
  EmbeddedVideoSectionStyle,
  FeaturesSectionContent,
  FeaturesSectionStyle,
  HeaderSectionContent,
  ImageSectionContent,
  LinkSectionContent,
  LinkSectionStyle,
  NewsSectionContent,
  NewsSectionStyle,
  QrSection,
  QrSectionTypeEnum,
  SocialsSectionContent,
  SocialsSectionStyle,
  TableSectionContent,
  TableSectionStyle,
  TextSectionContent
} from '@/api/types'
import { useDashboardStore, useEditorStore } from '@/store'

import AddSocialsPopup from '@/components/editor/AddSocialsPopup.vue'
import AmazonReviewCTAPanel from '@/components/editor/AmazonReviewCTAPanel.vue'
import ButtonPanel from '@/components/editor/ButtonPanel.vue'
import DebouncedTextInput from '@/components/input/DebouncedTextInput.vue'
import DropdownQrAddSection from '@/components/dropdown/DropdownQrAddSection.vue'
import DropdownQrViewDownload from '@/components/dropdown/DropdownQrViewDownload.vue'
import DropToggle from '@/components/switch/DropToggle.vue'
import EmbeddedVideoPanel from '@/components/editor/EmbeddedVideoPanel.vue'
import FeaturesPanel from '@/components/editor/FeaturesPanel.vue'
import ImagePanel from '@/components/editor/ImagePanel.vue'
import HeaderPanel from '@/components/editor/HeaderPanel.vue'
import LinkPanel from '@/components/editor/LinkPanel.vue'
import NewsPanel from '@/components/editor/NewsPanel.vue'
import SocialsPanel from '@/components/editor/SocialsPanel.vue'
import TablePanel from '@/components/editor/TablePanel.vue'
import TextPanel from '@/components/editor/TextPanel.vue'

import PreviewPanel from '@/components/editor/PreviewPanel.vue'
import SvgButton from '@/components/button/SvgButton.vue'
import TextClipboard from '@/components/text/TextClipboard.vue'

import { Palette } from '@/theme'

import { SlickList, SlickItem } from 'vue-slicksort'

const route = useRoute()
const router = useRouter()

const dashboardStore = useDashboardStore()
const { qrData } = storeToRefs(dashboardStore)
const { getQrDownload } = dashboardStore

const editorStore = useEditorStore()
const {
  subProfile
} = storeToRefs(editorStore)
const {
  resetSubProfile,
  onChangeSectionOrderStart,
  fetchUserSubProfile,
  toggleSubProfileActive,
  postQrSection,
  putQrSectionOrder,
  editRedirectUrl,
  editRedirectActive,
  resetRedirectUrl,
  getQrUrl
} = editorStore

const downloadContent = useTemplateRef('content')

const addSocialsPopupVisible = ref(false)
const contentActive = ref(false)
const downloadDropdownVisible = ref(false)
const previewViewport = ref('mobile')

const dropdownCssVars = computed(() => {
  return {
    '--background-color': Palette.light_grey,
    '--color': Palette.white
  }
})

const cssBackButton = computed(() => {
  return {
    backgroundColor: 'transparent',
    color: Palette.neutral800
  }
})

const qrUrl = computed(getQrUrl)

function toggleDownloadDropdown () {
  contentActive.value = !contentActive.value
}

function handleClickOutside (event) {
  if (
    downloadContent.value &&
    !event.composedPath().includes(downloadContent.value)
  ) {
    downloadDropdownVisible.value = false
  }
}

function onSelectViewport (value: string) {
  previewViewport.value = value
}

function onAddSocials () {
  addSocialsPopupVisible.value = true
}

function onDismissSocialsPopup () {
  addSocialsPopupVisible.value = false
}

/**
 * API CALLS
 */
async function onFetchUserSubProfile () {
  resetSubProfile()
  const status = await fetchUserSubProfile(String(route.params.id))
  if (status !== 'ok') {
    router.push('/not-found')
  }
  console.log('ne' + JSON.stringify(subProfile.value))
}

async function onToggleActive () {
  const status = await toggleSubProfileActive()
  if (status !== 'ok') {
    // return
  }
}

async function onCreateQrSection (type: number) {
  const status = await postQrSection(type)
  if (status !== 'ok') {
    // return
  }
}

async function onEditQrRedirectUrl (value: string) {
  const status = await editRedirectUrl(value)
  if (status !== 'ok') {
    // return
  }
}

async function onEditQrRedirectActive (value: boolean) {
  const status = await editRedirectActive(value)
  if (status !== 'ok') {
    // return
  }
}

async function onResetQrRedirectUrl () {
  const status = await resetRedirectUrl()
  if (status !== 'ok') {
    // return
  }
}

async function onDownloadQR (type: string) {
  const { id } = subProfile.value
  const status = await getQrDownload(id, type)
  if (status !== 200) return

  const href = URL.createObjectURL(qrData.value)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', id + '.' + type)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

router.beforeEach((to, from, next) => {
  if (contentActive.value) {
    contentActive.value = false
    next(false)
  } else {
    next()
  }
})

onMounted(onFetchUserSubProfile)
onBeforeUnmount(() => document.removeEventListener('click', handleClickOutside))
/*
    onToggleMode (id: number, mode: boolean) {
      if (mode) this.styleModeActive.push(id)
      else {
        this.styleModeActive.forEach((item, index) => {
          if (item === id) this.styleModeActive.splice(index, 1)
        })
      }
    },
}) */

</script>

<template>
  <div v-if="subProfile" class="view-container">
    <div class="view-content container-fluid">
      <div class="top-bar-scroll">
        <div class="back-button-container">
          <SvgButton  @click="() => router.replace('/dashboard')" icon="chevron-left" :style="cssBackButton"/>
        </div>
        <div class="sub=-profile-label-container">
          <h4 class="sub-profile-label-text">{{ subProfile.label }}</h4>
        </div>
        <div class="qr-switch-download-container">
          <div class="d-flex flex-row align-items-center">
            <div class="sub-profile-url-container">
              <TextClipboard :text="qrUrl"/>
            </div>
            <div class="sub-profile-qr-view-download-container">
              <DropdownQrViewDownload :qrUri="subProfile.qrUri" @onDownload="onDownloadQR"/>
            </div>
            <div class="sub-profile-status-toggle-container">
              <DropToggle
                v-if="!subProfile.isRootProfile"
                on-text="Online"
                off-text="Offline"
                :initial-value="subProfile.active"
                @update:model-value="onToggleActive"/>
            </div>
          </div>
          <div v-if="false && !subProfile.isRootProfile" class="d-flex flex-row align-items-end mt-3" style="background-color: lightgray; padding: 8px; border-radius: 8px;">
            <div class="d-flex flex-column align-items-start" style="flex: 1; margin: 0 8px 0 0">
              <p style="margin: 0 0 0 2px">Redirect to:</p>
              <DebouncedTextInput :value="subProfile.qrRedirect.url" type="text" placeholder="title" @update:value="onEditQrRedirectUrl"/>
            </div>
          <div class="sub-profile-status-toggle-container">
            <DropToggle
              on-text="On"
              off-text="Off"
              :initial-value="subProfile.qrRedirect.active"
              @update:model-value="onEditQrRedirectActive"/>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-end" style="width: 100%;">
      <div class="d-flex flex-column pb-5 panel-list-container" style="flex-grow: 1; width: 40vw">
            <SlickList axis="y" v-model:list="subProfile.sections" @update:list="putQrSectionOrder" @sort-start="onChangeSectionOrderStart" :useDragHandle="true">
              <SlickItem v-for="(section, i) in subProfile.sections" :key="section" :index="i">
                <div>
                  <AmazonReviewCTAPanel
                    v-if="section.type === QrSectionTypeEnum.AMAZAON_REVIEW_CTA"
                    :amazonReviewCTASection="(section as unknown as QrSection<AmazonReviewCTASectionContent, AmazonReviewCTASectionStyle>)"
                  />
                  <ButtonPanel
                    v-else-if="section.type === QrSectionTypeEnum.BUTTON"
                    :buttonSection="(section as unknown as QrSection<ButtonSectionContent, ButtonSectionStyle>)"
                  />
                  <EmbeddedVideoPanel
                    v-else-if="section.type === QrSectionTypeEnum.EMBEDDED_VIDEO"
                    :embeddedVideoSection="(section as unknown as QrSection<EmbeddedVideoSectionContent, EmbeddedVideoSectionStyle>)"
                  />
                  <FeaturesPanel
                    v-else-if="section.type === QrSectionTypeEnum.FEATURES"
                    :featuresSection="(section as unknown as QrSection<FeaturesSectionContent, FeaturesSectionStyle>)"
                  />
                  <HeaderPanel
                    v-else-if="section.type === QrSectionTypeEnum.HEADER"
                    :headerSection="(section as unknown as QrSection<HeaderSectionContent, null>)"
                  />
                  <ImagePanel
                    v-if="section.type === QrSectionTypeEnum.IMAGE"
                    :imageSection="(section as unknown as QrSection<ImageSectionContent, null>)"
                  />
                  <LinkPanel
                    v-else-if="section.type === QrSectionTypeEnum.LINK"
                    :linkSection="(section as unknown as QrSection<LinkSectionContent, LinkSectionStyle>)"
                    />
                  <NewsPanel
                    v-else-if="section.type === QrSectionTypeEnum.NEWS"
                    :newsSection="(section as unknown as QrSection<NewsSectionContent, NewsSectionStyle>)"
                  />
                  <SocialsPanel
                    v-else-if="section.type === QrSectionTypeEnum.SOCIALS"
                    @onAddSocials="onAddSocials"
                    :socialsSection="(section as unknown as QrSection<SocialsSectionContent, SocialsSectionStyle>)"
                  />
                  <TablePanel
                    v-else-if="section.type === QrSectionTypeEnum.TABLE"
                    :tableSection="(section as unknown as QrSection<TableSectionContent, TableSectionStyle>)"
                  />
                  <TextPanel
                    :ref="'textPanel' + section.id.toString()"
                    v-else-if="section.type === QrSectionTypeEnum.TEXT"
                    :textSection="(section as unknown as QrSection<TextSectionContent, null>)"
                  />
                </div>
              </SlickItem>
            </SlickList>
            <div class="create-section-button-container mt-4">
              <DropdownQrAddSection
                @onClickLink="onCreateQrSection"
                @onAddSocials="onAddSocials"
              />
            </div>
      </div>
      <div class="preview-panel-container">
            <PreviewPanel :subProfile="subProfile" :viewport="previewViewport" @onSelectViewport="onSelectViewport"/>
      </div>
    </div>
  </div>
</div>
  <AddSocialsPopup
    :visible="addSocialsPopupVisible"
    @onDismiss="onDismissSocialsPopup"/>
</template>

<style scoped lang="scss">

/* Override */
div.view-container {
  padding-top: 0;
}

div.top-bar-scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  width: calc(100% + 2 * var(--main-padding));
  margin: 0 0 0 calc(-1 * var(--main-padding));
  padding: 10px 1.92rem 10px 0;
  height: 2.55rem;
  background-color: var(--main-background-color);
}

div.back-button-container {
  width: var(--main-padding);
  display: flex;
  justify-content: center;
}

h4.sub-profile-label-text {
  text-align: left;
  margin: 0;
  color: var(--color-nearly-black);
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px; /* 130% */
}

div.qr-switch-download-container {
  margin: 0 0 0 auto;
}

div.sub-profile-url-container {
  margin: 0 8px 0 0;
}

div.sub-profile-qr-view-download-container {
  margin: 0 8px 0 0;
  z-index: 100;
}

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    position: fixed;
    top: 2.5em;
    background-color: var(--background-color);
    color: var(--color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: inherit;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .qr-image{
    width: 50%;
    aspect-ratio: 1/1;
  }

  div.qr-image-container {
    background-color: white;
    border-radius: 1em;
  }
  div.download-qrs{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  a{
    width: 50%;
    float: left;
  }
  div.create-section-button-container {
    width: 25%;
  }

  div.preview-panel-container {
    min-width: 370px;
    margin: 0 60px 0 0;
  }
</style>-->
