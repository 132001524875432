<script setup lang="ts">
import { computed, ref, PropType } from 'vue'

import { QrSection, QrSectionTargetEnum, NewsSectionContent, NewsSectionStyle, QrSectionItemType } from '@/api/types'
import { useEditorStore } from '@/store'

import ColorInput from '@/components/input/ColorInput.vue'
import CrossButton from '../button/CrossButton.vue'
import DebouncedTextInput from '../input/DebouncedTextInput.vue'
import EditorInputComponent from '../input/EditorInputComponent.vue'
import EditorPanel from '@/components/editor/EditorPanel.vue'
import FaIconLink from '../button/FaIconLink.vue'
import ImageInput from '../input/ImageInput.vue'
import StyleIconButton from '../button/style-panel/StyleIconButton.vue'
import StyleTextButton from '../button/style-panel/StyleTextButton.vue'
import SwitchComponent from '../switch/Switch.vue'
import EditorAddLink from '@/components/button/editor-panel/EditorAddLink.vue'

import { SlickItem, SlickList } from 'vue-slicksort'
import NewsInputComponent from '../input/NewsInputComponent.vue'

const editorStore = useEditorStore()
const { patchQrSection, postQrSectionItem, patchQrSectionItem, putQrSectionItemOrder, deleteQrSectionItem, postQrSectionItemImage } = editorStore

const emit = defineEmits(['onToggleMode'])

const { newsSection } = defineProps({
  newsSection: {
    type: Object as PropType<QrSection<NewsSectionContent, NewsSectionStyle>>,
    required: true
  }
})

const styleMode = ref(false)
const drag = ref(false)

const { id, label, labelVisible, active, persistent, section, style } = newsSection

const isLabelVisible = ref(labelVisible)

function onToggleMode (shouldEmit = false) {
  styleMode.value = !styleMode.value
  if (shouldEmit) {
    emit('onToggleMode', id, styleMode.value)
  }
}

function onCreateItem () {
  postQrSectionItem(id)
}

function onEditLabel (value: string) {
  patchQrSection(id, QrSectionTargetEnum.HEADER, 'label', value)
}

function onSetLabelVisible (value: boolean) {
  patchQrSection(id, QrSectionTargetEnum.HEADER, 'labelVisible', value)
}

function onEditNewsItemImage (itemID: number, file: File) {
  postQrSectionItemImage(id, itemID, 'imageUri', file)
}

function onEditNewsItemTitle (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'title', value)
}

function onEditNewsItemSubTitle (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'subTitle', value)
}

function onEditNewsItemUrl (itemID: number, value: string) {
  patchQrSectionItem(id, itemID, 'url', value)
}

function onChangeItemOrder (items: QrSectionItemType[]) {
  drag.value = false
  putQrSectionItemOrder(id, items)
}

function onDeleteNewsItem (itemID: number) {
  deleteQrSectionItem(id, itemID)
}

function onEditTextColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'textColor', value)
}

function onEditBackgroundColor (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'backgroundColor', value)
}

function onEditSize (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'size', value)
}

function onEditAlign (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'align', value)
}

function onEditImageStyle (value: string) {
  patchQrSection(id, QrSectionTargetEnum.STYLE, 'imageStyle', value)
}
</script>

<template>
    <EditorPanel
      :id="id"
      :title="label"
      :active="active"
      :persistent="persistent"
      :styleMode="styleMode"
      @onToggleMode="onToggleMode"
      >
      <div v-if="!styleMode" class="d-flex flex-column">
        <EditorInputComponent
          label="Title"
          toggleable
          :value="newsSection.label"
          :active="newsSection.labelVisible"
          @onChangeValue="onEditLabel"
          @onSetActive="onSetLabelVisible"
          />
        <SlickList axis="y" v-model:list="section.items" @update:list="onChangeItemOrder" :useDragHandle="true">
                <SlickItem v-for="(item, i) in section.items" :key="item" :index="i">
                  <NewsInputComponent
                  :title="item.content.title"
                  :subTitle="item.content.subtitle"
                  :url="item.content.url"
                  :imageUri="item.content.imageUri"
                  @update:title="(value: string) => onEditNewsItemTitle(item.id, value)"
                  @update:subtitle="(value: string) => onEditNewsItemSubTitle(item.id, value)"
                  @update:url="(value: string) => onEditNewsItemUrl(item.id, value)"
                  @update:image="(file: File) => onEditNewsItemImage(item.id, file)"
                  @delete="() => onDeleteNewsItem(item.id)"
                  />
              </SlickItem>
          </SlickList>
          <div class="my-2 align-self-start">
            <EditorAddLink @click="onCreateItem">Add item</EditorAddLink>
            </div>
          </div>
          <div v-else class="d-flex flex-column news-style-panel-content">
            <div class="d-flex news-style-panel-row align-items-center">
              <p class="style-header d-flex flex-fill">Color</p>
              <div class="d-flex align-items-center news-style-panel-row-group-br" style="padding: 0 10px 0 0">
                <ColorInput :label="'Text'" :value="newsSection.style.textColor" @onChangeColor="onEditTextColor"/>
              </div>
              <div class="d-flex" style="padding: 0 0 0 10px">
                <ColorInput :label="'Fill'" :value="newsSection.style.backgroundColor" @onChangeColor="onEditBackgroundColor"/>
              </div>
            </div>
            <div class="d-flex news-style-panel-row align-items-center">
              <p class="style-header d-flex flex-fill">Size & Align</p>
              <div class="d-flex align-items-center news-style-panel-row-group-br" style="padding: 0 10px 0 2px">
                <StyleTextButton
                  :selected="newsSection.style.size == 'sm'"
                  @click="() => onEditSize('sm')"
                  style="width: 30px; height: 30px">
                  S
                </StyleTextButton>
                <StyleTextButton
                  :selected="newsSection.style.size == 'md'"
                  @click="() => onEditSize('md')"
                  style="width: 36px; height: 36px;">
                  M
                </StyleTextButton>
                <StyleTextButton
                  :selected="newsSection.style.size == 'lg'"
                  @click="() => onEditSize('lg')"
                  style="width: 40px;">
                  L
                </StyleTextButton>
              </div>
              <div class="d-flex align-items-center" style="padding: 0 10px 0 2px">
                <StyleIconButton :icon="'style-align-left'" :selected="newsSection.style.align == 'left'" @click="() => onEditAlign('left')"/>
                <StyleIconButton :icon="'style-align-center'" :selected="newsSection.style.align == 'center'" @click="() => onEditAlign('center')"/>
                <StyleIconButton :icon="'style-align-right'" :selected="newsSection.style.align == 'right'" @click="() => onEditAlign('right')"/>
                <StyleIconButton :icon="'style-align-fill'" :selected="newsSection.style.align == 'fill'" @click="() => onEditAlign('fill')"/>
              </div>
            </div>
            <div class="d-flex news-style-panel-row align-items-center">
              <p class="style-header d-flex flex-fill">Image Style</p>
              <div class="d-flex">
                <StyleTextButton :icon="'style-layout-list'" :selected="newsSection.style.imageStyle === 'square'" @click="() => onEditImageStyle('square')">Square</StyleTextButton>
                <StyleTextButton :icon="'style-layout-grid'" :selected="newsSection.style.imageStyle === 'auto'" @click="() => onEditImageStyle('auto')">Auto Height</StyleTextButton>
              </div>`
            </div>
          </div>
      </EditorPanel>
</template>

<style scoped>

div.news-item-container {
  border-radius: 8px;
  background: var(--color-light-grey);
  width: 100%;
  padding: 12px;
}

.news-item-input-item {
    align-items: center;
  }
.news-item-input-label {
  margin: 0 10px 0 22.5px;
  color: var(--color-nearly-black);
  font-family: Inter;
  font-size: var(--font-size-14);
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  opacity: 0.5;
  width: 70px;
  text-align: left;
}

div.news-item-container{
  margin: 10px 0 10px 0;
}

div.news-item-input-container {
  height: 126px;
}

div.news-item-delete-button-container {
  width: 40px;
  margin: 0 10px 0 10px;
}

div.news-item-grip-handle-container {
  width: 10px;
  margin: 0 8px 0 0;
}

img.grip-icon {
  height: 16.8px;
  width: 10px;
}

div.news-style-panel-content {
    flex-shrink: 0;
    width: 100%;
    padding: 10px;
  }

  div.news-style-panel-row {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(33, 37, 41, 0.1);
  }

  div.news-style-panel-row-group-br {
    height: 100%;
    border-right: 1px solid rgba(33, 37, 41, 0.1);
  }

  p.style-header {
    color: var(--color-nearly-black);
    font-family: Inter;
    font-size: var(--font-size-14);
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    opacity: 0.5;
    margin: 0px;
  }

</style>
