import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-4 col-md-6 col-sm-8 mx-auto" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["card login", { error: _ctx.emptyFields }])
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/logo.png`)
            }, null, 8, _hoisted_5),
            _cache[2] || (_cache[2] = _createElementVNode("p", null, "We have sent a 6 digit code to your email address. Enter it to verify your account", -1)),
            _createElementVNode("form", {
              class: "form-group",
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.verify && _ctx.verify(...args)), ["prevent"]))
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
                type: "text",
                class: "form-control",
                placeholder: "Code",
                required: ""
              }, null, 512), [
                [_vModelText, _ctx.code]
              ]),
              _createElementVNode("input", {
                type: "submit",
                class: "btn btn-primary",
                value: "Verify Email",
                disabled: !_ctx.codeValid
              }, null, 8, _hoisted_6)
            ], 32)
          ], 2)
        ])
      ])
    ])
  ]))
}