import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SvgButton',
  props: {
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  }
},
  setup(__props) {



const imgSrc = computed(() => require('@/assets/icons/svg/' + __props.icon + '.svg'))

const cssVars = computed(() => {
  return {
    ...__props.style
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "svg-icon-button-wrapper",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("img", {
      src: imgSrc.value,
      alt: "icon"
    }, null, 8, _hoisted_1)
  ], 4))
}
}

})