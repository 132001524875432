import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-center icon-container" }
const _hoisted_2 = ["src"]

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'EditorTextIconButton',
  props: {
  icon: {
    type: String,
    required: true
  },
  style: {
    type: Object,
    default: null
  },
  textStyle: {
    type: Object,
    default: null
  }
},
  setup(__props) {




const cssVars = computed(() => {
  return {
    ...__props.style
  }
})

const cssVarsText = computed(() => {
  return {
    ...__props.textStyle
  }
})

const iconSrc = computed(() => {
  return require('@/assets/icons/svg/' + __props.icon + '.svg')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-content-center align-items-center button-content",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: iconSrc.value,
        alt: "icon",
        class: "icon"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("p", {
      class: "button-text",
      style: _normalizeStyle(cssVarsText.value)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ], 4))
}
}

})