import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "editor-num-select-input-container" }
const _hoisted_2 = { class: "editor-num-select-input-label" }
const _hoisted_3 = { class: "input-container" }
const _hoisted_4 = ["value", "v-model"]
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(this.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("select", {
        style: _normalizeStyle(_ctx.cssInputWrapper),
        value: _ctx.value,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event))),
        "v-model": _ctx.internalValue
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numbers, (n) => {
          return (_openBlock(), _createElementBlock("option", {
            key: n,
            value: n,
            style: _normalizeStyle(_ctx.cssInput)
          }, _toDisplayString(n), 13, _hoisted_5))
        }), 128))
      ], 44, _hoisted_4)
    ])
  ]))
}