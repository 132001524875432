import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'

import { Palette } from '@/theme'


export default /*@__PURE__*/_defineComponent({
  __name: 'StyleTextButton',
  props: {
  style: {
    type: Object,
    default: null
  },
  selected: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



const cssVars = computed(() => {
  if (__props.selected) {
    return {
      ...__props.style,
      background: 'linear-gradient(135deg, rgba(46, 117, 255, 0.10) 0%, rgba(122, 61, 255, 0.10) 99.99%, rgba(123, 61, 255, 0.10) 100%)'
    }
  } else {
    return {
      ...__props.style
    }
  }
})

const cssVarsText = computed(() => {
  if (__props.selected) {
    return {
      backgroundImage: Palette.gradientBP,
      backgroundClip: 'text',
      backgroundSize: '100%',
      textFillColor: 'transparent'
    }
  } else {
    return {
      color: 'var(--color-nearly-black);'
    }
  }
})

const cssTextClass = computed(() => {
  const baseClass = 'button-text'
  return __props.selected ? baseClass + ' text-gradient' : baseClass
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex justify-content-center align-items-center button-content",
    style: _normalizeStyle(cssVars.value)
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(cssTextClass.value),
      style: _normalizeStyle(cssVarsText.value)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6)
  ], 4))
}
}

})